import { _ } from "../../../../bl/admin/AdminLocaleBL";
import TextBox from "../../TextBox";
import BasePlugin from "../BasePlugin";

/**
 * Plugin to load and insert expand into a textbox
 */
export class ExpandPlugin extends BasePlugin {
    
    _icon: string = "resize";
    _label: string = "key_maximize_textfield";
    _name: string = "expand_textbox";

    // Event handlers
    private static onClickExpand: any = {};

    /**
     * Add event handler for expand
     * @param id Id as Topic
     * @param handler Event handler
     */
    public static addOnClickExpand(id: string, handler: any) {
        ExpandPlugin.onClickExpand[id] = handler;
    }

    onAction(textbox: TextBox) {
        let me = this;

        // Get event handler by chat topic (id as topic)
        var current = ExpandPlugin.onClickExpand[textbox.Id];
        // Execute handler
        current && current(textbox);
    }
}