import React, { Fragment } from "react";
import ComponentCommon from "../../core/ComponentCommon";
import LoaderBackdropProp from "../../entity/utils/props/LoaderBackdropProp";
import LoaderBackdropState from "../../entity/utils/states/LoaderBackdropState";

/**
 * Shows a loader: is inserted into a segment
 * @class LoaderBackdrop
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LoaderMini extends ComponentCommon<LoaderBackdropProp, LoaderBackdropState> {

    private fegaussianblur =  `<fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur>`;
    private fecolormatrix =  `<fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix>`;

    public constructor(props){
        super(props);
    }

    public get Visible(): boolean {
        let me = this;
        return me.props.visible == undefined || me.props.visible == true;
    }

    render() {
        let me = this;
        return (
            me.Visible ?
            <Fragment>
                <div className={`loader-wrapper loader-wrapper__mini ${me.Visible ? '' : 'loderhide'}`}>
                    <div>
                        <div className="loader-index">
                            <span></span>
                        </div>
                        {me.props.message && <h5>{me.props.message}</h5>}
                    </div>
                    <svg>
                        <defs></defs>
                        <filter id="goo" dangerouslySetInnerHTML={{__html: me.fecolormatrix + me.fegaussianblur}} >
                        </filter>
                    </svg>
                </div>
            </Fragment>
            : <></>
        );
    }
}

export default LoaderMini;