import React from "react";
import { _ } from "../../bl/admin/AdminLocaleBL";
import UserBL from "../../bl/user/UserBL";
import ComponentCommon from "../../core/ComponentCommon";
import ChatTransferProp from "../../entity/chat/props/ChatTransferProp";
import ChatTransferState from "../../entity/chat/states/ChatTransferState";
import { Button, IconFa, Row, Col } from '../../../cuba/components/utils/reactstrap'
import { User } from "../../entity/login/LoginEntity";
import LoaderMini from "../utils/LoaderMini";
import ChatBL from "../../bl/chat/ChatBL";
import MercureListener from "../../helper/MercureListener";
import Alert from "../utils/Alert";
import Paginator from "../control/Paginator";
import { PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import LoginBL from "../../bl/login/LoginBL";

/**
 * ChatTransfer control: Transfer chat to agent
 * @class ChatBox
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatTransfer extends ComponentCommon<ChatTransferProp, ChatTransferState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param prop 
     */
    constructor(prop: ChatTransferProp){
        super(prop);
        let me = this;
        me.state = new ChatTransferState();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(): void {
        let me = this;
        me.prepare();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(): void {
        let me = this;
    }

    /**
     * Prepare ChatTransfer component
     */
    private prepare(){
        let me = this;
        me.searchResult();
    }

    /**
     * Search results with criteria
     */
    private async searchResult(){

        let me = this;

        let data = {
            criteria: me.state.criteria,
            chatTopic: me.props.chat.chatTopic
        }

        let request = new PaginatedRequest();
        request.pagination = me.state.pagination;
        request.data = data

        me.setState({busy:true});
        let results = await UserBL.getUserTransfer(request);

        me.setState({
            busy: false,
            agents: results.data,
            pagination: results.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Transfer chat to agent
     * @param agent 
     */
    private async transfer(agent: User){
        let me = this;

        const result = await Alert.confirm(_("key_chat_transfer"), _("key_chat_transfer_message", {agent_name: agent.firstName}));
        if(result.isConfirmed){
            // Transfer chat
            let response = await ChatBL.transferChatAgent(me.props.chat, agent);

            if(response === false) {
                let currentUser = LoginBL.SessionData?.userName;
                const data = JSON.stringify({
                    message: "update_chats",
                    users: [agent.userName, currentUser]
                });
                MercureListener.publish(MercureListener.MAIN_AGENT_TOPIC, data);

                me.props.onChange && me.props.onChange(agent);
                Alert.success();
            } else {
                const agentName = agent.firstName +' '+ agent.lastName
                Alert.show(_("key_user_offline"), _("key_user_offline_text", {agent_name: agentName}), "info");
            }
        }
    }
    
    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Close window
     */
    private close(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    /**
     * Enter key event
     * @param e Event
     */
    private handleCriteriaChange(e){
        let me = this;
    
        if(e.charCode == 13){
            me.searchResult();
        }
    }

    render(){
        let me = this;

        return (
            <div id="backdrop" className="chat_backdrop">
                <div className="chat-transfer-container card">
                    <div className="alert alert-primary alert-header mb-0">{_("key_chat_transfer")}</div>
                    <div className="chat-transfer-list">
                        <div className="p-2">
                            <Row>
                                <Col sm="12" md="6">
                                    <input 
                                        onChange={ e =>  me.setState({criteria: e.target.value}) } 
                                        onKeyPress={ e => me.handleCriteriaChange(e)  } 
                                        value={ me.state.criteria } 
                                        className="form-control" 
                                        type="text" 
                                        placeholder={_("key_search_criteria")}
                                        />
                                </Col>
                                <Col sm="12" md="6">
                                    <Button onClick={ e => me.searchResult() }>
                                        <IconFa icon="search" /> {_("key_search")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <table className="table table-sm table-m-2">
                            <thead>
                                <tr>
                                    <th className="pl-4 pr-1" scope="col">{_("key_image")}</th>
                                    <th scope="col">{_("key_name")}</th>
                                    <th className="hide-md" scope="col">{_("key_lastname")}</th>
                                    <th scope="col">{_("key_language")}</th>
                                    <th scope="col">{_("key_role")}</th>
                                    <th scope="col">{_("key_actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {me.state.busy &&
                                <tr>
                                    <td colSpan={6}>
                                        <LoaderMini message={_("key_search_loading_results")}/>     
                                    </td>
                                </tr>
                            }
                            {!me.state.busy && me.state.agents.map( (user: User) => 
                                <tr key={user.id}>
                                    <td className="pl-4 p-1">
                                        <img className="img-40" src={ user.Avatar }alt=""/>
                                    </td>
                                    <td>{user.firstName}</td>
                                    <td className="hide-md">{user.lastName}</td>
                                    <td className="pl-1 pr-1">
                                        {user.languages.map(l => 
                                            <i className="badge badge-pill badge-primary ml-0 mr-1">{l?.code}</i>
                                        )}
                                    </td>
                                    <td className="p-2">{user.groupUser}</td>
                                    <td className="p-2">
                                        <Button className="p-x-md-0 p-2" onClick={ e =>  me.transfer(user)} size="sm">
                                            <IconFa icon="users"/> {_("key_transfer")}
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>                                   
                        <Paginator visible={me.state.agents.length > 0} ref={ me._paginatorRef } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                    </div>
                    <div className="p-2  text-center">
                        <Button className="p-x-md-0" onClick={ e =>  me.close()} color="danger" size="sm">
                            <IconFa icon="close"/> {_("key_cancel")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatTransfer;
