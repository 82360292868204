import React from "react";
import { Link } from "react-router-dom";
import { _ } from "../../bl/admin/AdminLocaleBL";
import { GET_NOTIFY_TEMPLATE } from "../../constants/notification";
import { Notify } from "../../entity/notification/NotificationEntity";

/**
 * Request notify
 * @param props 
 */
export const RequestNotify = (props: {notify: Notify}) => {
    var TEMPLATE = GET_NOTIFY_TEMPLATE(props.notify);
    return (
        <Link className={`text-${props.notify.state < 1 ? "success" : "muted"}`} to={TEMPLATE.Action} onClick={ () => TEMPLATE.checkPage() }>
            <i className={`fa fa-circle mr-3 font-${props.notify.state < 1 ? "success" : "danger"}`}></i>
            <i className={`fa fa-envelope${props.notify.state > 0 ? "-open" : ""} mr-3`}></i>
            {TEMPLATE.Message}
            <small className="pull-right">{props.notify.CreatedDateText}</small>
        </Link>
    );
}

/**
 * Chat notify
 */
 export const ChatNotify = (props: {notify: Notify}) => {
    var TEMPLATE = GET_NOTIFY_TEMPLATE(props.notify);
    return (
        <Link className={`text-${props.notify.state < 1 ? "success" : "muted"}`} to={TEMPLATE.Action} onClick={ () => TEMPLATE.checkPage() }>
            <i className={`fa fa-circle mr-3 font-${props.notify.state < 1 ? "success" : "danger"}`}></i>
            <i className={`fa fa-comment mr-3`}></i>
            {TEMPLATE.Message}
            <small className="pull-right">{props.notify.CreatedDateText}</small>
        </Link>
    );
}

/**
 * Chat notify
 */
 export const ChatNewNotify = (props: {notify: Notify}) => {
    var TEMPLATE = GET_NOTIFY_TEMPLATE(props.notify);
    return (
        <Link className={`text-${props.notify.state < 1 ? "success" : "muted"}`} to={TEMPLATE.Action} onClick={ () => TEMPLATE.checkPage() }>
            <i className={`fa fa-circle mr-3 font-${props.notify.state < 1 ? "success" : "danger"}`}></i>
            <i className={`fa fa-life-ring mr-3`}></i>
            {TEMPLATE.Message}
            <small className="pull-right">{props.notify.CreatedDateText}</small>
        </Link>
    );
}

/**
 * Chat transfer notify
 */
 export const ChatTransferNotify = (props: {notify: Notify}) => {
    var TEMPLATE = GET_NOTIFY_TEMPLATE(props.notify);
    return (
        <Link className={`text-${props.notify.state < 1 ? "success" : "muted"}`} to={TEMPLATE.Action} onClick={ () => TEMPLATE.checkPage() }>
            <i className={`fa fa-circle mr-3 font-${props.notify.state < 1 ? "success" : "danger"}`}></i>
            <i className={`fa fa-users mr-3`}></i>
            {TEMPLATE.Message}
            <small className="pull-right">{props.notify.CreatedDateText}</small>
        </Link>
    );
}
