import moment from "moment";
import { User } from "../login/LoginEntity";

export class AuditorLog {
    public id: number = 0;
    public module: string;
    public table: string;
    public tableId: number;
    public userId: number;
    public action: string;
    public dateTime: string;
    public device: AuditDevice;
    public affectedColumns: AuditColumn[];
    public user: User = null;

	/**
	 * define Last Message Date text
	 */
	public get DateTimeText() {
		// default Var
		let me = this;
		let date = "";
		let dateTime = me.dateTime; 
		dateTime && (date = moment(dateTime).format('DD/MM/YYYY H:mm:ss'));
		return date;
	}
}

export class AuditDevice {
    public ip: string;
    public browser: string;
    public os: string;
    public device: string;
    public brand: string;
    public model: string;
}

export class AuditColumn {
    public name: string;
    public oldValue: string;
    public newValue: string;
}

/**
 * Represents the configuration of a log history
 */
export class AuditorConfig {
    public criteriaList: AuditorCriteria[] = [];
    private context: CallableFunction = null;
    public timeout: number = 1000;
    public labels: any = null;

    /**
     * Set column labels
     * @param labels Labels key->value
     */
    public setLabels(labels: any) {
        let me = this;
        me.labels = labels;
    }

    /**
     * Clean the List of Criteria
     */
    public clear(){
        let me = this;
        me.criteriaList = [];
    }

    /**
     * Update context
     */
    public update() {
        let me = this;
        me.context && me.context(me);
    }

    /**
     * Verify if the list of criteria is empty
     * @returns True: Is empty
     */
    public isEmpty(size: number = 0): boolean {
        let me = this;
        return !(me.Criteria.length > size);
    }
    
    public addCriteria(
            {module, table = "", identifier = 0, id = 0}:
            {module: string, table?: string, id?: number, identifier?: number}
        ) {
        let me = this;
        var criteria = new AuditorCriteria();
        criteria.id = id;
        criteria.module = module;
        criteria.table = table;
        criteria.identifier = identifier;
        me.criteriaList.push(criteria);
    }

    /**
     * Gets the List of Criteria
     */
    public get Criteria(): AuditorCriteria[] {
        let me = this;
        return me.criteriaList;
    }

    /**
     * 
     * @param callback 
     */
    public setContext(callback: CallableFunction) {
        let me =this;
        me.context = callback;
    }

    /**
     * Configure the time interval in which the components related to
     * the log have been completely charged.
     * @param timeout 
     */
    public setTimeout(timeout: number) {
        let me = this;
        me.timeout = timeout;
    }
}

/**
 * Search criteria for Mongo (Auditor)
 */
export class AuditorCriteria {
    public module: string = "";
    public table: string = "";
    public identifier: number = 0;
    public id: number = 0;
}