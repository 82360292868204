import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { Card, CardHeader, CardBody, FormGroup, IconFa, Row, Col } from '../../../cuba/components/utils/reactstrap';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import AuditorLogList from '../auditor/AuditorLogList';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import LoaderBackdrop from '../utils/LoaderBackdrop';
import ChannelFormProp from '../../entity/channel/props/ChannelFormProp';
import ChannelFormState from '../../entity/channel/states/ChannelFormState';
import ChannelBL from '../../bl/channel/ChannelBL';
import { Switch } from '../control/Switch';
import { REQUEST_PLATFORM } from '../../constants/data/request';
import { ADMIN_SITES } from '../../constants/data/sites';

/**
 * Update chat channels
 * @class ChannelForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChannelForm extends ComponentCommon<ChannelFormProp, ChannelFormState> {
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ChannelFormProp){
        super(props);
        let me = this;
        me.state = new ChannelFormState();  
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize componente
     */
    private async prepare(){
        let me = this;
        me.setState({
            channel: me.props.channel
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        let me = this;
        let channel = me.state.channel;
        me.setState({busy: true});

        // Save changes...
        channel = await ChannelBL.updateChannel(channel);

        me.props.onChange && me.props.onChange(channel);
        Alert.success();
        me.setState({
            busy: false,
            channel: channel
        });

        // Update auditor
        me._auditorLog.current?.update();
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
     private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let channelState = me.state.channel;
        channelState[prop] = value;
        me.setState({
            channel: channelState
        });
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({
            module: "Channel", identifier: me.state.channel?.id
        });

        if(!me.state.channel){
            return <></>
        }

        return (
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card> 
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-0">{_("key_edit")} {me.state.channel?.name && <span>"{me.state.channel.name}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                        <IconFa icon="save"/> {_("key_save")}
                                    </button>
                                    <button onClick={ () => me.handleClose() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                                <Row>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_name")}</h6>
                                            <input type="text" required={true} onChange={ e => me.handleChange(e) } name="name" value={ me.state.channel.name } className="form-control" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_platform")}</h6>                                         
                                            <select required={ true } onChange={ e => me.handleChange(e) } name="platform" value={ me.state.channel.platform || "" } className="form-control">
                                                {REQUEST_PLATFORM.map( rol => {
                                                    return <option key={ rol.identifier } value={rol.identifier}>{rol.name}</option>
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_sites")}</h6>
                                            <select onChange={ e => me.handleChange(e) } name="siteCode" value={ me.state.channel.siteCode } className="form-control">
                                                {ADMIN_SITES.map( (rp) => {
                                                    return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_profile")}</h6>
                                            <input type="text" required={true} onChange={ e => me.handleChange(e) } name="profile" value={ me.state.channel.profile } className="form-control" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_identifier")}</h6>
                                            <input type="text" required={true} onChange={ e => me.handleChange(e) } name="identifier" value={ me.state.channel.identifier } className="form-control" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <Switch size="sm" title={_("key_enabled")} onChange={ e => me.handleChange(e) } name="enabled" checked={me.state.channel.enabled}/>
                                    </Col>
                                </Row>
                        </CardBody>
                    </Card>
                </form> 
                { me.state.channel.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}

export default ChannelForm;