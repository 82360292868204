import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { IconFa, Button } from '../../../cuba/components/utils/reactstrap';
import SchemaFormProp from '../../entity/landing/props/SchemaFormProp';
import SchemaFormState from '../../entity/landing/states/SchemaFormState';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import { FormInputTranslate } from '../../core/Translate';
import LandingSchemaBL from '../../bl/Landing/LandingSchemaBL';
import TextBox from '../control/TextBox';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { LANDING_SCHEMA_EDITOR_CONFIG } from '../../constants/data/landing';
import InputSearch, { InputSearchConfig } from '../control/InputSearch';
import System from '../../helper/System';
import { ISPlace } from './ISPlace';

/**
 * Manage an element of static content and its translations
 * @class SchemaElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SchemaForm extends ComponentCommon<SchemaFormProp, SchemaFormState>{
    private _searchPlaceConfig: InputSearchConfig = null;
    private _refsIS: React.RefObject<any>[] = [];
    
    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: SchemaFormProp){
        super(props);
        this.state = new SchemaFormState();
        this._searchPlaceConfig = new InputSearchConfig("/Home/PlaceAPI/GetPlaces");
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        var languages = await LocaleBL.getLanguages();
        
        // Generate form inputs
        var forms = FormInputTranslate.prepare(
            e => LandingSchemaBL.updateSchema(e),
            e => LandingSchemaBL.updateSchemaGeolocation(e)
        ).generate(languages, me.props.schema);

        me.setState({
            languages: languages,
            schema: me.props.schema,
            forms: forms,
            selectedLanguage: me.props.language
        });
    }

    /**
     * Handle onSave button click
     */
    private async handleSave(){
        let me = this;
        var result = await Alert.confirm(_("key_save_changes"), _("key_save_changes_message"));
        if(result.isConfirmed) {
            var forms = me.state.forms;
            forms = await FormInputTranslate.save(forms);

            me.setState({
                forms: forms
            });

            me.props.onChange && me.props.onChange(me.state.schema);
            me.flushRedis();
        }
    }

    /**
     * Flush redis caché
     */
    private async flushRedis(){
        let me = this;
        // Redis flush command
        try {
            await System.redisFlushById('pageschema', me.state.schema.id);
            // Success message
            Alert.success();
        }catch {
            // Redis error
            Alert.error(_("key_redis_error"));
        }
    }

    /**
     * Update all places (scheme geolocation)
     * @param value place identifier
     */
    private updatePlaces(value: number){
        let me = this;
        var forms = me.state.forms;
        forms.map( f => {
            f.value.idPlace = value;
        });
        me.setState({
            forms: forms
        })
    }

    /**
     * On close editor event
     */
    private close(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;
        return ( 
            <>
                {/*<ul className="nav nav-tabs">
                    {me.state.forms.map( (l, i) => 
                        <li key={i} onClick={ e => me.setState({selectedLanguage: l.code}) } className={`nav-item`}>
                            <a className={`nav-link ${l.code == me.state.selectedLanguage ? "active" : ""}`}>{l.label}</a>
                        </li>
                    )}
                </ul>*/}       
                <div className="m-2">
                    <label>{_("key_place")}: </label>
                </div>
                {me.state.schema &&
                    <InputSearch 
                        component={ISPlace}
                        onChange={ id => me.updatePlaces(id) } 
                        value={me.state.schema.idPlace}  
                        ref={ FormInputTranslate.getParent(me.state.forms).createRef("idPlace") } 
                        config={ me._searchPlaceConfig } />
                }

                {me.state.forms.map( (l, i) => 
                    <div key={i} className={ me.props.language == l.code ? "" : "d-none" }>
                        <div className="m-2">
                            <label>{_("key_question")}: </label>
                        </div>
                        <input className="form-control" ref={ l.createRef("question") } defaultValue={l.value.question} />
                        <div className="m-2">
                            <label>{_("key_answer")}: </label>
                        </div>
                        <TextBox config={LANDING_SCHEMA_EDITOR_CONFIG} ref={ l.createRef("answer") } value={l.value.answer} />
                    </div>
                )}
                <Button color="primary" type="button" className="m-2" onClick={ e => me.handleSave() }>
                    <IconFa icon="save"/> {_("key_save")}
                </Button>
                <Button color="secondary" type="button" className="m-2" onClick={ e => me.close() }>
                    <IconFa icon="close"/> {_("key_return")}
                </Button>
            </>
        );
    }
}
export default SchemaForm;