import IState from "../../../core/IState";
import { FormInputTranslate } from "../../../core/Translate";
import { UserLanguage } from "../../login/LoginEntity";
import { LocationNote } from "../LocationEntity";

export default class LocationNoteFormState implements IState {
    public locationNote: LocationNote;
    public languages: Array<UserLanguage> = [];
    public forms: FormInputTranslate[] = [];
    public selectedLanguage: string = "EN";
    public visible: boolean = true;
}