import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { PaginatedReponse, PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { Images } from "../../entity/images/ImagesEntity";
import {User} from "../../entity/login/LoginEntity";

/**
 * Performs Images Operations
 * @Class ImagesBL 
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export class ImagesBL extends BaseBL {
    /**
     * Get current images list
     * @returns Images
     */
     public static async getImages(paramsData: PaginatedRequest): Promise<PaginatedReponse<Images>>{
        let me = this;
        let data = {
            params: paramsData
        };
        const response = await axios.get('/Home/ImagesAPI/ListImages', data);
        const responseData = me.getResponseData(response);
        const result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, Images, (item: Images) => {
            item.user = me.parseEntity(item.user, User);
            return item;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param image Entity object
     * @returns response
     */
    public static async updateImage(image: Images) {
        let me = this;
        let formData = me.getEntityFormData(image);
        let response = await axios.post('/Home/ImagesAPI/UpdateImages', formData);
        let responseData = me.getResponseData(response);
        let imageUpdated = me.parseEntity(responseData, Images);
        imageUpdated.user = me.parseEntity(imageUpdated.user, User);
        return imageUpdated;
    }

    /**
     * Take the action to save in the API.
     * @param image Entity object
     * @returns response
     */
    public static async deleteImage(image: Images) {
        let me = this;
        let formData = me.getEntityFormData(image);
        let response = await axios.post('/Home/ImagesAPI/DeleteImages', formData);
        let responseData = me.getResponseData(response);
        let imageUpdated = me.parseEntity(responseData, Images);
        return imageUpdated;
    }
}