
export const REQUEST_PLATFORM = [ 
    {
        identifier: "web",
        name: "Web"
    },
    {
        identifier: "whatsapp",
        name: "WhatsApp"
    },
    {
        identifier: "instagram",
        name: "Instagram"
    },
    {
        identifier: "facebook",
        name: "Facebook"
    } 
];