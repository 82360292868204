import IProp from '../../../core/IProp';
import {Chat} from "../../chat/ChatEntity";
import {INewNavigation} from "../states/NavigationLogState";
export type IUpdateNewNavigation = (updateNewNavigations:INewNavigation[], chatTopic: string) => void;
export const updateNewNavigation = (newNavigations: INewNavigation[],  updateNewNavigations:INewNavigation[], chatTopic: string) => {
    const currentNewNavigations = newNavigations;
    let updatedNewNavigations = [];
    if(updateNewNavigations.length){
        let navigationsWithoutChatTopicUpdate = currentNewNavigations
            .filter(newNavigation => !updateNewNavigations
                .some(updateNewNavigation => newNavigation.chatTopic === updateNewNavigation.chatTopic));
        if(navigationsWithoutChatTopicUpdate.filter(newNavigation=>newNavigation.chatTopic===chatTopic).length)
            navigationsWithoutChatTopicUpdate = navigationsWithoutChatTopicUpdate.filter(newNavigation=>newNavigation.chatTopic!==chatTopic);
        updatedNewNavigations = navigationsWithoutChatTopicUpdate.concat(updateNewNavigations);
    }
    else updatedNewNavigations = currentNewNavigations.filter(newNavigation=>newNavigation.chatTopic!==chatTopic);
    return updatedNewNavigations;
}
export class NavigationLogProp implements IProp {
    public currentChat: Chat;
    public selectedChat: Chat;
    public visible: boolean;
    public updateNewNavigations: IUpdateNewNavigation;
}

export default NavigationLogProp;