import React from "react";
import { _ } from "../../../../bl/admin/AdminLocaleBL";
import ReportResultProps from "../../../../entity/report/props/ReportResultProps";
import { Empty } from "../../../utils/Empty";

// Language columns
var languages = ['es', 'en', 'pt'];

/**
 * Report result
 */
export const RatingResult = (props: ReportResultProps) => {
    // Final data
    var data: any = {}; 
    var total = 0;

    // Flat array
    props.data.map( a => { 
        let key = Object.keys(a).pop(); 
        let lang = Object.keys(a[key]).pop();
        if(!data[key]) {
            data[key] = {total: 0, rate: key}; 
            languages.map( l => data[key][l.toLowerCase()] = 0)
        };
        data[key][lang] = a[key][lang]; 
        data[key]["total"] += a[key][lang]; 
        total += a[key][lang];
        data[key]["percentage"] = 0; 
        return a[key] 
    });

    data = Object.values(data);

    // Percentages
    data.map( a => {
        let percentage = (a["total"] / total) * 100;
        percentage = Math.round((percentage + Number.EPSILON) * 100) / 100;
        a["percentage"] = percentage;
        return a;
    });

    if(data.length < 1) {
        return <Empty message={_("key_without_results")}/>;
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>{_("key_rate")}</th>
                    {languages.map( l =>
                        <th key={l}>{l.toUpperCase()}</th>
                    )}
                    <th>{_("key_total")}</th>
                    <th>%</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(data).map( (r: any, i) => 
                    <tr key={i}>
                        <td>{_("key_chat_rating" + r.rate)}</td>
                        {languages.map( (l, ii) =>
                            <td key={`${ii}_${i}`}>{r[l]}</td> 
                        )}
                        <td>{r.total}</td>
                        <td>{r.percentage} %</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}