import React from "react";
import NotifierBL from "../../bl/admin/NotifierBL";
import ComponentCommon from "../../core/ComponentCommon";
import NotifierListProp from "../../entity/notification/props/NotifierListProp";
import NotifierListState from "../../entity/notification/states/NotifierListState";
import { Bell } from 'react-feather';
import { Notify } from "../../entity/notification/NotificationEntity";
import { _ } from "../../bl/admin/AdminLocaleBL";
import MercureListener from "../../helper/MercureListener";
import LoginBL from "../../bl/login/LoginBL";
import { Notifier } from "../../helper/Notifier";
import { MILES_LOGO_ICON } from "../../constants/data/general";
import { Toast } from 'react-bootstrap';
import { GET_NOTIFY_TEMPLATE } from "../../constants/notification";
import { Link } from "react-router-dom";
import Alert from "../utils/Alert";

/**
 * It is responsible for visualizing notifications and managing them
 * @class NotifierList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class NotifierList extends ComponentCommon<NotifierListProp, NotifierListState> {

    private timeoutIds: any[] = [];

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: NotifierListProp){
        super(props);
        this.state = new NotifierListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(){
        let me = this;

        // Clean to avoid errors
        me.timeoutIds.map( id => clearTimeout(id));
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;
        me.getNotifications();

        MercureListener.subscribe("notify_" + LoginBL.SessionData.id, data => {
            me.getNotifications();
        });

        me.setState({
            notificationPermission: Notifier.getCurrentPermission()
        });
    }

    /**
     * Get notifications list
     */
    private async getNotifications(){
        let me = this;
        
        var notifications = await NotifierBL.getNotifications();

        notifications = me.showNotifications(notifications);

        me.setState({
            notifications: notifications
        });
    }

    /**
     * Show notifications in the browser
     * @param notifications Notifications list
     */
    private showNotifications(notifications: Notify[]): Notify[] {
        let me = this;
        var currentNotifications = me.state.notifications;

        // Filter new notifications
        var newNot = notifications.filter( n => {
            for(var x in currentNotifications){
                var not = currentNotifications[x];

                // Se as push notification
                n.push = true;
                if(not.id == n.id) {
                    n.push = not.push;
                    return false;
                } 
            }
            return true;
        });

        if(currentNotifications.length > 0){
            newNot.map( notify => {
                var TEMPLATE = GET_NOTIFY_TEMPLATE(notify);
                Notifier.show(TEMPLATE.Title, {
                    body: TEMPLATE.Message,
                    icon: MILES_LOGO_ICON,
                }).onclick = () => {
                    me.hideNotification(notify);
                    window.open(TEMPLATE.Action);
                };

                // Hide push notifications timeout
                var id = setTimeout(() => {
                    me.hideNotification(notify);
                }, 15000)
                me.timeoutIds.push(id);
            });
        }

        return notifications;
    }

    /**
     * Handle notification click
     * @param e Event
     */
    private async clearNotification(notify: Notify) {
        let me = this;

        var notifications = me.state.notifications.map( n => {
            if(n.id == notify.id) {
                n.state = 1;
                n.push = false;
            }
            return n;
        });
        me.setState({
            notifications: notifications
        });
        var result = await NotifierBL.clearNotification(notify.id);
    }

    /**
     * Hide toast notification
     * @param notify 
     */
    private hideNotification(notify: Notify){
        let me = this;
        var notifications = me.state.notifications.map( n => {
            if(n.id == notify.id) {
                n.push = false;
            }
            return n;
        });
        me.setState({
            notifications: notifications
        });
    }

    /**
     * Get notification template
     * @param notify 
     * @returns Component
     */
    private getNotify(notify: Notify){
        let me = this;
        var Component = GET_NOTIFY_TEMPLATE(notify).Component;
        return <li onClick={ e => me.clearNotification(notify) } key={notify.id}><Component notify={notify}/></li>
    }

    /**
     * Clear notifications
     * @param notify 
     */
    private async clearNotifications(){
        let me = this;
        var notifications = me.state.notifications.map( n => {
            n.state = 1;
            n.push = false;
            return n;
        });
        me.setState({
            notifications: notifications
        });
        var notificationsCount = await NotifierBL.clearNotifications();
    }

    /**
     * Request permission
     */
    private async requestNotification(){
        let me = this;
        var result = await Notifier.requestPermission();

        me.setState({
            notificationPermission: result
        })

        if(result != "granted") {
            me.alertNoPush();
        }
    }

    /**
     * Alert disabled push notifications
     */
    private alertNoPush(tryPermission: boolean = false){
        let me = this;
        Alert.error(_("key_notify_denied_message"));
        tryPermission && me.requestNotification(); 
    }

    /**
     * Gets the count of new messages
     */
    public get CountNotify(): number {
        let me = this;
        return me.state.notifications.filter( n => n.state < 1).length;
    }
    
    render() {
        let me = this;
        let countNotify = me.CountNotify;
        return (
            <>
                <div className="notifier-toast-container">
                    {me.state.notifications.filter( n => n.state < 1 && n.push).map( notify => {
                        var TEMPLATE = GET_NOTIFY_TEMPLATE(notify);
                        return (
                            <Toast key={notify.id}>
                                <Toast.Header>
                                    <img src={MILES_LOGO_ICON} className="img-40 rounded me-2" alt="" />
                                    <strong className="me-auto">{TEMPLATE.Title}</strong>
                                    <small>{notify.CreatedDateText}</small>
                                    <i onClick={ e => me.hideNotification(notify) } className="fa fa-close"></i>
                                </Toast.Header>
                                <Link className="font-secondary" to={TEMPLATE.Action} onClick={ () => TEMPLATE.checkPage() }> 
                                    <Toast.Body onClick={ e => me.clearNotification(notify) }>
                                        {TEMPLATE.Message} 
                                    </Toast.Body>
                                </Link>
                            </Toast>
                        );
                    })}
                </div>
                <li className="onhover-dropdown">
                    <div className={ countNotify > 0 ? "notification-box" : ""} onClick={() => me.setState({visible: !me.state.visible})}>
                        <Bell />{countNotify > 0 && <span className="badge badge-pill badge-primary">{countNotify}</span>}
                    </div>
                    <ul className={`notification-dropdown onhover-show-div ${me.state.visible ? "active" : ""}`}>
                        <li>
                            <Bell />
                            <h6 className="f-18 mb-0">{_("key_my_notifications")}</h6>
                        </li>
                        {me.state.notifications.map( notify =>  
                            me.getNotify(notify)
                        )}
                        <li className="text-center">
                            <button onClick={ e => me.clearNotifications() } className="btn btn-primary m-1 w-100">
                                <i className="fa fa-bell"></i> {_("key_mark_as_read")}
                            </button>
                            <Link to="/notification/Dubai" className="btn btn-secondary m-1 w-100">
                                <i className="fa fa-eye"></i> {_("key_my_notifications")}
                            </Link>
                            {me.state.notificationPermission == "default" && 
                                <button onClick={ e => me.requestNotification() }  className="btn btn-success m-1 w-100">
                                    <i className="fa fa-bell"></i> {_("key_notify_request_permission")}
                                </button>
                            }
                            {me.state.notificationPermission == "denied" && 
                                <button onClick={ e => me.alertNoPush(true) }  className="btn btn-primary m-1 w-100">
                                    <i className="fa fa-bell"></i> {_("key_notify_denied")}
                                </button>
                            }
                        </li>
                    </ul>
                </li>
            </>
        );
    }
}

export default NotifierList;
