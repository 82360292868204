import axios from 'axios';
import moment from 'moment';
import BaseBL from '../../core/BaseBL';
import { Chat } from '../../entity/chat/ChatEntity';
import { PaginatedReponse, PaginatedRequest, Pagination, PaginatedWithIdRequest } from '../../entity/control/ControlEntity';
import { GroupUser, User, UserLanguage } from '../../entity/login/LoginEntity';
import UserSchedule from '../../entity/user/UserEntity';
import LoginBL from '../login/LoginBL';

/**
 * Perform operations in the user API
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class UserBL extends BaseBL {
    
    // Stored schedule
    private static _schedule: UserSchedule[] = null;

    /**
     * Check the listing of stored users
     * @returns Array of User Entity
     */
     public static async getUsers(paramsData: PaginatedWithIdRequest): Promise<PaginatedReponse<User>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/UserAPI/ListUser', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);
        
        result.data = me.parseEntityArray(result.data, User);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     *  Get user
     * @returns User Entity
     */
     public static async getProfile(id: number): Promise<User>{
        let me = this;
        let data = {
            params: {
                id
            }
        };
        let response = await axios.get('/Home/UserAPI/GetProfile', data);
        let responseData = me.getResponseData(response);
        let user = me.parseEntity(responseData, User);
        return user;
    }

    /**
     * Take the action to save in the API.
     * @param user Entity object
     * @returns response
     */
     public static async updateProfile(user: User) {
        let me = this;
        let formData = me.getEntityFormData(user);
        let response = await axios.post('/Home/UserAPI/UpdateProfile', formData);
        let responseData = me.getResponseData(response);
        let userUpdated = me.parseEntity(responseData, User);
        return userUpdated;
    }

    /**
     * Take the action to save in the API.
     * @param user Entity object
     * @param password Current password
     * @param passwordNew New password
     * @returns response
     */
     public static async updateProfilePassword(user: User, password: string, passwordNew: string): Promise<boolean> {
        let me = this;
        var data = {
            userName: user.userName,
            password: password,
            passwordNew: passwordNew
        };  
        let formData = me.getEntityFormData(data);
        let response = await axios.post('/Home/UserAPI/UpdateProfilePassword', formData);
        return response.data?.result;
    }

    /**
     * Check the listing of stored agents
     * @returns Array of User Entity
     */
    public static async getAgents(groups: number[] = [2]){
        let me = this;
        let data = {
            params: {
                groups: groups
            }
        };

        let response = await axios.get('/Home/UserAPI/GetAgents', data);
        let responseData = me.getResponseData(response);
        let userLista = new Array<User>();
        userLista = me.parseEntityArray(responseData, User);
        return userLista;
    }

    /**
     * Take the action to save in the API.
     * @param user Entity object
     * @returns response
     */
     public static async saveUser(user: User) {
        let me = this;
        let formData = me.getEntityFormData(user);
        let response = await axios.post('/Home/UserAPI/UpdateUser', formData);
        let responseData = me.getResponseData(response);
        let userUpdated = me.parseEntity(responseData, User);
        return userUpdated;
    }

    /**
     * Perform the action of get user languages from the API.
     * @param user Entity object
     * @returns response
     */
     public static async getLanguages(user: User){
        let me = this;
        let formData = new FormData();
        formData.set("User", user.id + "");
        let response = await axios.post('/Home/UserAPI/GetLanguages', formData);
        let responseData = me.getResponseData(response);
        let languageList = new Array<UserLanguage>();
        languageList = me.parseEntityArray(responseData, UserLanguage);
        return languageList;
    }

    /**
     * Add a new language to List of User Languages from the API
     * @param user Entity object
     * @returns response
     */
    public static async assignLanguage(user: User, langCode: string, state: boolean){
        let me = this;
        let formData = new FormData();
        formData.set("User", user.id + "");
        formData.set("Language", langCode);
        formData.set("State", state ? "true" : "false");
        let response = await axios.post('/Home/UserAPI/AssignLanguage', formData);
        return me.getResponseData(response);
    }

    /**
     * Get the schedules assigned to the user
     * @param user User
     * @returns UserSchedule data
     */
     public static async getUserTransfer(paramsData: PaginatedRequest): Promise<PaginatedReponse<User>>{
        let me = this;
        var data = {
            params: paramsData
        }
        let response = await axios.get('/Home/UserAPI/GetUserTransfer', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);

        result.data = me.parseEntityArray(result.data, User);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Get the schedules assigned to the user
     * @param user User
     * @returns UserSchedule data
     */
    public static async getUserSchedule(user: User, cache: boolean = true){
        let me = this;
        if(me._schedule !== null && cache){
            return me._schedule;
        }
        var data = {
            params: {
                user: user.id
            }
        }
        let response = await axios.get('/Home/UserAPI/GetUserSchedule', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntityArray(responseData, UserSchedule);
        cache && (me._schedule = result);
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
     public static async saveUserSchedule(schedule: UserSchedule){
        let me = this;
        var data = me.getEntityFormData(schedule);
        let response = await axios.post('/Home/UserAPI/UpdateUserSchedule', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, UserSchedule);
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
    public static async deleteUserSchedule(schedule: UserSchedule){
        let me = this;
        var data = me.getEntityFormData(schedule);
        let response = await axios.post('/Home/UserAPI/DeleteUserSchedule', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, UserSchedule);
        return result;
    }

    /**
     * Verify that the user is within the calendar and schedules configured to the user
     */
    public static async checkSchedule(user: User, date: Date = null){
        !date && (date = new Date());
        let me = this;

        await me.getUserSchedule(LoginBL.SessionData);
        return me.checkScheduleNoAsync(date);
    }
    
    /**
     * Verify that the user is within the calendar and schedules configured to the user
     */
    public static checkScheduleNoAsync(date: Date = null){
        !date && (date = new Date());
        let me = this;
        let schedule: Array<UserSchedule> = me._schedule;

        // If you do not have a configured schedule, it automatically accepts anyone
        // if you are administrador, manage all chats        
        if(schedule == null || schedule.length < 1 || LoginBL.SessionData?.isAdministrator){
            return true;
        }

        const day_of_week = date.getDay();
        let have = false;
        schedule.forEach( day => {
            if(day.day == day_of_week){
                let start = moment(day.start, ['H:m']).toDate();
                let end = moment(day.end, ['H:m']).toDate();
                have = have || (start <= date && date <= end);
            }
        })
        return have;
    }

    public static async getGroups() {
        let me = this;
        let response = await axios.get('/Home/UserAPI/GetAllGroup')
        let responseData = me.getResponseData(response);
        return responseData;
    }
}

export default UserBL;