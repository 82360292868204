import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import AdminLocaleBL, { _ } from "../../bl/admin/AdminLocaleBL";
import { Button, IconFa } from '../../../cuba/components/utils/reactstrap'
import ChatCreateProp from "../../entity/chat/props/ChatCreateProp";
import ChatCreateState from "../../entity/chat/states/ChatCreateState";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { REQUEST_PLATFORM } from '../../constants/data/request';
import ChatBL from "../../bl/chat/ChatBL";
import Alert from "../utils/Alert";
import LoaderMini from "../utils/LoaderMini";
import ChannelBL from "../../bl/channel/ChannelBL";
import { ChatContacts } from "./ChatContacts";
import { Contact } from "../../entity/contact/ContactEntity";

/**
 * Represents the component that begins a chat
 * @class ChatCreate
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatCreate extends ComponentCommon<ChatCreateProp, ChatCreateState> {
    
    private _phoneRef = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ChatCreateProp){
        super(props);
        this.state = new ChatCreateState();
        this._phoneRef = React.createRef();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;

        me.getChannels();
    }

    /**
     * Set form state
     * @param state 
     */
    private setForm(state: boolean){
        let me = this;
        me.props.onChange && me .props.onChange(state);
        me.setState({
            showForm: state
        });
    }

    private async getChannels() {
        let me = this;

        var channels = await ChannelBL.getAllChannels();
        var defaultPlatform = REQUEST_PLATFORM[1].identifier;
        me.setState({
            channels: channels,
            platform: defaultPlatform
        }, () => {
            me.selectPlatform(defaultPlatform);
        });
    }

    /**
     * Create a new chat conversation
     */
    private async create(){
        let me = this;
        if(!(me.state.profile?.length > 1)){
            Alert.error(_("key_phone_invalid"));
            return;
        }
        me.setState({busy: true});
        let currentLanguage = AdminLocaleBL.Language;
        let createdChat = await ChatBL.createChat(me.state.profile, me.state.platform, me.state.channel, currentLanguage);
        me.props.onCreate && me.props.onCreate(createdChat)
        me.setState({
            showForm: false,
            busy: false
        });
    }
    
    /**
     * Validate phone
     * @param isValid 
     * @param phone 
     * @param country 
     * @returns 
     */
    private onPhoneChange(isValid, phone, country){
        let me = this;
        if(!isValid){
            return;
        }
        var number = String(phone);
        if(!number.includes("+")){
            number = `+${country.dialCode}${phone}`;
        }
        if(isValid){
            me.setState({profile: number});
        } else {
            me.setState({profile: ""});
        }
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.setForm(false);
    }

    /**
     * Select platform
     * @param platform Platform identifier
     */
    private selectPlatform(platform: string){
        let me = this;
        let defaultChannel = "";
        var channels = me.state.channels.filter( c => c.platform == platform);
        if(channels.length > 0){
            defaultChannel = channels[0].identifier;
        }
        me.setState({ 
            platform: platform,
            channels: channels,
            channel: defaultChannel
        });
    }

    /**
     * Open contact search
     */
    private openContactSearch(){
        let me = this;

        me.setState({
            openContactSearch: true
        });
    }

    /**
     * Select current contact
     * @param contact Contact
     */
    private selectContact(contact: Contact) {
        let me = this;

        me._phoneRef.current?.setNumber(contact.profile);

        me.setState({
            profile: contact.profile,
            openContactSearch: false
        });
    }

    render() {
        let me = this;
        return (
            <div className="mb-3">
                {!me.state.showForm ?
                    <div className="text-center p-2">
                        <Button onClick={ e => me.setForm(true)} color="primary">
                            <IconFa icon="plus"/> {_("key_new_conversation")}
                        </Button>
                    </div>
                    :
                    <>
                        {me.state.busy ? 
                            <LoaderMini message={_("key_loading")} />
                            :
                            <form className="p-2" onSubmit={ e => me.handleFormSubmit(e) } >
                                <label>{_("key_platform")}:</label>
                                <select 
                                    required={true}
                                    className="form-control mb-2" 
                                    onChange={ e => me.selectPlatform(e.target.value)}>
                                    {REQUEST_PLATFORM.filter( k => k.identifier != "web").map( (k) => 
                                        <option key={k.identifier} value={k.identifier}>{k.name}</option>
                                    )}
                                </select>
                                {me.state.platform &&
                                    <>
                                        <label>{_("key_profile")}:</label>
                                        <select 
                                            required={true}
                                            className="form-control mb-2"
                                            onChange={ e => me.setState({channel: e.target.value })}>
                                            {me.state.channels.map( (channel) => 
                                                <option key={channel.id} value={channel.identifier}>
                                                    {channel.name} ({channel.profile})
                                                </option>
                                            )}
                                        </select>
                                    </>
                                }
                                <label>{_("key_phone")}:</label>
                                <div>
                                    <IntlTelInput
                                        ref={me._phoneRef}
                                        formatOnInit={true}
                                        autoComplete={'tel'}
                                        containerClassName="intl-tel-input chat-tel-input"
                                        inputClassName="form-control"
                                        onPhoneNumberChange={ (isValid, phone, country) => me.onPhoneChange(isValid, phone, country) }
                                        />
                                </div>
                                <div className="text-center p-2">
                                    <button type="button" onClick={ () => me.openContactSearch() } className="btn btn-danger">
                                        <IconFa icon="search"/> {_("key_contact_find")}
                                    </button>
                                </div>
                                {/*
                                <label> Mensaje inicial:</label>
                                <textarea className="form-control"></textarea>
                                */}
                                <div className="p-1 text-center">
                                    <Button 
                                        onClick={ e => me.create() }
                                        color="success" 
                                        className="m-2">
                                        <IconFa icon="comment"/> {_("key_start")}
                                    </Button>
                                    <Button 
                                        color="danger" 
                                        type="submit"
                                        className="m-2">
                                        <IconFa icon="close"/> {_("key_textbox_cancel")}
                                    </Button>
                                </div>
                                {me.state.openContactSearch &&
                                    <ChatContacts
                                         onChange={ c => me.selectContact(c) } 
                                         onClose={ () =>  me.setState({ openContactSearch: false })}
                                         />
                                }
                            </form>
                        }
                    </>
                }
            </div>
        );
    }
}

export default ChatCreate;