/**
 * Url hash helper
 * @class UrlHash
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class UrlHash {
    /**
     * Get hash parameter
     * @param parameter Parameter key
     * @param defaultValue Default value
     * @returns Value
     */
    public static get(parameter: string, defaultValue:string = null){
        var hash = location.hash;
        if(hash?.length < 1){
            return defaultValue;
        }

        hash = hash.slice(1, hash.length);
        var data = JSON.parse('{"' + decodeURI(hash).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        var value = data[parameter];

        return value == undefined ? defaultValue : value;
    }

    /**
     * Data to encode
     * @param data Data to encode
     * @returns Value
     */
    public static encode(data: any){
        return new URLSearchParams(data) + "";
    }
}

export default UrlHash;
