import React, { Fragment } from 'react';
//import { FileText, LogIn, Mail, User as UserIcon, Edit } from 'react-feather';
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa, Nav } from '../../cuba/components/utils/reactstrap'
import { GroupUser } from '../entity/login/LoginEntity';
import PageCommon from '../core/PageCommon';
import Paginator from '../component/control/Paginator';
import { _ } from '../bl/admin/AdminLocaleBL';
import { PaginatedRequest, Pagination, PaginatedWithIdRequest } from '../entity/control/ControlEntity';
import LoaderMini from '../component/utils/LoaderMini';
import GroupPageProp from '../entity/security/props/GroupPageProp';
import GroupPageState from '../entity/security/states/GroupPageState';
import GroupBL from '../bl/security/GroupBL';
import GroupForm from '../component/security/GroupForm';

/**
 * GroupPage (User admin)
 * @class GroupPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class GroupPage extends PageCommon<GroupPageProp, GroupPageState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props: GroupPageProp){
        super(props);
        this.state = new GroupPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me._paginatorRef = React.createRef();
        me.searchResult();
    }

    /**
     * Select a user and displays the preview
     * @param role 
     */
    private selectGroup(role: GroupUser){
        let me = this;
        me.setState({
            group: role
        });
    }

    /**
     * Make the query by page
     */
     private async searchResult(resetCount: boolean = false){
        let me = this;

        let request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data.criteria = me.state.criteria;

        me.setState({loadingGroups:true});
        let data = await GroupBL.getGroups(request);
        
        me.setState({
            loadingGroups: false,
            groups: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult(true);
    }

    /**
     * Handle form close
     */
    private handleClose(){
        let me = this;

        me.setState({
            group: null
        });
        me.searchResult();
    }

    /**
     * Create group
     */
    private createGroup(){
        let me = this;
        let newGroup = new GroupUser();
        me.setState({
            group: newGroup
        })
    }

    render() {
        let me = this;
        return (
            <Fragment>
                <Row>
                    <Col sm={12} md={2}></Col>
                    <Col sm={12} md={8}>
                        { !me.state.group ? 
                            <Card>
                                <CardHeader>
                                    
                                    <Row>
                                        <Col md="6">
                                            <h5>{_("key_groups_registered")}</h5> 
                                        </Col>
                                        <Col md="6">
                                            <Button color="success" className="float-right mt-3 mt-md-0" onClick={ e => me.createGroup() }>
                                                <IconFa icon="plus"/> {_("key_create_new")}
                                            </Button>
                                        </Col>
                                    </Row>               
                                </CardHeader>
                                <CardBody> 
                                    <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                        <Row>
                                            <Col md="6">
                                                <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                                            </Col>
                                            <Col md="6">
                                                <button className="btn btn-primary mt-3 mt-md-0 float-right" type="submit">
                                                    <IconFa icon="search"/> {_("key_filter_results")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <table className="table table-m-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">{_("key_name")}</th>
                                                <th scope="col">{_("key_actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {me.state.loadingGroups &&
                                            <tr>
                                                <td colSpan={6}>
                                                    <LoaderMini message={_("key_search_loading_results")}/>     
                                                </td>
                                            </tr>
                                        }
                                        {!me.state.loadingGroups && me.state.groups.map( (role: GroupUser) => 
                                            <tr key={role.id}>
                                                <td>{role.name}</td>
                                                <td className="p-2 actions">
                                                    <Button className="p-x-md-0" onClick={ e =>  me.selectGroup(role)} size="sm">
                                                        <IconFa icon="pencil"/> {_("key_edit")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>                    
                                    <Paginator 
                                        visible={!me.state.loadingGroups && me.state.groups.length > 0} 
                                        ref={ me._paginatorRef } 
                                        pagination={me.state.pagination} 
                                        onChange={ pagination => me.passPage(pagination) } />
                                </CardBody>
                            </Card>
                        :
                            <GroupForm onClose={ e => me.handleClose() } group={me.state.group} />
                        }
                    </Col>
                    <Col sm={12} md={2}></Col>
                </Row>
            </Fragment>
        );
    }
}

export default GroupPage;
