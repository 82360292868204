import React from "react";
import BaseComponent from "../../core/BaseComponent";
import ChatContactsProp from "../../entity/chat/props/ChatContactsProp";
import ChatContactsState from "../../entity/chat/states/ChatContactsState";
import { Button, IconFa, Row, Col } from '../../../cuba/components/utils/reactstrap'
import ContactBL from "../../bl/contact/ContactBL";
import { Contact } from "../../entity/contact/ContactEntity";
import LoaderMini from "../utils/LoaderMini";
import { Empty } from "../utils/Empty";
import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * Represents the component that begins a chat
 * @class ChatCreate
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatContacts extends BaseComponent<ChatContactsProp, ChatContactsState> {
    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ChatContactsProp){
        super(props);
        this.state = new ChatContactsState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
    }

    /**
     * Search contacts
     */
    private async search(){
        let me = this;

        me.setState({busy: true});
        var result = await ContactBL.getAllContacts(me.state.criteria);
        me.setState({
            busy: false,
            contacts: result
        })
    }
    
    /**
     * Select current contact
     * @param contact 
     */
    private selectContact(contact: Contact){
        let me = this;

        me.setState({
            contact: contact
        });
        me.props.onChange && me.props.onChange(contact);
    }

    /**
     * Handle input search keypress
     * @param e Event
     */
    private handleKeyPress(e){
        let me = this;
        if(e.key == "Enter"){
            e.preventDefault();
            me.search();
        }
    }

    /**
     * Close
     */
    private close(){
        let me = this;
        
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;
        return (
            <div className="chat_backdrop">
                <div className="chat_contenedor_image_preview chat-contacts-box">
                    <Row>
                        <Col md="6">
                            <input className="form-control" value={me.state.criteria} onKeyPress={ e => me.handleKeyPress(e) } onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                        </Col>
                        <Col md="6">
                            <Button onClick={ () => me.search() } className="btn btn-primary mt-3 mt-md-0 float-right" type="button">
                                <IconFa icon="search"/> {_("key_filter_results")}
                            </Button>
                        </Col>
                    </Row>
                    <div className="p-2">
                        <LoaderMini visible={me.state.busy} message={_("key_loading")} />
                        {me.state.contacts.length > 0 &&
                            <ul className="list-group">
                                {me.state.contacts.map( (c, i) => 
                                    <li onClick={ e => me.selectContact(c) } key={ i } className="list-group-item d-flex justify-content-between align-items-center">
                                        <b><IconFa icon={c.platform}/> {c.name}</b> {c.profile}
                                    </li>
                                )}
                            </ul>
                        }
                        <Empty visible={!me.state.busy && me.state.contacts.length < 1 } />
                    </div>
                    <Button 
                        color="danger" 
                        type="submit"
                        className="m-2"
                        onClick={ () => me.close() }
                        >
                        <IconFa icon="close"/> {_("key_textbox_cancel")}
                    </Button>
                </div>
            </div>
        );
    }
}