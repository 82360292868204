import IState from "../../../core/IState";
import { FormInputTranslate } from "../../../core/Translate";
import { UserLanguage } from "../../login/LoginEntity";
import { LocationName } from "../LocationEntity";

export default class LocationNameElementState implements IState {
    public locationName: LocationName = null;
    public languages: UserLanguage[] = [];
    public inputs: FormInputTranslate[] = [];
    public busy: boolean = false;
}