import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import AdminLocaleBL, { _ } from '../../../tsc/bl/admin/AdminLocaleBL';

const Breadcrumbs = (props) => {
  const [all, setAll] = useState(false);
  
  AdminLocaleBL.subscribeHandler(() => {
    setAll(!all);
  });

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <h3>{_(props.title)}</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/dashboard/default`}><Home /></Link></BreadcrumbItem>
                <BreadcrumbItem>{_(props.parent)}</BreadcrumbItem>
                <BreadcrumbItem active>{_(props.title)}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;