/**
 * Represents a Place
 */
export class Place {
    code: string;
    enabled: number = 0;
    id: number = 0;
    idParent: number = 0;
    latitude: number = 0;
    levelTree: number = 0;
    longitude: number = 0;
    name: string;
    path: string;
    polygon: string;
    timeZone: string;
    typePlace: number = 0;
    viewPort: string;

    // Relations
    parent: Place = null;
}