import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { GroupUser } from '../../entity/login/LoginEntity';
import { GroupPermissions, Role } from '../../entity/security/SecurityEntity';
import LoginBL from '../login/LoginBL';

/**
 * Perform operations in the security API
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class GroupBL extends BaseBL {
    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getGroups(paramsData: PaginatedRequest): Promise<PaginatedReponse<GroupUser>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/UserAPI/ListGroup', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, GroupUser, (group: GroupUser) => {
            group.groupPermissions = me.parseEntityArray(group.groupPermissions, GroupPermissions)
            return group;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param group Entity object
     * @returns response
     */
     public static async updateGroup(group: GroupUser) {
        let me = this;
        let formData = me.getEntityFormData(group);
        let response = await axios.post('/Home/UserAPI/UpdateGroup', formData);
        let responseData = me.getResponseData(response);
        let groupUpdated = me.parseEntity(responseData, GroupUser);
        return groupUpdated;
    }
}

export default GroupBL;