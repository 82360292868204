import React, { Component } from 'react';
import AdminLocaleBL from '../bl/admin/AdminLocaleBL';
import IProps from './IProp';
import IState from './IState';

/**
 * This component was created to control 
 * the update of translations in all components.
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export abstract class BaseComponent<Props extends IProps,State extends IState>extends React.Component<Props,State>{
    
    // Mount flag
    private _mounted: boolean = false;

    /**
     * Constructor
     * @param props 
     */
    constructor(props: Props) {
        super(props);
        AdminLocaleBL.subscribeComponent(this);
    }
    
    /**
     * Check the component is mounted
     */
     public get Mounted(): boolean {
        return this._mounted;
    }

    /**
     * Common calls
     */
     componentDidMount() {
        if(super.componentDidMount){
            super.componentDidMount();
        }
        // Mounted flag
        this._mounted = true;
    }

    componentWillUnmount(){
        if(super.componentWillUnmount){
            super.componentWillUnmount();
        }

        // Mounted flag
        this._mounted = false;
    }
}
export default BaseComponent;