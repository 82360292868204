/**
 * Represents a token of the API Tiny
 */
export default class ApiTinyToken {
    id: number;
    token: string;
    numberTimesUsed: number = 0;
    enabled: boolean = false;
    createDate: Date;
    usageRenewalDate: Date;
    updateDate: Date;
}