import { ExpandPlugin } from "./plugins/ExpandPlugin";
import { FilePlugin } from "./plugins/FilePlugin";
import { ImagePlugin } from "./plugins/ImagePlugin";
import { SuggestionsPlugin } from "./plugins/SuggestionsPlugin";

export const TEXTBOX_PLUGINS = {
    "image_uploader": new ImagePlugin(),
    "file_uploader": new FilePlugin(),
    "suggestions_selector": new SuggestionsPlugin(),
    "expand_textbox": new ExpandPlugin()
};