import IState from '../../../core/IState';

export class ResetPasswordState implements IState {
    public defaultState?: boolean = false;
    public busy: boolean = false;
    public email: string = "";
    public password: string = "";
    public passwordConfirm: string = "";
    public passwordLevel: any = {};
    public error: string = "";
    public message: string = "";
    public canLogin: boolean = false;
    public success: boolean = false;
}

export default ResetPasswordState;