import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Images } from "../ImagesEntity";

export type IAction = "create" | "update" | "view" | "delete" | "";
export default class ImagesPageState implements IState {
    public pagination: Pagination = new Pagination();
    public loadingImages: boolean = false;
    public images: Images[] = [];
    public criteria: string = "";
    public image: Images;
    public action: IAction;
    public filterEnabled: string = "1";
}