import { RequestForm } from '../../../component/request/RequestForm';
import IState from '../../../core/IState';
import { PairList } from '../../../helper/Pair';
import { UserInfo } from '../../chat/ChatEntity';
import { Pagination } from '../../control/ControlEntity';

export class RequestPageState implements IState {
    public defaultState?: boolean = false;
    public loadingRequestResults: boolean = false;
    public requestList: UserInfo[] = [];
    public currentRequest: UserInfo = null;
    public dateStart: Date = new Date();
    public criteria: string = "";
    public dateEnd: Date = new Date();
    public platform: string = "";
    public siteCode: string = "";

    // Pagination
    public pagination: Pagination = new Pagination();
}

export default RequestPageState;