import IState from '../../../core/IState';
import { ChatDisposition, MBTemplate } from '../ChatEntity';

export class ChatBoxState implements IState {
    public visible: boolean = true;
    public closed: boolean = false;
    public chatWritting: boolean = false;
    public chatTransfer: boolean = false;
    public message: string = "";
    public messages: Array<any> = [];
    public chatFindState: boolean = false;
    public chatFindText: string = "";
    public disposition: ChatDisposition = null;
    public dispositions: Array<ChatDisposition> = [];
    public templates: MBTemplate[] = [];
    public template: MBTemplate = null;
}

export default ChatBoxState;