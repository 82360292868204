
/**
 * Default response APIController
 */
export class APIResponse<T = APIResponseStatus> {
    public result: boolean = true;
    public data: T = null;
}

/**
 * Default response of the API layer
 */
export class APIResponseStatus<T = any> {
    public message: string = "";
    public status: number = 1;
    public response: T = null;
}