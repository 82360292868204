import React from 'react';
import ComponentCommon from '../../core/ComponentCommon';
import EmptyProp from '../../entity/utils/props/EmptyProp';
import EmptyState from '../../entity/utils/states/EmptyState';
import errorImg  from '../../../cuba/assets/images/search-not-found.png';
import { Media } from '../../../cuba/components/utils/reactstrap';

/**
 * Represents a result emptied with a message
 * @class Empty
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Empty extends ComponentCommon<EmptyProp, EmptyState> {
    /**
     * Constuctor
     * @param props Property 
     */
    constructor(props: EmptyProp){
        super(props);
        this.state = new EmptyState();
    }

    /**
     * Verify if the attribute was configured as visible
     */
    public get Visible(): boolean{let me = this;
        return me.props.visible == undefined || me.props.visible;
    }

    render(){
        let me = this;
        return (
            me.Visible ?
            <div className="text-center mt-4 mb-4">
                <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                { me.props.message && <h5>{me.props.message}</h5>}
            </div>
            : <></>
        );
    }
}