import React from "react";
import Reflection from "../../helper/Reflection";
import { UserLanguage } from "../login/LoginEntity";

/**
 * Timepicker model
 */
/*export class TimePair {
    public date: Date = new Date();
    public label: string = "";
    public value: string = "";
    public active: boolean = false;

    get TimeStamp() {
        return this.date.getTime()
    }
}*/

/**
 * Represents the current status of a pagination
 */
export class Pagination {
    constructor(currentPage: number = 1, totalPages: number = 1, totalItems: number = 20){
        let me = this;
        me.currentPage = currentPage;
        me.totalItems = totalItems;
        me.totalPages = totalPages;
    }

    public totalPages: number = 1;
    public totalItems: number = 20;
    public items: number = 20;
    public currentPage: number = 1;

    public setTotalPages(totalPages: number){
        this.totalPages = totalPages;
        return this;
    }
    public setTotalItems(totalItems: number){
        this.totalItems = totalItems;
        return this;
    }
    public setCurrentPage(currentPage: number){
        this.currentPage = currentPage;
        return this;
    }
}

/**
 * Data to handle the page
 */
export class PaginatorButtonData {
    public range: Array<number> = [];
    public haveStart: boolean = true;
    public haveEnd: boolean = true;
    public lastPage: number = 0;
    public nextPage: number = 0;
    public previewPage: number = 0;
    public totalPages: number = 0;
}

/**
 * Enclose the paginal results of a query (Symfony)
 */
 export class PaginatedReponse<T = any> {
    public data = [];
    public pagination: Pagination = null;
}

/**
 * Represents a paginal request
 */
export class PaginatedRequest<T = RequestSearch> {
    constructor(defaultData = null) {
        let me = this;
        let def = defaultData ? defaultData : new RequestSearch();
        def && (me.data = def);
    }
    public data: T;
    public pagination: Pagination = new Pagination();
}

/**
 * Represents a paginal request
 */
 export class PaginatedWithIdRequest<T = RequestWithIdSearch> {
    constructor(defaultData = null) {
        let me = this;
        let def = defaultData ? defaultData : new RequestWithIdSearch();
        def && (me.data = def);
    }
    public data: T;
    public pagination: Pagination = new Pagination();
}

/**
 * Default search parameters for text search
 */
export class RequestSearch {
    public criteria: string = "";
}

/**
 * Default search parameters for text search
 */
 export class RequestWithIdSearch {
    public id: number;
    public criteria: string = "";
}

/**
 * Represents a checkbox list item
 */
export class CheckListElement {
    public value: string = "";
    public label: string = "";
    public checked: boolean = false;

    /**
     * Convert to a CheckListElement
     * @param values Values to convert
     * @param label label to use
     * @param key key value
     * @returns CheckListElement[]
     */
    public static getFrom(values: any[], label: string, key: string = "id"): CheckListElement[] {
        let me = this;
        let data = values.map( value => {
            var element = new CheckListElement();
            element.value = value[key];
            element.label = value[label];
            return element;
        });
        return data;
    }
}
