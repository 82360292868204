import React from 'react';
import LandingPageProp from "../entity/landing/props/LandingPageProp";
import LandingPageState from "../entity/landing/states/LandingPageState";
import { Card, CardHeader, CardBody, Row, Col, Form, Button, IconFa } from '../../cuba/components/utils/reactstrap';
import { LandingBL } from '../bl/Landing/LandingBL';
import Paginator from '../component/control/Paginator';
import { PaginatedRequest, PaginatedWithIdRequest, Pagination } from '../entity/control/ControlEntity';
import LandingElement from '../component/landing/LandingElement';
import LandingForm from '../component/landing/LandingForm';
import PageCommon from '../core/PageCommon';
import LoaderMini from '../component/utils/LoaderMini';
import { Empty } from '../component/utils/Empty';
import { Pair } from '../helper/Pair';
import { CustomerSite, CustomerSiteLandingPage } from '../entity/landing/LandingEntity';
import LoaderBackdrop from '../component/utils/LoaderBackdrop';
import { _ } from '../bl/admin/AdminLocaleBL';

/**
 * Component to manage translations
 * @class LandingPage
 */
export class LandingPage extends PageCommon<LandingPageProp, LandingPageState> {
    private _paginatorRef = null;

    constructor(props: LandingPageProp){
        super(props);
        let me = this;
        me.state = new LandingPageState();
        me._paginatorRef = React.createRef();
    }

    componentDidMount(){
        let me = this;
        me.prepare();
    }

    private async prepare() {
        let me = this;
        await me.getCustomer();
    }

    /**
     * Make the query by page
     */
     private async searchResult(){
        let me = this;

        let request = new PaginatedWithIdRequest();
        request.pagination = me.state.pagination;
        request.data.id = +me.state.currentCustomerSite
        request.data.criteria = me.state.criteria;

        me.setState({
            busy:true,
            busySaving: false,
            itemsLandingPage: []
        });
        let data = await LandingBL.getCustomerSiteLandingPage(request);
        // let dataLanguage = await LocaleBL.getLanguages();
        
        me.setState({
            busy: false,
            pagination: data.pagination,
            itemsLandingPage: data.data,
            currentItemLandingPage: null
        }, () => {
            me._paginatorRef.current.update()
        });
    }


    private async getCustomer() {
        let me = this;

        let data = await LandingBL.getCustomer();

        let customers = data.data.data.response; 
        
        me.setState({
            busy: false,
            customers: customers,
            currentCustomer: ""+customers[0]['id']
        }, () => {
            me.getCustomerSite();
            // me._paginatorRef.current.update()
        });
    }

    private changeCurrentCustomer(currentCustomer: string) {
        let me = this
        me.setState({
            currentCustomer: currentCustomer
        }, () => {
            me.getCustomerSite()
        })
    }

    private async getCustomerSite() {
        let me = this;

        let data = await LandingBL.getCustomerSite(+me.state.currentCustomer)

        let customerSite = data.data.data.response

        if (customerSite.length > 0) {
            me.setState({
                customerSite: customerSite,
                currentCustomerSite: ""+customerSite[0]['id']
            }, () => {
                me.searchResult()
            })
        } else {
            me.setState({
                customerSite: customerSite,
                currentCustomerSite: ""
            })
        }

        
    }

    private changeCurrentCustomerSite(currentCustomerSite: string) {
        let me = this;
        me.setState({
            currentCustomerSite: currentCustomerSite
        })
    }

    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.setState({
            pagination: me.state.pagination.setCurrentPage(1)
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Edit suggestion
     * @param suggestion Suggestion object 
     */
    private editItemLandingPage(landing: CustomerSiteLandingPage){
        let me = this;
        me.setState({
            currentItemLandingPage: null
        }, () => {
            me.setState({
                currentItemLandingPage: landing
            })
        });
    }

    /**
     * Funcion para poder poner el loading en la pagina
     * @returns void
     */
    public loadingSave() {
        let me = this;
        me.setState({
            busySaving: true
        })
    }

    public deleteCurrentItemLandingPage() {
        let me = this;
        me.setState({
            currentItemLandingPage: null
        })
    }

    render() {
        let me = this;
        return (
            <React.Fragment>
                {me.state.busySaving && <LoaderBackdrop visible={true} message={_("key_data_saving")}/>}
                { me.state.currentItemLandingPage == null ?
                    <Card>
                        <CardBody>
                            <Form onSubmit={ e => me.handleFormSubmit(e) } className="theme-form">
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <h6 className="form-label">{_("key_client")}</h6>
                                            <select value={me.state.currentCustomer} onChange={ e => me.changeCurrentCustomer(e.target.value)} className="form-control">
                                                {me.state.customers.map( customer => 
                                                    <option key={customer.id} value={customer.id}>{ customer.name }</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <h6 className="form-label">{_("key_site")}</h6>
                                            <select value={me.state.currentCustomerSite} onChange={ e => me.changeCurrentCustomerSite(e.target.value )} className="form-control">
                                                {me.state.customerSite.map( customerSite => 
                                                    <option key={customerSite.id} value={customerSite.id}>{ customerSite.name }</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <input 
                                                onChange={ e => me.setState({ criteria: e.target.value }) } 
                                                value={ me.state.criteria } 
                                                className="form-control" 
                                                type="text" 
                                                placeholder={_("key_search_criteria")}
                                                />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col>
                                        <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder="Criterios de búsqueda"/>
                                    </Col> */}
                                    <Col>
                                        <div className="text-center p-2">
                                            <Button type="submit" color="primary"><IconFa icon="search"/> {_("key_search")}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                            <table className="table tableDsn">
                                <thead>
                                    <tr>
                                        <th scope="col">{_("key_url")}</th>
                                        <th scope="col">{_("key_state")}</th>
                                        <th scope="col">{_("key_service")}</th>
                                        <th scope="col">{_("key_type")}</th>
                                        <th scope="col">{_("key_h1")}</th>
                                        <th scope="col">{_("key_actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {me.state.busy &&
                                        <tr>
                                            <td colSpan={6}>
                                                <LoaderMini message={_("key_search_loading_results")}/>     
                                            </td>
                                        </tr>
                                    }
                                    {me.state.itemsLandingPage.map( function(landing: CustomerSiteLandingPage, key) { 
                                        let urlRequest = ''
                                        let totalCustomerUrl = landing.customerUrlSiteLandingPages.length
                                        if (totalCustomerUrl > 1) {
                                            landing.customerUrlSiteLandingPages.map(function (val, key) {
                                                if (val.customerUrl != null && val.customerUrl.language == 'EN') {
                                                    urlRequest = val.customerUrl.urlRequest
                                                }
                                            })
                                        } else {
                                            urlRequest = landing.customerUrlSiteLandingPages[0]?.customerUrl.urlRequest
                                        }
                                        return(
                                            <tr key={landing.id}>
                                                <td>{urlRequest}</td>
                                                <td>{landing.enabled ? _("key_enabled") : _("key_disabled")}</td>
                                                <td>{landing.service}</td>
                                                <td>{landing.typeLandingPage}</td>
                                                <td>{landing.h1}</td>
                                                <td className="p-2">
                                                    <Button onClick={ e =>  me.editItemLandingPage(landing)} size="sm">
                                                        <IconFa icon="pencil"/> {_("key_edit")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Paginator visible={me.state.itemsLandingPage.length > 0} ref={ me._paginatorRef } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                        </CardBody>
                    </Card>
                :
                    <LandingForm landingElement={me.state.currentItemLandingPage} searchLanding={() => me.searchResult()} notifySaving={() => me.loadingSave()} cancelUpdate={() => me.deleteCurrentItemLandingPage()} />
                }
            </React.Fragment>
        );
    }
}

export default LandingPage;