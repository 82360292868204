import React, { Fragment } from "react";
import ComponentCommon from "../../core/ComponentCommon";
import LoaderBackdropProp from "../../entity/utils/props/LoaderBackdropProp";
import LoaderBackdropState from "../../entity/utils/states/LoaderBackdropState";

/**
 * Shows a loader: blocking the screen
 * @class LoaderBackdrop
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LoaderBackdrop extends ComponentCommon<LoaderBackdropProp, LoaderBackdropState> {

    private fegaussianblur =  `<fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur>`;
    private fecolormatrix =  `<fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix>`;

    public constructor(props){
        super(props);
    }

    render() {
        let me = this;
        return (
            me.props.visible ?
            <Fragment>
                <div className={`loader-wrapper loader-wrapper__backdrop ${me.props.visible ? '' : 'loderhide'}`}>
                    <div>
                        <div className="loader-index">
                            <span></span>
                        </div>
                        {me.props.message && <h3 className="text-primary">{me.props.message}</h3>}
                    </div>
                    <svg>
                        <defs></defs>
                        <filter id="goo" dangerouslySetInnerHTML={{__html: me.fecolormatrix + me.fegaussianblur}} >
                        </filter>
                    </svg>
                </div>
            </Fragment>
            : <></>
        );
    }
}

export default LoaderBackdrop;