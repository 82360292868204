import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { User } from "../../login/LoginEntity";

export default class ChatTransferState implements IState {
    public agents: User[] = [];
    public busy: boolean = false;
    
    public pagination: Pagination = new Pagination();
    public criteria: string = "";
}
