import axios from "axios";
import BaseBL from "../../core/BaseBL";

/**
 * Perform operations in the reports API
 * @class ReportsBL
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class ReportsBL extends BaseBL {
    /**
     * Get reports result
     * @param reportName 
     */
    public static async getResults(reportName: string, parameters: any){
        let me = this;
        
        var data = {
            params: parameters
        };
        
        let response = await axios.get('/Home/ReportAPI/' + reportName, data);
        return response.data;
    }
}
