import IState from "../../../../core/IState";
import {Pagination} from "../../../control/ControlEntity";
import ApiTinyToken from "../ApiTinyTokenEntity";

export default class ApiTinyTokenPageState implements IState {
    public pagination: Pagination = new Pagination();
    public loadingTokens: boolean = false;
    public apiTinyTokens: ApiTinyToken[] = [];
    public criteria: string = "";
    public apiTinyToken: ApiTinyToken;
}
