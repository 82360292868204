import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import TimeRangeProp from "../../entity/control/props/TimeRangeProp";
import TimeRangeState from "../../entity/control/states/TimeRangeState";
import TimePicker from "./TimePicker";
import { Row, Col, Button, IconFa} from '../../../cuba/components/utils/reactstrap'
import Alert from "../utils/Alert";
import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * TimeRange component
 * @class TimeRange
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class TimeRange extends ComponentCommon<TimeRangeProp, TimeRangeState>{

    /** Refs */
    private _timepickerStart = null;
    private _timepickerFinal = null;

    /**
     * Constructor
     * @param props Property
     */
    public constructor(props){
        super(props);
        this.state = new TimeRangeState();
        this._timepickerStart = React.createRef();
        this._timepickerFinal = React.createRef();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare(){
        let me = this;
        var value = me.props.value;
        var parts = value ? value.split("-") : [];
        if(parts.length > 1){
            var start = parts[0];
            var final = parts[1];
            me.setTimeRange(start, final);
        }
    }

    /**
     * Restart the current range
     */
    private async clearTime(){
        let me = this;
        var result = await Alert.confirm(_("key_timerange_clear"), _("key_timerange_clear_message"));
        if(result.isConfirmed){
            me.setTimeRange("", "");
            me._timepickerStart.current?.clear();
            me._timepickerFinal.current?.clear();
            me.props.onChange && me.props.onChange(null);
            me.props.onClear && me.props.onClear();
        }
    }

    /**
     * Set current time range
     * @param start Initial time
     * @param final Final time
     */
    public setTimeRange(start: string, final: string){
        let me = this;
        var range = `${start}-${final}`;
        me.setState({
            startHour: start,
            finalHour: final,
            range: range.length > 1 ? range : null
        });
        me._timepickerStart.current.setValue(start);
        me._timepickerFinal.current.setValue(final);
    }

    /**
     * Handle the initial time
     * @param time Time string
     */
    private handleStart(time: string){
        let me = this;
        me.setState({
            startHour: time
        }, () => me.checkComplete());
    }
    
    /**
     * Handle the final time
     * @param time Time string
     */
    private handleFinal(time: string){
        let me = this;
        me.setState({
            finalHour: time
        }, () => me.checkComplete());
    }

    /**
     * Verify if the range is complete
     */
    private checkComplete(){
        let me = this;
        var start = me.state.startHour;
        var final = me.state.finalHour;
        if(start?.length > 0 && final?.length > 0){
            var range = `${start}-${final}`;
            me.setState({
                range: range
            });
            me.props.onChange && me.props.onChange(range);
        }
    }

    render() {
        let me = this;
        return (
            <Row className="p-2">
                {me.props.label.length > 0 && 
                    <Col sm="12" md="2">{ me.props.label}</Col>
                }
                <Col sm="12" md="4" className="p-1">
                    <TimePicker minutesDiff={30}
                                selectedSign={true}
                                ref={ me._timepickerStart }
                                value={me.state.startHour}
                                onChange={ time => me.handleStart(time) } />
                </Col>
                <Col sm="12" md="4" className="p-1">
                    <TimePicker minutesDiff={30}
                                selectedSign={true}
                                ref={ me._timepickerFinal }
                                value={me.state.finalHour}
                                onChange={ time => me.handleFinal(time) } />
                </Col>
                <Col sm="12" md="2">
                    {me.state.range &&
                        <Button type="button"
                                className="pl-2 pr-2"
                                onClick={ e => me.clearTime() }>
                                <IconFa icon="trash"/> {_("key_delete")}
                        </Button>
                    }
                </Col>
            </Row>
        );
    }
}

export default TimeRange;