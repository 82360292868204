import moment from "moment/moment";
import {User} from "../login/LoginEntity";

/**
 * Images
 */
export class Images {
	public id: number = 0;
	public idUser: number = 0;
	public name: string = "";
	public extension: string = "";
	public path: string = "";
	public thumbnailPath: string = "";
	public sizeByte: number = 0;
	public enabled: number = 1;
	public user: User = null;
    public createdDate: Date = new Date();

	public get CreatedDateText(){
		let me = this;
		if(me.createdDate){
			return moment(me.createdDate, "YYYY-MM-DD H:mm:ss").format('DD/MM/YYYY HH:mm:ss');
		}
		return "";
	}
}