/**
 * Connect with the API Miles (Azure blobs)
 * @Class FileUpload 
*/
export class FileUploader {
	private button: HTMLInputElement;
	private server: string = null;

	/**
	 * Constructor
	 * @param server Miles sever URL
	 */
	constructor(server: string) {
		let me = this;
		this.server = server;
		this.button = document.createElement("input");
		this.button.type = "file";
		this.button.style.display = "none";
		document.body.appendChild(this.button);

		this.button.onchange = function () {
			let files = me.button.files;
			if (files.length > 0) {
				me.upload(files[0]);
			}
		}
    }

	/**
	 * Opens the File Selection dialog
	 */
	public open(onchange: CallableFunction = null) {
		let me = this;
		me.button.click();
		if(onchange) {
			me.button.onchange = function () {
				let files = me.button.files;
				if (files.length > 0) {
					onchange(files);
				}
			}
		}
    }

	/**
	 * Perform the file load to the Miles server
	 * @param file File to upload
	 * @param onSuccess On success event
	 * @param onError On error event
	 */
	public upload(file: File, onSuccess: CallableFunction = null, onError: CallableFunction = null) {
		let me = this;
		var formData = new FormData();
		formData.append('file', file, file.name);
		var xhr = new XMLHttpRequest();
		xhr.open('POST','/Home/AzureAPI/Upload', true);
		xhr.onload = function (r) {
			if (xhr.status === 200) {
				r = JSON.parse(r.srcElement["response"]);
				onSuccess && onSuccess(r);
			} else {
				onError && onError(r);
			}
		}
		xhr.onerror = function (e) {
			onError && onError(e);
        }
		xhr.send(formData);
	}

	/**
	 * Delete a file from the Miles server
	 * @param fileName
	 * @param onSuccess
	 * @param onError
	 */
	public delete(fileName: string, onSuccess: CallableFunction = null, onError: CallableFunction = null){
		const xhr = new XMLHttpRequest();
		xhr.open('DELETE','/Home/AzureAPI/Delete?fileName=' + fileName, true);
		xhr.onload = function (r) {
			if (xhr.status === 200) {
				r = JSON.parse(r.srcElement["response"]);
				onSuccess && onSuccess(r);
			} else {
				onError && onError(r);
			}
		}
		xhr.onerror = function (e) {
			onError && onError(e);
		}
		xhr.send();
	}
}

export default FileUploader;