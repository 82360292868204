import React from 'react';
import ChatSuggestionBL from '../../bl/chat/ChatSuggestionBL';
import ComponentCommon from '../../core/ComponentCommon';
import ChatSuggestionProp from '../../entity/chat/props/ChatSuggestionsProp';
import ChatSuggestionState from '../../entity/chat/states/ChatSuggestionsState';
import $ from 'jquery';
import { ChatSuggestion } from '../../entity/chat/ChatEntity';
import LoaderMini from '../utils/LoaderMini';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { UserLanguage } from '../../entity/login/LoginEntity';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import ChatTextBox from './ChatTextBox';
import { SuggestionsPlugin } from '../control/textbox/plugins/SuggestionsPlugin';
import TextBox from '../control/TextBox';
import { MilesContext } from '../../context/MilesContext';

/**
 * Show agent conversation suggestions
 * @class ChatSuggestion
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatSuggestions extends ComponentCommon<ChatSuggestionProp, ChatSuggestionState> {

    private _searchTimeout = null;
    private _searchInput = null;
    static contextType = MilesContext;

    /**
     * ChatSuggestions constructor
     * @param props Property
     */
    constructor(props: ChatSuggestionProp){
        super(props);
        let me = this;
        me.state = new ChatSuggestionState();
        me._searchInput = React.createRef();
    }
    
    /**
     * On component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;

        let languages = await LocaleBL.getLanguages();
        let language = languages.find(lang => lang.code === me.props.language);
        me.setState({
            languages: languages,
            language: language
        });

        // Add the button when the DOM has been loaded.
        setTimeout(() => {
            // Add custom ckeditor button (ckeditor lib limitation)
            SuggestionsPlugin.addOnClickSuggestions(me.props.chat.chatTopic, () => {
                me.setState({
                    visible: true
                })
            });
            /*ChatTextBox.addEditorButton(me.props.chat.chatTopic, "suggestion", "key_suggestions", () => {
                me.setState({
                    visible: true
                })
            });*/
        }, 2000);
    }

    /**
     * Filter suggestions for the specified criteria
     * @param criteria Text to search
     */
    private async findSuggestions(criteria: string = null){
        let me = this;
        criteria = criteria == null ? me.state.criteria : criteria;
        me.setState({busy: true});
        let suggestions = await ChatSuggestionBL.findSuggestions(me.state.language.code, criteria, me.props.chat.siteCode);
        me.setState({
            busy: false,
            suggestions: suggestions
        });
    }

    /**
     * Close suggestions component
     */
    private close(){
        let me = this;
        me.setState({
            visible: false
        })
    }

    /**
     * Select the specified suggestion from the list and close
     * @param suggestion Selected suggestion
     */
    private selectSuggestion(suggestion: ChatSuggestion){
        let me = this;
        me.props.onSelect && me.props.onSelect(suggestion);
        me.close();
    }

    /**
     * Handle input search value
     * @param e Event
     */
     private handleSearch(e){
        let me = this;
        let criteria = e.target.value;
        me.setState({
            criteria: criteria
        });
        
        // Set timeout
        clearTimeout(me._searchTimeout);
        me._searchTimeout = setTimeout(function(){
            me.findSuggestions(criteria);
        }, 1000);
    }

    /**
     * Handle input search keypress
     * @param e Event
     */
    private handleKeyPress(e){
    }

    /**
     * Establish language of the suggestion
     * @param language 
     */
     private setLanguage(language: UserLanguage){
        let me = this;
        me.setState({
            language: language
        }, () => {
            me.findSuggestions();
        });
    }
    
    render() {
        let me = this;
        
        return (
            <>
            {me.state.visible ?
                <div className={`card chat-suggestions-box ${me.context.chat.Expand ? "chat-suggestions-box--floating":""}`}>
                    <div className="card-header p-2">
                        <ul className="chat-menu-icons">
                            <li className="list-inline-item">
                                <input ref={me._searchInput} onKeyPress={ e => me.handleKeyPress(e) } onChange={ e => me.handleSearch(e) } className="form-control" type="text" placeholder={_("key_suggestions_find")} value={ me.state.criteria }/>
                            </li>
                            <li className="list-inline-item">
                                <a onClick={ () => me.findSuggestions() }><i className="icon-search"></i></a>
                            </li>
                            <li className="list-inline-item">
                                { me.state.languages && me.state.languages.map( (lang: UserLanguage) => 
                                    <div key={lang.id} className={`form-check form-check-inline radio radio-${lang.id == me.state.language.id ? 'success': "primary"}`}>
                                        <input onChange={ e => me.setLanguage(lang) } checked={lang.id == me.state.language.id} className="form-check-input" id={`label_sug_${lang.id}`} type="radio" name={`chat_suggestion_${lang.id}`}/>
                                        <label className="form-check-label mb-0" htmlFor={`label_sug_${lang.id}`}>{lang.name}</label>
                                    </div>
                                )} 
                            </li>
                            <li className="list-inline-item float-right">
                                <a onClick={ () => me.close() }><i className="icon-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <LoaderMini visible={me.state.busy} message={_("key_suggestions_loading")}/>
                    <ul className="list-group">
                        {!me.state.busy && me.state.suggestions.map( (s, i) => {
                            return (
                            <li onClick={ e => me.selectSuggestion(s) } key={ i } className="list-group-item d-flex justify-content-between align-items-center">
                                {s.SuggestionText}
                            </li>
                            );
                        })}
                    </ul>
                    {/** No results found */}
                    {!me.state.busy && me.state.suggestions.length == 0 &&
                        <div className="text-center p-3">{_("key_suggestions_not_found")}</div>
                    }
                </div>
                :
                <></>
            }
            </>
        );
    }
}

export default ChatSuggestions;