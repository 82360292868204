/**
 * Default setting with styles for the LandingPages editor
 */
export const SUGGESTION_EDITOR_CONFIG =  {
    height: 250,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount code'
    ],
    entity_encoding : "raw",
    toolbar: 'undo redo | styleselect | formatselect | bold italic | bullist numlist | imgBtn | code',
    force_br_newlines : true,
    force_p_newlines : false,
    forced_root_block : '' // Needed for 3.x
};
