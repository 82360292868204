import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { LocationName, LocationNameTranslate } from "../../entity/location/LocationEntity";

/**
 * Get and manage Locations
 * @class LocationNameBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationNameBL extends BaseBL {
    
    /**
     * Save the specified static content
     * @param locationName Location
     * @returns Updated object
     */
     public static async updateLocationName(locationName: LocationName): Promise<LocationName> {
        let me = this;
        let formData = me.getEntityFormData(locationName);
        let response = await axios.post('/Home/LocationAPI/UpdateLocationName', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocationName);
        return result;
    }

    /**
     * Save the specified static content
     * @param locationNameTranslate Location
     * @returns Updated object
     */
     public static async updateLocationNameTranslate(locationNameTranslate: LocationNameTranslate): Promise<LocationNameTranslate> {
        let me = this;
        let formData = me.getEntityFormData(locationNameTranslate);
        let response = await axios.post('/Home/LocationAPI/UpdateLocationNameTranslate', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocationNameTranslate);
        return result;
    }
}