import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { PaginatedReponse, PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { Agency, Location } from "../../entity/location/LocationEntity";
import { Place } from "../../entity/place/PlaceEntity";

/**
 * Get and manage Locations
 * @class LocationBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationBL extends BaseBL{
    private static _agencies: Agency[] = [];

    /**
     * Gets a listing page of translations
     * @param paramsData Parameter data
     * @returns 
     */
     public static async getLocation(paramsData: PaginatedRequest): Promise<PaginatedReponse<Location>> {
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/LocationAPI/ListLocation', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);

        result.data = me.parseEntityArray(result.data, Location, (location: Location) => {
            location.place = me.parseEntity(location.place, Place);
            location.agency = me.parseEntity(location.agency, Agency);
            return location;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Gets a listing page of translations
     * @param paramsData Parameter data
     * @returns 
     */
     public static async getAgencies(): Promise<Agency[]> {
        let me = this;
        if(me._agencies.length > 0){
            return me._agencies;
        }
        
        let response = await axios.get('/Home/LocationAPI/GetAgencies');
        let responseData = me.getResponseData(response);
        me._agencies = me.parseEntityArray(responseData, Agency);
        return me._agencies;
    }

    /**
     * Save the specified static content
     * @param location Location
     * @returns Updated object
     */
    public static async updateLocation(location: Location): Promise<Location> {
        let me = this;
        let formData = me.getEntityFormData(location);
        let response = await axios.post('/Home/LocationAPI/UpdateLocation', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, Location);
        return result;
    }

    /**
     * Find location by id
     * @param idLocation 
     * @returns 
     */
    public static async getLocationById(idLocation: number): Promise<Location>{
        let me = this;
        let data = {
            params: {
                id: idLocation
            }
        };
        let response = await axios.get('/Home/LocationAPI/GetLocationById', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, Location);
        return result;
    }
}