import { BaseTranslate, BaseTranslation } from "../../core/Translate";
import GeneralFunctions from "../../helper/GeneralFunctions";
import { Place } from "../place/PlaceEntity";

/**
 * FAQs
 */
export class Faqs extends BaseTranslate {
    public idFaqs: number = 0;
    public idPlace: number = 0;
    public question: string = "";
    public answer: string = "";
    public icon: string = "";
    public iso: string = "";
    public createDate: Date = new Date();
    public updateDate: Date = null;
    public isFrequent: boolean = false;
    public isDefault: boolean = false;
    public enabled: boolean = false;
    public position: number = 0;
    
    // Relations
    public place: Place = null;
    
	/**
	 * Returns a text without HTML labels
	 */
	public get AnswerText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.answer);
	}

	/**
	 * Returns a text without HTML labels
	 */
	public get QuestionText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.question);
	}
}

/**
 * Faqs translations
 */
export class FaqsGeolocation extends BaseTranslation {
    public idFaqsGeolocation: number = 0;
    public idPlace: number = 0;
    public question: string = "";
    public answer: string = "";

    // Relations
    public place: Place = null;

	/**
	 * Returns a text without HTML labels
	 */
	public get AnswerText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.answer);
	}

	/**
	 * Returns a text without HTML labels
	 */
	public get QuestionText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.question);
	}
}
