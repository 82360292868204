import axios, { AxiosResponse } from "axios";
import { APIResponse, APIResponseStatus } from "../entity/common/CommonEntity";
import Reflection from "../helper/Reflection";

axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === 401) {
        return Promise.resolve(error);
    }
 }
);

/**
 * Performs common operations of all BL
 * @class BaseBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class BaseBL extends Reflection {

    // Last error 
    protected static lastError: APIResponseStatus = null;

    /**
     * Last error
     * @returns Info
     */
    public static getLastError(): APIResponseStatus {
        return this.lastError;
    }

    /**
     * Has errors
     */
    public static hasErrors(){
        return this.lastError !== null;
    }
    
    /**
     * Verify if the answer is correct
     * @param data Response data
     * @returns True: success, False: Error
     */
    public static checkResponse(data: AxiosResponse<any>) {
        let me = this;
        var result = me.formatResponse(data);
        return result !== null;
    }

    /**
     * Gets the essential data as a response and performs the response verification process
     * @param data Input response
     * @returns Data
     */
    public static getResponseData(data: AxiosResponse<any>){
        let me = this;
        var formatData = me.formatResponse(data);
        return formatData?.data?.response;
    }

    /**
     * Make the default format of an API response
     * @param data Input response
     * @returns Formatted data
     */
    public static formatResponse(data: AxiosResponse<any>): APIResponse {
        let me = this;
        var response = me.parseEntity(data.data, Response) as APIResponse;
        response.data = me.parseEntity(response.data, APIResponseStatus);

        if((""+data).includes("401") && location.pathname !== "/"){
            location.href = "/";
            me.onApiError(response.data.message);
            return null;
        }

        if(data.status !== 200){
            me.onError(data.status);
            return null;
        }

        if(!response.result) {
            me.lastError = response.data;
            me.onServerError();
            return null;
        }
        if(response.result && response.data.status !== 1) {
            me.onApiError(response.data.message);
            return null;
        }

        me.lastError = null;
        return response;
    }

    /**
     * On http errorr
     * @param code Error code
     */
    public static onError(code: number){
        console.error(`BaseBL: Error en la respuesta del servidor [${code}]`);
    }

    /**
     * On handled server error
     */
    public static onServerError(){
        console.error(`BaseBL: Error en la respuesta del servidor`);
    }

    /**
     * On API error
     * @param message Message 
     */
    public static onApiError(message: string = ""){
        console.error(`BaseBL: La api devolvió un mensaje de error ${message}`);
    }
}

export default BaseBL;