import React from "react";
import { UserLanguage } from "../entity/login/LoginEntity";


/**
 * Help treat all languages with the same method
 * This class helps perform the saving and update process
 * both of translations and the parent element.
 */
 export class FormInputTranslate {
    public language: UserLanguage = null;
    public code: string;
    public label: string;
    public value: any = null;
    public save: any = null;

    // Form inputs refs
    public refs: any[] = [];

    // BLs
    private static originBLSave = null;
    private static targetBLSave = null;

    /**
     * Prepare the BLS that apply to this translation
     * @param originBLSave Parent BL Save
     * @param targetBLSave Child translate BL Save
     * @returns FormInputTranslate
     */
    public static prepare(originBLSave, targetBLSave){
        let me = this;
        me.originBLSave = originBLSave;
        me.targetBLSave = targetBLSave;
        return me;
    }

    /**
     * Create a reference for each field of the form for
     * autosave.
     * @param key 
     * @returns 
     */
    public createRef(key: string){
        let me = this;
        var ref = me.refs[key];
        if(ref == undefined){
            ref = React.createRef();
            me.refs[key] = ref;
        }
        return ref;
    }

    /**
     * Gets the saved value of a reference from a
     * Form field.
     * @param key 
     * @returns 
     */
    public getValue(key: string){
        let me = this;
        var value = "";
        var ref = me.createRef(key);
        if(ref.current.getValue !== undefined){
            value = ref.current.getValue();
        } 
        if(ref.current.value !== undefined){
            value = ref.current.value;
        }
        return value;
    }

    /**
     * Update the values according to the references.
     */
    public update(){
        let me = this;
        var keys = Object.keys(me.refs);
        keys.forEach( key => {
            let value = me.getValue(key);
            me.value[key] = value;
        });
    }

    /**
     * Update the data from the fields and execute the BL.
     */
    public async saveSelf(){
        let me = this;
        me.update();
        me.value = await me.save(me.value);
    }

    public static generate(languages: UserLanguage[], baseElement: BaseTranslate): FormInputTranslate[] {
        let me = this;
        var list = [];
        // Common form
        languages.forEach( l => {
            var langCode = l.code.toUpperCase();
            var data = new FormInputTranslate();
            data.language = l;
            data.code = langCode;
            data.label = l.label;
            
            // Generate element
            if(data.code == "EN") {
                // Parent element
                data.value = baseElement;
                data.save = me.originBLSave;
            } else {
                // Translate
                data.save = me.targetBLSave;
                baseElement.translates.forEach( tr => {
                    if(tr.language.toUpperCase() == data.code){
                        data.value = tr;
                    }
                });

                // Default if not exists
                if(!data.value){
                    var type = baseElement.translationType;
                    type = type ? type : BaseTranslation;
                    var locationTranslate = new type;
                    locationTranslate.idRelation = baseElement.id;
                    locationTranslate.language = data.code;
                    data.value = locationTranslate;
                }
            }
            list.push(data);
        });
        return list;
    }

    /**
     * Find the Father element for translations
     * @param inputs FormInputTranslate[] elements
     * @returns FormInputTranslate
     */
    public static getParent(inputs: FormInputTranslate[]): FormInputTranslate{
        var found = inputs.filter( f => f.code == "EN")[0];
        return found;
    }

    /**
     * Save a list of encapsulated form fields
     * In the current class (FormInputTranslate).
     * @param inputs Input list
     * @returns 
     */
    public static async save(inputs: FormInputTranslate[]) {
        let me = this;
        var mainForm = me.getParent(inputs);
        await mainForm.saveSelf();

        inputs.forEach( form => {
            if(form.code !== mainForm.code) {
                // Update identifier
                form.value["language"] = form.code;
                form.value["idRelation"] = mainForm.value["id"];
                form.saveSelf();
            }
        });

        return inputs;
    }
}

/**
 * Translaving object
 */
export class BaseTranslate {
    public id: number = 0;
    public translates: BaseTranslation[] = [];
    public translationType: any = BaseTranslation;
}

/**
 * Translation
 */
export class BaseTranslation {
    public id: number = 0;
    public idRelation: number = 0;
    public language: string;

    public getValue() {
        return null;
    }
}