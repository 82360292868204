import { RatingForm } from "../component/reports/chat/forms/RatingForm";
import { RatingResult } from "../component/reports/chat/results/RatingResult";
import { Report, ReportCategory } from "../entity/report/ReportEntity";

// Administrator reports
export const REPORTS = {
    CHAT: new ReportCategory({
        name: 'chat',
        title: 'key_mod_page_chat',
        reports: [
            new Report({
                report: "ChatRatings",
                name: 'key_reports_chat_rating',
                form: RatingForm,
                result: RatingResult
            })
        ]
    }),
    // Example
    /*DEFAULT: new ReportCategory({
        name: 'default',
        title: 'key_mod_page_user',
        reports: [
            new Report({
                report: "ChatRatings",
                name: 'key_reports_chat_rating',
                form: RatingForm,
                result: RatingResult
            })
        ]
    })*/
};

export const REPORTS_LIST = Object.values(REPORTS);
