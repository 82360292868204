import axios, { AxiosResponse } from "axios";
import BaseBL from "../core/BaseBL";

/**
 * Manage the sending of messages in real time to Mercure
 * @class Mercure
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Mercure extends BaseBL {
    /**
     * Send a message with your attributes
     * @param topic Topic of the conversation
     * @param strUserType User type (chatbot, customer, agent)
     * @param strMessage Message
     * @param strIntName ¿?
     */
    public static async message(topic: string, strUserType: string, strMessage: string, strIntName: string = ""){
        let me = this;
        let data = JSON.stringify({ userType: strUserType, message: strMessage, strMsgType: "standard", strIntName: strIntName });
        await me.publish(topic, data);
    }
    /**
     * Send a simple message via Mercure
     * @param topic Topic of the conversation
     * @param data Data to submit.
     * @returns Response
     */
    public static async publish(topic: string, data: string): Promise<AxiosResponse<any>> {
        let me = this;
        let params = {
            topic,
            data
        };
        var formData = me.getEntityFormData(params);
        let response =  await axios.post('/Home/SystemAPI/Mercure', formData);
        let responseData = me.getResponseData(response);
        return responseData;
    }
}

export default Mercure;