import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { GroupUser, User } from '../../entity/login/LoginEntity';
import { GroupPermissions } from '../../entity/security/SecurityEntity';
import Cookies from '../../helper/Cookies';

/**
 * Realiza el login en la aplicación
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class LoginBL extends BaseBL {
    private static session_data: User = null;
    //Callbacks
    private static onlogin_callback: any = {};

    /**
     * Check event
     * @param event Event name
     */
    private static checkEvent(event: string){
        let me = this;
        me.onlogin_callback[event] == undefined && (me.onlogin_callback[event] = []);
    }

    /**
     * On event
     * @param event Event name
     * @param callback Callback function
     */
    public static on(event: string, callback: CallableFunction) {
        let me = this;
        me.checkEvent(event);
        me.onlogin_callback[event].push(callback);
    }

    /**
     * Call event
     * @param event Event name 
     */
    private static call(event: string){
        let me = this;
        me.checkEvent(event);
        me.onlogin_callback[event].map(callback => callback());
    }

    /**
     * Log in by making a call to the Spin API
     * @param email Email user login
     * @param password Password user login
     * @returns 
     */
    public static async login(email: string, password: string) {
        let me = this;
        let data = new FormData();
        data.append("userLogin", email);
        data.append("userPassword", password);
        data.append('UrlRedirect', '/');
        let response = await axios.post('/Admin/Security/User/Login', data);
        let state = response && [200, 301].includes(response.status) && response.data.indexOf('"root"') > 0;
        // Check user enabled
        state && (state = await me.check(true));
        Cookies.set("spin_session");
        if(state) {
            me.setOnline();
            me.call("login");
        }
        return state;
    }

    /**
     * Close session of the current user
     * @returns State
     */
    public static async logout() {
        await this.setOffline();
        let response = await axios.get('/Home/SecurityAPI/Logout');
        Cookies.delete("spin_session");
        return true;
    }

    /**
     * Verify the current user session
     * @param server Check making server call, otherwise using cookies
     * @returns 
     */
    public static async check(server: boolean = false){
        let me = this;
        let state = Cookies.get("spin_session") == "true";
        if(server) {
            me.session_data = await me.userData();
            state = me.session_data != null;
            me.call("check");
            if(state){
                Cookies.set("spin_session");
            } else {
                Cookies.delete("spin_session");
            }
        }
        return state;
    }

    /**
     * Get the stored data from the last Logon attempt
     */
    public static get SessionData() {
        return this.session_data;
    }

    /**
     * Get user session data from API.
     * @returns 
     */
    public static async userData(){
        let me = this;
        let response = await axios.get('/Home/SecurityAPI/User');
        let data = me.getResponseData(response);
        
        if(data && Object.keys(data) && data.groupUser && data.groupUser.groupPermissions){

            // Parse
            let user = me.parseEntity(data, User);
            let groupUser = me.parseEntity(data.groupUser, GroupUser, (group: GroupUser) => {
                group.groupPermissions = me.parseEntityArray(group.groupPermissions, GroupPermissions)
                return group;
            });

            // Save
            user.groupUser = groupUser;
            me.session_data = user;

            //console.log("--CURRENT_USER ", user);
            return user;
        }

        return null;
    }
    
    /**
     * Call the function of recovering spin password.
     * @param email User email
     * @returns 
     */
     public static async resetPassword(password: string){
        let me = this;
        let data = new FormData();
        data.append("email", me.SessionData.userName);
        data.append("password", password);
        let response = await axios.post('/Home/SecurityAPI/ResetPassword', data);
        let responseData = me.getResponseData(response);
        //console.log("--RESET PASS ", response);
        return response;
    }

    /**
     * Call the function of recovering spin password (from email link).
     * @param email User email
     * @returns 
     */
     public static async changePassword(user: string, newPassword: string){
        let me = this;
        let data = new FormData();
        data.append("user", user);
        data.append("newPassword", newPassword);
        let response = await axios.post('/Home/SecurityAPI/ChangePassword', data);
        let responseData = me.getResponseData(response);
        return response.data.success;
    }

    /**
     * Call the function of recovering spin password.
     * @param email User email
     * @returns 
     */
    public static async remember(email: string){
        let data = new FormData();
        data.append("UserName", email);
        let response = await axios.post('/Home/SecurityAPI/RememberPassword', data);
        return response && response.status == 200 && response.data.result;
    }

    /**
     * Set online
     */
    public static async setOnline(){
        return axios.get("/Home/SecurityAPI/SetOnline");
    }

    /**
     * Set offline
     */
    public static async setOffline(){
        return axios.get("/Home/SecurityAPI/SetOffline");
    }
}

export default LoginBL;
