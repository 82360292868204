import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import ChatTextBoxProp from '../../entity/chat/props/ChatTextBoxProp';
import ChatTextBoxState from '../../entity/chat/states/ChatTextBoxState';
import { _ } from '../../bl/admin/AdminLocaleBL';
import GeneralFunctions from '../../helper/GeneralFunctions';
import TextBox from '../control/TextBox';
import Alert from '../utils/Alert';

/**
 * Chat TextBox Component helper
 * @class ChatTextBox
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatTextBox extends ComponentCommon<ChatTextBoxProp, ChatTextBoxState> {
    // TextBox reference
    private _textBox = null;
    // Custom buttons info
    private static _buttons: any[] = [];

    /**
     * Constructor
     * @param prop Property 
     */
    constructor(prop: ChatTextBoxProp) {
        super(prop);
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare(){
        let me = this;
        me.state = new ChatTextBoxState();
        me._textBox = React.createRef();
    }

    /**
     * componentDidMount
     */
    componentDidMount(){
        let me = this;
    }

    public get Config(): any {
        //Default config
        var data = {
            height: 140,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount code'
            ],
            // Custom plugins: See control/textbox/plugins.ts
            custom_plugins: ["file_uploader", "image_uploader", "suggestions_selector", "expand_textbox"],
            toolbar: 'bold italic link | bullist numlist | file_uploader image_uploader suggestions_selector expand_textbox',
            extended_valid_elements: [
                'ul[class=list]',
                'ol[class=list]',
            ],
            force_br_newlines : true,
            force_p_newlines : false,
            object_resizing : false
        }
        return data;
    }

    public get TextBox(): any {
        let me = this;
        return me._textBox?.current;
    }

    public get Editor(): any {
        let me = this;
        return me._textBox?.current.Editor;
    }

    /**
     * Avoid sending empty messages but with HTML labels
     * @param text Text
     * @returns True if not empty
     */
    private checkEmtpy(text: string){
        var container = document.createElement("div");
        container.innerHTML = text;
        if(container.querySelector("img") != null){
            return false;
        }
        return container.textContent.length <= 1;
    }

    /**
     * Clear tags from html text
     * @param msg Text to clean
     * @returns Formatted text
     */
	clearText(msg: string): string {
		let container = document.createElement("div");
		container.innerHTML = msg;
        // remove <figure> tags
        $(container).find("figure").each(function(){
            $(this).replaceWith($(this).html());
        });
		let ps = $(container).find("p");
		ps.each(function(){
			let content = $(this).html();
			content = content.trim();
			if (content.length < 1) {
				$(this).remove();
            }
		});
		let formatted = container.innerHTML.split('<p>').join('').split('</p>').join('');;
		return formatted;
    }

    /**
     * Show dialog: choose insert image mode
     * @param image 
     */
    showImagePreview(image: string) {
        let me = this;
        me.setState({
            uploadDialog: true,
            uploadedFile: image
        });
	}

    /**
     * Insert a text with HTML format in the ckeditor component
     * @param html Html text
     */
    private insertHtml(html: string){
        let me = this;
        me.TextBox.insertHtml(html);
    }

    /**
     * Allows a parent component to change the value of the ckeditor component
     * @param text Html text
     */
    public setValue(text: string){
        let me = this;
        me.TextBox.setValue(text);
    }

    /**
     * Get the current content of the editor
     * @returns string
     */
    public getValue(){
        let me = this;
        return me.clearText(me.TextBox?.getValue());
    }

    /**
     * Execute spell checker
     */
    public spellCheck(callback = null){
        let me = this;
        me._textBox.current?.spellCheck(callback);
    }

    /**
     * Handle keydown event
     * @param e 
     */
    private handleKeyDown(e: any){
        let me = this;
        var editor = me.Editor;
        var isParentLi = editor.dom.getParent(editor.selection.getNode(), 'li') !== null;
        
        let usrRequest = me.getValue();
        if(e.keyCode == 13 && !e.shiftKey && !isParentLi){
            e.preventDefault();
            me.checkSpellBeforeSend(usrRequest);
        } else if (GeneralFunctions.stripTags(usrRequest.trim()).length > 1500) {
            e.preventDefault();
        }
    }

    /**
     * Check spell before send
     */
    private checkSpellBeforeSend(text){
        let me = this;

        if(me.checkEmtpy(text)){
            me.setValue(""); 
            return;
        }

        me.spellCheck( async data => {
            var isConfirmed = true;
            if(data.length > 0){
                var result = await Alert.confirm(_("key_spelling_error"), _("key_spelling_error_message"));
                isConfirmed = result.isConfirmed;
            }
            if(isConfirmed){
                me.setValue(""); 
                !me.checkEmtpy(text) &&  me.props.onEnter && me.props.onEnter(text);
            }
        });
    }

    /**
     * Handle on enter event
     * @param e 
     */
    private handleOnEnter(e: any){
        let me = this;
        me.props.onEnter && me.props.onEnter(e);
    }

    /**
     * Handle on enter event
     * @param e 
     */
    private handleOnKeyPress(e: any){
        let me = this;
        me.props.onKeyPress && me.props.onKeyPress(e);
    }

    /**
     * Handle on enter event
     * @param e 
     */
    private handleOnChange(e: any){
        let me = this;
        me.props.onChange && me.props.onChange(e);
    }

    render(){
        let me = this;

        return (
            <div id={`textbox_` + me.props.id}>
                <TextBox 
                    id={me.props.id}
                    ref={me._textBox}
                    config={me.Config}
                    onKeyDown={ e => me.handleKeyDown(e) }
                    onEnter={ e => me.handleOnEnter(e)}
                    onKeyPress={ e => me.handleOnKeyPress(e) }
                    onChange={ msg => me.handleOnChange(msg) }
                    spellCheckStart={false}
                    statusBar={false}
                    spellLanguage={me.props.spellLanguage}
                    />
            </div>
        );
    }
}

export default ChatTextBox;