import { BaseTranslate, BaseTranslation } from "../../core/Translate";
import { Place } from "../place/PlaceEntity";

/**
 * It represents a location
 */
export class Location {
    public id: number = 0;
    public idPlace: number = 0;
    public idLocationName: number = 0;
    public idAgency: number = 0;

    public locationName: string;
    public oagCode: string;
    public agencyCode: string;
    public locationType: number = 0;
    public zipCode: string;
    public address1: string;
    public address2: string;
    public phone: string;

    public monday: string;
    public tuesday: string;
    public wednesday: string;
    public thursday: string;
    public friday: string;
    public saturday: string;
    public sunday: string;

    public latitude: number;
    public longitude: number;

    public keywords: string;
    public amountBookCar: number = 0;
    public enabled: boolean;

    // Relations
    public place: Place;
    public agency: Agency;
    public locationNameRef: LocationName;
}

/**
 * It represents a LocationNote
 */
export class LocationNote extends BaseTranslate {
    translationType = LocationNoteTranslate;

    public idLocationNote: number;
    public idLocation: number;
    public note: string = "Default content";
    public typeNote: number;

    public get NoteText(){
		// default Var
		let me = this;
		let note = me.note ? me.note : "";
		return note.length > 60 ? note.slice(0, 30) + "..." : note;
    }
}

/**
 * It represents a LocationNoteTranslate
 */
export class LocationNoteTranslate extends BaseTranslation {
    public idLocationNoteTranslate: number;
    public note: string = "";
}

/**
 * It represents a LocationName
 */
export class LocationName extends BaseTranslate {
    translationType = LocationNameTranslate;

    public name: string = "Default name";
}

/**
 * It represents a LocationNameTranslate
 */
export class LocationNameTranslate extends BaseTranslation {
    public name: string;
}

/**
 * It represents a Agency
 */
export class Agency {
    id: number = 0;
    image: string;
    abbreviation: string;
    accountCode: string;
    code: string;
    enabled: number = 0;
    generateVoucherAfter: number = 0;
    landingPage: number = 0;
    name: string;
    partnerCode: string;
    position: number = 0;
    rating: number = 0;
    requireITVoucher: number = 0;
    requireVoucher: number = 0;
    tagClass: string;
    timeoutBooking: number = 0;
    tourCode: string;
    useLocationAgency: number = 0;
}