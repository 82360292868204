import React from "react";
import { ADMIN_SITES } from "../../constants/data/sites";
import ComponentCommon from "../../core/ComponentCommon";
import SuggestionElementProp from "../../entity/suggestion/props/SuggestionElementProp";
import SuggestionElementState from "../../entity/suggestion/states/SuggestionElementState";

/**
 * Displays an element from the list of suggestions
 * @class SuggestionElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SuggestionElement extends ComponentCommon<SuggestionElementProp, SuggestionElementState> {
    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: SuggestionElementProp){
        super(props);
        this.state = new SuggestionElementState();
    }

    /**
     * Handle click event
     */
    private handleClick(){
        let me = this;
        me.props.onClick && me.props.onClick(me.props.suggestion);
    }
    
    render(){
        let me = this;
        let siteCode = ADMIN_SITES.find( site => site.code == me.props.suggestion.siteCode );
        return (
            <li className={`clearfix p-2 ${me.props.active ? 'active' : ''}`} onClick={ e => me.handleClick() }>
                <div className="mt-0">
                    <b className="badge badge-pill badge-primary mr-2">{me.props.suggestion.language?.label}</b>
                    {me.props.suggestion.SuggestionText}
                    {siteCode &&
                        <div>
                            <b><i className={`fa fa-globe mr-1`}></i></b> {siteCode.name}
                        </div>
                    }
                </div>
            </li>
        );
    }
}

export default SuggestionElement;