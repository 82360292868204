import moment from "moment";
import { _ } from "../../bl/admin/AdminLocaleBL";
import NotifierBL from "../../bl/admin/NotifierBL";
import { BaseTranslate, BaseTranslation } from "../../core/Translate";
import { User } from "../login/LoginEntity";

/**
 * Represents a notify element
 */
export class Notify {
    public id: string = "";
    public state: number = 0;
    public push: boolean = false;
    public createdDate: Date = null;
    public type: string;
    public data: string;
    public identifier: number = 0;
    public idUser: number = 0;
    public user: User = null;

    /**
     * Date text
     */
     public get CreatedDateText(): string {
        let me = this;
        var notifyDate = moment(me.createdDate, "YYYY-MM-DD H:mm:ss");
        var currentDate = moment();
        var format = 'HH:mm:ss';

        if(currentDate.isAfter(notifyDate, "day")){
            format = "DD/MM/YY H:mm:ss";
        }

		if(me.createdDate){
			return notifyDate.format(format);
		}
		return "";
    }

    /**
     * Decode JSON data
     */
    public get Data(): any {
        let me = this;
        try {
            return JSON.parse(me.data);
        }catch(e){
            return {};
        }
    }
}

/**
 * Notification types
 */
 export class Notification extends BaseTranslate {
    public name: string = "";
    public type: string = "";
}

/**
 * Notification types
 */
export class NotificationTranslate extends BaseTranslation {
    public name: string = "";
}

/**
 * User related notifies
 */
 export class UserNotification {
    public id: number = 0;
    public idGroup: number = 0;
    public idNotification: number = 0;
    public enabled: boolean = false;

    public notification: Notification = null;
}

/**
 * Represents the properties Template of a notification
 */
export class NotificationTemplate {
    public constructor(
        {related, page, action, messageKey, titleKey, component, message}: 
        {related?: any, page: any, action: string, messageKey: string, titleKey: string, component: any, message: CallableFunction}
    ) {
        let me = this;
        me.action = action;
        me.titleKey = titleKey;
        me.messageKey = messageKey;
        me.related = related;
        me.component = component;
        me.message = message;
        me.page = page;
    }

    private messageKey: string = "";
    private action: string = "";
    private titleKey: string = "";
    private related: Notify = null;
    private component: any = null;
    private message: CallableFunction = null;
    private page: any = null;
    
    public get Message() {
        return this.message();
    }

    public get Title() {
        return _(this.titleKey);
    }

    public get Action() {
        return this.action + "#id=" + this.related.identifier;
    }

    public get Component() {
        return this.component;
    }

    public get Page() {
        return this.page;
    }

    public get Related(): Notify {
        return this.related;
    }

    public set Related(related: Notify) {
        this.related = related;
    }

    /**
     * Check page hash url
     * It is used to update the current element selected on the page
     * In case you are already the open component and do not pass through mounted component
     */
    public checkPage(){
        let me = this;

        // Find related page
        var pageName = me.page.name;
        var relatedPage = NotifierBL.getSection(pageName);

        // Tiempo de espera mientras carga el <Link>
        setTimeout(() => {
            // Update
            if(relatedPage?.Mounted){
               relatedPage.checkHash();
            }
        }, 300);
    }
}
