import { _ } from "../../../../bl/admin/AdminLocaleBL";
import TextBox from "../../TextBox";
import BasePlugin from "../BasePlugin";

/**
 * Plugin to load and insert files into a textbox
 */
export class FilePlugin extends BasePlugin {
    
    _icon: string = "browse";
    _label: string = "key_attach_file";
    _name: string = "file_uploader";

    onAction(textbox: TextBox) {
        let me = this;
        me._fileUploader.open(files  => {
            if (files.length > 0) {
                let file = files[0]
                textbox.setUploadingState(true);
                me._fileUploader.upload(file, r => {
                    let file_attr = r["files"];
                    for (var x in file_attr) {
                        x = file_attr[x]["url"];
                        let anchorTag = `<a href="${x}" download target="_blank">${file.name}</a>`;
                        textbox.insertHtml(anchorTag);
                    }
                    textbox.setUploadingState(false);
                }, e => {
                    textbox.setUploadingState(false);
                    console.log("Error al cargar archivo!", e);
                });
            }
        });
    }
}