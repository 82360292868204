import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { Notification, UserNotification } from "../../entity/notification/NotificationEntity";
import LoginBL from "../login/LoginBL";

/**
 * Manage the types and configuration of notifications
 * @Class NotificationBL 
 * @author Samael Fierro <sfierro@viajemos.com>
*/
export class NotificationBL extends BaseBL {
	/**
	 * Get notifications types
	 */
	public static async getNotifications(): Promise<Notification[]> {
        let me = this;
        let data = {
            params: {
                id: LoginBL.SessionData.id
            }
        };
        let response = await axios.get('/Home/NotificationAPI/GetNotifications', data);
        let responseData = me.getResponseData(response);
        var notifications = me.parseEntityArray(responseData, Notification);
        return notifications;
	}

	/**
	 * Set chats UserNotify
	 * @param userNotify UserNotify
	 * @returns Updated element
	 */
     public static async getUserNotification(idGroup: number): Promise<UserNotification[]> {
		let me = this;
        let data = {
            params: {
                id: idGroup
            }
        };
        let response = await axios.get('/Home/NotificationAPI/GetUserNotification', data);
        let responseData = me.getResponseData(response);
        let userNotify = me.parseEntityArray(responseData, UserNotification);
		return userNotify;
	}

	/**
	 * Set chats UserNotify
	 * @param userNotify UserNotify
	 * @returns Updated element
	 */
    public static async updateUserNotification(userNotify: UserNotification): Promise<UserNotification> {
		let me = this;
        let formData = me.getEntityFormData(userNotify);
        let response = await axios.post('/Home/NotificationAPI/UpdateUserNotification', formData);
        let responseData = me.getResponseData(response);
        userNotify = me.parseEntity(responseData, UserNotification);
		return userNotify;
	}
}

export default NotificationBL;