import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { PaginatedReponse, PaginatedRequest, PaginatedWithIdRequest, Pagination } from '../../entity/control/ControlEntity';
import { CustomerSiteLandingPage, CustomerSiteLandingPage_Translate } from '../../entity/landing/LandingEntity';
import { Place } from '../../entity/place/PlaceEntity';
import { UserLanguage } from '../../entity/login/LoginEntity';
import Cookies from '../../helper/Cookies';

/**
 * Get and manage LandingPages
 * @class LandingBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LandingBL extends BaseBL {
    /**
     * Gets a listing page of translations
     * @param criteria Criterio de búsqueda
     * @returns 
     */
    public static async getCustomer() {
        let me = this;
        let response = await axios.get('/Home/LandingPageAPI/GetCustomer');
        return response;
    }

    public static async getCustomerSite(currentCustomer: number) {
        let me = this;

        let data = {
            params: {
                id: currentCustomer
            }
        };

        let response = await axios.get('/Home/LandingPageAPI/GetCustomerSite', data)

        return response
    }

    public static async getCustomerSiteLandingPage(paramsData: PaginatedWithIdRequest) {
        let me = this;

        let data = {
            params: paramsData
        }

        let response = await axios.get('/Home/LandingPageAPI/GetCustomerSiteLandingPage', data)
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
     public static async updateLandingItem(itemCustomerSiteLandingPage: CustomerSiteLandingPage): Promise<CustomerSiteLandingPage> {
        let me = this;
        let formData = me.getEntityFormData(itemCustomerSiteLandingPage);
        let response = await axios.post('/Home/LandingPageAPI/UpdateItem', formData);
        let responseData = me.getResponseData(response);
        responseData.pagination = me.parseEntity(responseData.pagination, Pagination)
        // let updated = me.parseEntity(responseData, UserInfo, (userInfo: UserInfo) => {
        //     userInfo.state = me.parseEntity(userInfo.state, RequestState);
        //     return userInfo;
        // });
        return responseData;
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
     public static async updateLandingItemTranslate(itemCustomerSiteLandingPage: CustomerSiteLandingPage_Translate): Promise<CustomerSiteLandingPage_Translate> {
        let me = this;
        let formData = me.getEntityFormData(itemCustomerSiteLandingPage);
        let response = await axios.post('/Home/LandingPageAPI/UpdateItemTranslate', formData);
        let responseData = me.getResponseData(response);
        responseData.pagination = me.parseEntity(responseData.pagination, Pagination)
        // let updated = me.parseEntity(responseData, UserInfo, (userInfo: UserInfo) => {
        //     userInfo.state = me.parseEntity(userInfo.state, RequestState);
        //     return userInfo;
        // });
        return responseData;
    }

    public static async getCarCategories() {
        let me = this;
        let response = await axios.get('/Home/LandingPageAPI/GetAllCategories');

        let responseData = me.getResponseData(response);
        return responseData;
    }

    public static async getCountryPlaceFromPlace(dataRequest: PaginatedRequest) {
        let me = this;

        let data = {
            params: dataRequest
        }

        let response = await axios.get('/Home/LandingPageAPI/GetCountryFromPlace', data)

        let responseData = me.getResponseData(response);
        return responseData;
    }
}
