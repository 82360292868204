import React from 'react';
import PageCommon from "../core/PageCommon";
import SupervisorPageProp from "../entity/supervisor/props/SupervisorPageProp";
import SupervisorPageState from "../entity/supervisor/states/SupervisorPageState";
import { Row, Col, Card, CardBody, Button, Form, Media, IconFa } from '../../cuba/components/utils/reactstrap'
import LoaderMini from '../component/utils/LoaderMini';
import { Empty } from '../component/utils/Empty';
import Config from '../core/component/chat/config/Config';
import ChatRequest from '../core/component/chat/ChatRequest';
import { Chat, ChatPair } from '../entity/chat/ChatEntity';
import ChatBox from '../component/chat/ChatBox';
import ChatElement from '../component/chat/ChatElement';
import ChatBL from '../bl/chat/ChatBL';
import MercureListener from '../helper/MercureListener';
import { _ } from '../bl/admin/AdminLocaleBL';

/**
 * Visualize the chats of the agents in real time
 * @class SupervisorPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SupervisorPage extends PageCommon<SupervisorPageProp, SupervisorPageState> {
    private mainTopic: string = "agentRequest2021";
    private eventSource: EventSource = null;
    private config: Config = null;
    
    /**
     * Constructor
     * @param props Property
     */
    constructor(props: SupervisorPageProp){
        super(props);
        this.state = new SupervisorPageState();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(){
        let me = this;
        me.destroy();
    }

    /**
     * Destroy component, reset vars
     */
    public destroy(){
        let me = this;
        MercureListener.unsubscribe(me.mainTopic);
        me.setState({
        })
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        me.config = new Config();
        await me.config.loadConfiguration();
        
        // Load dispo
        me.loadSupervisorTopics();
        // Start mercure listen
        me.listenMercureEvents();
    }

    /**
     * detect Agent Event
     */
    async listenMercureEvents() {
        let me = this;
        MercureListener.subscribe(me.mainTopic, data => {
            me.loadSupervisorTopics(false);
        });
    }

    /**
     * Take the request for topics available to view
     * @params state Disable loader
     */
    private async loadSupervisorTopics(state: boolean = true) {
        let me = this;
        me.setState({ loadingChatCurrent: state });
        await ChatBL.getSupervisorChats()
            .then(currentChats => {
                me.displayChats(currentChats);
                me.setState({
                    loadingChatCurrent: false
                })
            });
    }

    /**
     * Processes the response of the available chats (assets) and integrates them into the interface
     * Considering if these are already loaded
     * @param chats 
     */
    private displayChats(chats: Array<Chat>){
        let me = this;

        // Recorrer chats recibidos
        chats.forEach( newChat => {
            let exists = false;
            me.state.chatCurrent.forEach( chatPair => {
                exists = exists || (chatPair.entity.chatTopic == newChat.chatTopic);
            });
            if(!exists){
                let chatPair = me.pushChat(newChat);
                me.onChatCurrent(chatPair);
            }
        });
    }

    /**
     * Update listing
     */
    private onCloseChat(chat: Chat){
        let me = this;
        let list = me.state.chatCurrent.map( chatPair => {
            chatPair.entity.chatTopic == chat.chatTopic && (chatPair.visible = false);
            return chatPair;
        });
        me.setState({
            chatCurrent: list
        })
    }

    /**
     * Create chatbox component and chatPair
     * It should only be called when the chatbox is created a single time
     * Ideally, it is to create only once a chatbox and avoid creating several of it
     * To avoid conflicts in the listening of events
     * @param chat Chat
     * @returns chatPair
     */
    private pushChat(chat: Chat) { 
        let me = this;
        let pair = new ChatPair();
        pair.entity = chat;
        pair.component = null;

        // Update last message info: dashboard
        let onMessageHandler = (chatChanged: Chat) => {
            pair.entity = chatChanged;
            me.onChatCurrent(pair);
        }

        let component = <ChatBox readOnly={true} onClose={ chat => me.onCloseChat(chat) } onMessage={ chat => onMessageHandler(chat) } key={chat.chatTopic} chat={chat} />;
        pair.component = component;

        return pair;
    }

    /**
     * Event handler: on chat updated
     * @param {*} chatPair ChatPair
     */
    private onChatCurrent(chatPair: ChatPair) {
        let me = this;
        let exists = false;
        let chatCurrentNL = [];
        // Update chat info, check exists
        me.state.chatCurrent.forEach( c => {
            if(c.entity.chatTopic == chatPair.entity.chatTopic){
                c.entity = chatPair.entity;
                exists = true;
            }
            chatCurrentNL.push(c);
        })
        !exists && chatCurrentNL.push(chatPair);

        // Update user chat history
        me.setState({chatCurrent: chatCurrentNL});
    }

    /**
     * On change selected chat
     * @param chatPair Chat pair
     */
    private changeChatClick(chatPair) {
        let me = this;
        
        // Force update
        me.setState({selectedChat: null}, () => {
            me.setState({selectedChat: chatPair});
        });
    }

    /**
     * Apply sort to the available chat list
     */
    public get AvailableChat(): Array<ChatPair> {
        let me = this;
        // Sort list
        return me.state.chatCurrent.sort( (a, b) => {
            return a.entity.LastMessageDate.getTime() - b.entity.LastMessageDate.getTime();
        }).reverse();
    }

    render(){
        let me = this;
        return (
            <Row>
              <Col md="1"></Col>
              <Col sm="3" className="call-chat-sidebar">
                <Card className="card-chat">
                  <CardBody className="chat-body">
                    <div className="chat-box">
                      <div className="chat-left-aside">
                        <div className="people-list">
                            <LoaderMini visible={me.state.loadingChatCurrent} message={_("key_chat_loading_current")}/>
                            { me.state.chatCurrent.length > 0 ?
                                <>
                                    <ul className="list supervisor-container custom-scrollbar">
                                        { me.AvailableChat.map((chatPair, i) => {
                                            let active = me.state.selectedChat && me.state.selectedChat.entity.chatTopic == chatPair.entity.chatTopic;
                                            if(!chatPair.visible){
                                                return null;
                                            }
                                            return (
                                                <ChatElement key={i} active={active} chat={chatPair.entity} onClick={ () => me.changeChatClick(chatPair) }/>
                                            );
                                        })}
                                    </ul>
                                </>
                            :  
                                <Empty visible={!me.state.loadingChatCurrent} />
                            }
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="7" className="call-chat-body">
                <Card className="card-chat">
                  <CardBody className="p-0">
                    <Row className="chat-box">
                      <Col className="pr-0 chat-right-aside">
                        <Empty visible={!me.state.selectedChat}/>
                        {/* All in real time, it is deactivated by Mercure Limits*/}
                        { me.state.chatCurrent.map( chatPair => {
                            let currentChat = chatPair.entity;
                            let current_topic = me.state.selectedChat && me.state.selectedChat.entity.chatTopic == currentChat.chatTopic;
                            return <div key={currentChat.chatTopic} className={!current_topic ? "d-none" : ""}>{chatPair.component}</div>;
                        })}
                        {/*me.state.selectedChat?.component*/}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="1"></Col>
            </Row>
        );
    }
}

export default SupervisorPage;