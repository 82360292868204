import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import ChatTabsProp from "../../entity/utils/props/ChatTabsProp";
import ChatTabsState from "../../entity/utils/states/ChatTabsState";

export class ChatTabs extends ComponentCommon<ChatTabsProp, ChatTabsState> {
    render() {
        const me = this;
        const currentTopic = me.props.selectedChat && me.props.selectedChat.chatTopic == me.props.currentChat.chatTopic;
        const currentNewNavigationsByChatTopic = me.props.newNavigations?.filter(newNavigation=>
            me.props.selectedChat && me.props.selectedChat.chatTopic == newNavigation.chatTopic);
        const countNewNavigations = (currentNewNavigationsByChatTopic?.length) ? currentNewNavigationsByChatTopic[0].listNewTrackUrls.length : 0;
        return (
        <div key={Math.random()} className={!currentTopic ? "d-none" : ""}>
            <ul className="nav nav-fill nav-tabs px-1" role="tablist">
                <li className="nav-item border" role="presentation">
                    <a className={'nav-link rounded-0 ' + (me.props.visibleNavigationLog ? '' : 'active')} id="tab-chat"
                       data-toggle="tab"
                       href="#tab-chat" role="tab" aria-controls="tab-chat"
                       aria-selected="true"
                       onClick={(event) => me.props.updateVisibleNavigationLog(event.currentTarget.id === "tab-navigation-user-" + me.props.currentChat.chatTopic)}> CHAT </a>
                </li>
                <li key={Math.random()}
                    className={!currentTopic ? "d-none" : ('nav-item border ' + (countNewNavigations ? 'blink' : ''))}
                    role="presentation">
                    <a className={'nav-link rounded-0 ' + (me.props.visibleNavigationLog ? 'active' : '')}
                       id={'tab-navigation-user-' + me.props.currentChat.chatTopic} data-toggle="tab"
                       href={'#tab-navigation-user-' + me.props.currentChat.chatTopic}
                       role="tab" aria-controls={'tab-navigation-user-' + me.props.currentChat.chatTopic}
                       aria-selected="false"
                       onClick={(event) => me.props.updateVisibleNavigationLog(event.currentTarget.id === "tab-navigation-user-" + me.props.currentChat.chatTopic)}>
                        NAVEGACIÓN DEL USUARIO
                        {countNewNavigations ? <span key={Math.random()}
                                                     className={!currentTopic ? "d-none" : "ml-2 badge bg-secondary"}>
                                                        {countNewNavigations}
                                                    </span> : <></>}
                    </a>
                </li>
            </ul>
        </div>);
    }
}