import IState from "../../../core/IState";
import { Locale } from "../LocaleEntity";

export default class LocaleElementState implements IState {
    public locale: Locale = null;
    public expand: boolean = false;
    public editable: boolean = false;
    public busy: boolean = false;
    public width: number = 0;
    public height: number = 0;
}