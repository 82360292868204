
/**
 * It represents the default translation
 */
export class Locale {
    public id: number = null;
    public keyLocale: string = "";
    public label: string = "";
    public enabled: number = null;
    public showInVue: number = null;
    public translates: Array<LocaleTranslate> = [];
    public modified: boolean = false;
}

/**
 * It represents a translation
 */
export class LocaleTranslate {
    public id: number = null;
    public idRelation: number = null;
    public enabled: number = null;
    public label: string = "";
    public language: string = "";
    public modified: boolean = false;
}