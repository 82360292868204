import GeneralFunctions from "../../helper/GeneralFunctions"
import { GroupPermissions } from "../security/SecurityEntity"

/**
 * User login entity
 */
export class User {
    public id : number
    public userName : string
    public password : string
    public changePassword : boolean
    public firstName : string
    public lastName : string
    public emailAlternative : string = "";
    public image : string
    public occupation : string = "";
    public idCustomer : number
    public customer : any
    public idGroup : number
    public city : string
    public country : string
    public isConnect : boolean
    public lastConnect : string
    public phone : string
    public token : any
    public language : any
    public isAdministrator : boolean
    public enabled : boolean
    public pathStructure : any
    public idOwner : number
    public sessions : any;
    public groupUser: GroupUser = new GroupUser();
    public languages: any[] = [];

    /**
     * Get default avatar
     */
    public get Avatar() {
        return GeneralFunctions.getAvatarOrDefault(this.image);
    }

    /**
     * Get full name
     */
    public get FullName(){
        return `${this.firstName} ${this.lastName}`;
    }
}

/**
 * User language item list
 */
export class UserLanguage {
    public id: number = null;
    public name: string = "";
    public label: string = "";
    public code: string = "";
    public state: boolean = false;
}

/**
 * User login GroupUser entity
 */
export class GroupUser {
    public idGroupParent : any = null;
    public name : string;
    public showConfigurationDashboard : number = 0;
    public idCustomer : number = 1;
    public customer : any;
    public children : any;
    public isAdmin: boolean = false;
    public id : number;
    public groupPermissions: Array<GroupPermissions> = [];
}
