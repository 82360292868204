import axios from 'axios';

/**
 * Helper security class
 * @class Security
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Security {
    public static async encodedRequest(url: string, data: any){
        let me = this;
        let body = me.encode(JSON.stringify(data));
        let response = await axios.post(
            url, 
            body, 
            {headers:{'Content-Type':'text/plain'}}
        )
        return response;
    }

    /**
     * Encode string
     * @param text text
     * @returns Encoded string
     */
    public static encode(text: string){
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
        let before = "";
        let last = "";
        let count = 6;
        while(count){
            let randBefore = Math.round((Math.random() * chars.length) - 1);
            let randLast = Math.round((Math.random() * chars.length) - 1);
            before += chars[randBefore];
            last += chars[randLast];
            --count;
        }
        let encoded = btoa(before + text + last);
        return encoded;
    }

    /**
     * Decode string
     * @param text text
     * @returns Decoded string
     */
    public static decode(text: string){
        text = atob(text);
        text = text.slice(6, text.length - 6);
        return text;
    }
    
    /**
     * Generate token
     * @returns Token
     */
    private static rand() {
        return Math.random().toString(36).substr(2);
    };
    
    /**
     * Generate an alphanumeric token
     * @returns Token
     */
    public static genToken() {
        let me = this;
        return me.rand() + me.rand();
    };
}

export default Security;