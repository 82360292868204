import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import ImagesFormProp from '../../entity/images/props/ImagesFormProp';
import ImagesFormState from '../../entity/images/states/ImagesFormState';
import LoaderBackdrop from "../utils/LoaderBackdrop";
import {ImagesBL} from "../../bl/images/ImagesBL";
import {CardBody, Col, FormGroup, Row, CardHeader, Card, IconFa} from "../../../cuba/components/utils/reactstrap";
import {Images} from "../../entity/images/ImagesEntity";
import Files from 'react-files';
import FileUploader from "../../helper/FileUploader";

/**
 * Manage an element of static content
 * @class SchemaElement
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export class ImagesForm extends ComponentCommon<ImagesFormProp, ImagesFormState>{
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    
    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: ImagesFormProp){
        super(props);
        this.state = new ImagesFormState();
        this._auditorLog = React.createRef();
        this._auditorLogConfig = new AuditorConfig();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        const me = this;
        me.setState({
            image: me.props.image,
            action: me.props.action
        });
    }

    /*
    * Convert data URL to Blob
    * @param dataURL Data URL
    * @returns {Blob}
    */
    private dataUrlToBlob(dataURL: string): Blob {
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            const parts = dataURL.split(',');
            const contentType = parts[0].split(':')[1];
            const raw = parts[1];
            return new Blob([raw], {type: contentType});
        }
        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    }

    /*
    * Create image thumbnail.
    * @param imageOriginal Original image
    * @param type Image type
    */
    private createImageThumbnail(imageOriginal: File, type: string="image/png"): Promise<File> {
        const me = this;
        return new Promise(resolve=>{
            const windowUrl = window.URL || window.webkitURL;
            const img = new Image();
            const objectUrl = windowUrl.createObjectURL(imageOriginal);
            img.addEventListener('load', () => {
                windowUrl.revokeObjectURL(objectUrl);
                const canvas = document.createElement("canvas");
                const maxSize = 100;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(img, 0, 0, width, height);
                const dataUrl = canvas.toDataURL(type);
                const blob = me.dataUrlToBlob(dataUrl);
                resolve(new File([blob], imageOriginal.name, { type: type }));
            });
            img.src = objectUrl;
        });
    }

    /**
     * Upload image in the server
     */
    private uploadImageFile(): Promise<any> {
        const me = this;
        const file = me.state.file;
        const upload = new FileUploader(file.name);
        // Upload original Image
        return new Promise(async (resolve, reject) => {
            upload.upload(file, async(responseOriginalFile) => {
                if(responseOriginalFile.result){
                    const image = JSON.parse(JSON.stringify(me.state.image));
                    image.path = responseOriginalFile.file.url;
                    const thumbnailFile = await me.createImageThumbnail(file);
                    upload.upload(thumbnailFile, async(responseThumbnailFile) => {
                        if(responseThumbnailFile.result){
                            image.thumbnailPath = responseThumbnailFile.file.url;
                            await me.updateImage(image);
                            Alert.success();
                            resolve({
                                original: responseOriginalFile,
                                thumbnail: responseThumbnailFile
                            });
                        } else {
                            Alert.error();
                            me.deleteFile();
                            reject();
                        }
                    }, ()=>{
                        Alert.error();
                        me.deleteFile();
                        reject();
                    });
                } else {
                    Alert.error();
                    me.deleteFile();
                    reject();
                }
            }, ()=>{
                Alert.error();
                me.deleteFile();
                reject();
            });
        });
    };

    /**
     * Delete image file in the server
     * @param fileName File name
     */
    private deleteImageFile(fileName: string): Promise<any>{
        const upload = new FileUploader(fileName);
        return new Promise((resolve, reject) => {
            upload.delete(fileName, response => {
                if(response.result) {
                    resolve(response);
                }
                else {
                    Alert.error();
                    reject();
                }
            }, ()=>{
                Alert.error();
                reject();
            });
        });
    }

    /**
     * Update image
     * @returns {Promise<void>}
     */
    public async updateImage(image: Images): Promise<void>{
        const me = this;
        me.setState({busy: true});
        // Save changes...
        image.extension = image.extension.toLowerCase();
        delete image.createdDate;
        delete image.user;
        if(me.state.action === "create" || me.state.action === "update") image = await ImagesBL.updateImage(image);
        else if(me.state.action === "delete") image = await ImagesBL.deleteImage(image);
        if(image.path) Alert.success();
        else {
            Alert.error();
            me.deleteFile();
        }

        me.props.onChange && me.props.onChange(me.state.image);
        me.setState({
            busy: false,
            image: image
        });

        // Update auditor
        me._auditorLog.current?.update();
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        const me = this;
        const result = await Alert.confirm(_("key_save_changes"), _("key_save_changes_message"));
        me.setState({action: "create"});
        if(result.isConfirmed) await me.uploadImageFile();
        me.handleClose();
    }

    /**
     * Delete image in the data base
     */
    private async deleteImage(){
        const me = this;
        me.setState({action: "delete"});
        const result = await Alert.confirm(_("key_image_delete"), _("key_image_delete_message"));
        if(result.isConfirmed) {
            const originalFileName = me.state.image.path.split("/").pop();
            const response = await me.deleteImageFile(originalFileName);
            let isDeletedSuccess = false;
            if(response.result){
                const thumbnailFileName = me.state.image.thumbnailPath.split("/").pop();
                const responseThumbnail = await me.deleteImageFile(thumbnailFileName);
                if(responseThumbnail.result){
                    const image = JSON.parse(JSON.stringify(me.state.image));
                    image.enabled = 0;
                    await me.updateImage(image);
                    Alert.success();
                    isDeletedSuccess = true;
                }
            }
            if(!isDeletedSuccess) Alert.error();
        }
        me.handleClose();
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
    private handleChange(event){
        const me = this;
        const dom = event.target;
        const value = dom.value;
        const prop = dom.name;
        const channelState = me.state.image;
        channelState[prop] = value;
        me.setState({
            image: channelState
        });
    }

    /**
     * Handle on close
     */
    private handleClose(){
        const me = this;
        me.props.onClose && me.props.onClose();
    }

    private deleteFile() {
        const me = this;
        me.setState({
            file: null
        });
        return me.state.file;
    }
    private onFilesChange = (files: File[]) => {
        if(files.length){
            const [file] = files;
            const me = this;
            const extension = file["extension"].toLowerCase();
            const isExtensionValid = extension === "jpeg" || extension === "png";
            const name = file.name.split(".")[0]+`_${new Date().getTime()}`;
            if(!isExtensionValid) Alert.error(_("key_extension_invalid"));
            else {
                const newImage: Images = JSON.parse(JSON.stringify(me.state.image));
                newImage.name = name;
                newImage.extension = file["extension"];
                newImage.sizeByte = file.size;
                me.setState({
                    file: file,
                    image: newImage
                });
            }
        }
    }

    private onFilesError = (error: {code: number, message: string}) => {
        if(error.code === 1) Alert.error(_("key_extension_invalid"));
    }

    render(){
        const me = this;

        /* UPDATE AUDITOR */
        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({module: "Images", identifier: me.state.image?.id});
        let titleSection: string;
        if(me.state.action==="view" || me.state.action === "delete") titleSection = _("key_image_information");
        else if(me.state.action==="create") titleSection = _("key_created");

        return ( 
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-1">{titleSection}</h5>
                                    <h5 className="card-title mb-0">{me.state.image?.name && <span>"{me.state.image.name}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    {me.state.action === "view" || me.state.action === "delete" ?
                                        <button className="btn btn-danger my-2 mx-md-2 float-right m-2" type={"button"}
                                                onClick={() => me.deleteImage()}>
                                            <IconFa icon="trash"/> {_("key_delete")}
                                        </button> :
                                        <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                            <IconFa icon="save"/> {_("key_save")}
                                        </button>
                                    }
                                    <button onClick={() => me.handleClose()} type={"button"}
                                            className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                            <Row>
                                {me.state.action === "view" || me.state.action === "delete" ?
                                    <Col sm="12" md="6">
                                        <a href={me.state.image.path} target="_blank" rel="nofollow noopener noreferrer">
                                            <img src={me.state.image.thumbnailPath} className={"img-thumbnail"}
                                                 alt={me.state.image.name}/>
                                        </a>
                                    </Col>
                                    : <Col sm="12" md="6">
                                        <Files
                                            className='files-dropzone fileContainer'
                                            onChange={me.onFilesChange}
                                            onError={me.onFilesError}
                                            accepts={['.jpeg', '.png']}
                                            multiple={false}
                                            clickable
                                        >
                                            {
                                                me.state.file
                                                    ? <div className='files-gallery'>
                                                        <img className='files-gallery-item img-thumbnail' alt="img"
                                                             src={me.state.file["preview"].url}/>
                                                    </div>
                                                    : <div
                                                        className="d-flex justify-content-center chooseFileButton flex-column align-items-center"
                                                        style={{gap: "10px"}}>
                                                        <IconFa icon="cloud-upload fa-lg"/>
                                                        <div className="d-flex flex-column">
                                                        <span
                                                            className="h6 text-dark">{_("key_drag_and_drop_and_upload_image")}</span>
                                                        </div>
                                                    </div>
                                            }
                                        </Files>
                                    </Col>}
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_name")}</h6>
                                        <input disabled={true} type="text" required={true}
                                               onChange={e => me.handleChange(e)} name="name"
                                               value={me.state.image?.name } className="form-control" />
                                </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_type")}</h6>
                                        <input disabled={true} type="text" required={true} onChange={ e => me.handleChange(e) } name="extension" value={ me.state.image?.extension } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_path")}</h6>
                                        <input disabled={true} type="text" required={true} onChange={ e => me.handleChange(e) } name="path" value={ me.state.image?.path } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_thumbnail_path")}</h6>
                                        <input disabled={true} type="text" required={true} onChange={ e => me.handleChange(e) } name="thumbnailPath" value={ me.state.image?.thumbnailPath } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_size_bytes")}</h6>
                                        <input disabled={true} type="number" required={true} onChange={ e => me.handleChange(e) } name="sizeByte" value={ me.state.image?.sizeByte } className="form-control" />
                                    </FormGroup>
                                </Col>
                                {
                                    me.state.action !== "create" ?
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <h6 className="form-label">{_("key_state")}</h6>
                                            <input disabled={true} type="text" required={true} value={(me.state.image?.enabled)?_("key_enabled"):_("key_disabled")} className="form-control"/>
                                        </FormGroup>
                                    </Col> : <></>
                                }
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_profile")}</h6>
                                        <input disabled={true} type="text" required={true} onChange={ e => me.handleChange(e) } name="name" value={ me.state.image?.user?.FullName } className="form-control" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </form>
                {me.state.action === "view" || me.state.action === "delete" ? <AuditorLogList ref={me._auditorLog} config={me._auditorLogConfig}/> : <></>}
            </>
        );
    }
}
export default ImagesForm;
