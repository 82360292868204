import moment from 'moment';
import defaultAvatar from '../../cuba/assets/images/dashboard/profile.jpg';

/**
 * @Class GeneralFunctions 
*/
export class GeneralFunctions {
	// Events
	private static mouseClickEvents: string[] = ['mousedown', 'click', 'mouseup'];

	// Simulates click
	public static simulateMouseClick(element){
		let me = this;
		me.mouseClickEvents.forEach(mouseEventType =>
			element.dispatchEvent(
				new MouseEvent(mouseEventType, {
					view: window,
					bubbles: true,
					cancelable: true,
					buttons: 1
				})
			)
		);
	}
	  
	/**
	 * Converts UTC data to local time
	 * @param date UTC date
	 * @returns new date
	 */
	public static convertUTCDateToLocalDate(date) {
		var newDate = new Date(date.getTime()+date.getTimezoneOffset()/**60*1000*/);
		var offset = date.getTimezoneOffset() / 60;
		var hours = date.getHours();
		newDate.setHours(hours - offset);
		return newDate;   
	}

	/**
	 * Convert Local Date to UTC
	 * @param date Local date
	 * @returns new date
	 */
	public static convertLocalDateToUTCDate(date: Date = new Date()) {
		var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
		date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
		return new Date(now_utc);
	}
	
  	/**
  	 * define Actual Time
  	 */
	public static defineActualDateTime() {
		// define Actual Date
		let nowDate:Date = this.convertLocalDateToUTCDate();//new Date();
		// define Times
		let nowMonth   = (nowDate.getUTCMonth() < 9)    ? '0'+(nowDate.getUTCMonth()+1): (nowDate.getUTCMonth()+1);
		let nowDay     = (nowDate.getUTCDate() <= 9)    ? '0'+nowDate.getUTCDate(): nowDate.getUTCDate();
		let nowHours   = (nowDate.getUTCHours() <= 9)   ? '0'+nowDate.getUTCHours(): nowDate.getUTCHours();
		let nowMinutes = (nowDate.getUTCMinutes() <= 9) ? '0'+nowDate.getUTCMinutes(): nowDate.getUTCMinutes();
		let nowSeconds = (nowDate.getUTCSeconds() <= 9) ? '0'+nowDate.getUTCSeconds(): nowDate.getUTCSeconds();
		// return Date Time 
		return `${nowDay}/${nowMonth}/${nowDate.getFullYear()} ${nowHours}:${nowMinutes}:${nowSeconds}`; 
	}
	
  	/**
  	 * define Time form Datetime
  	 */
	   public static defineTimeFromDatetime(nowDate: string) {
		let date = this.convertUTCDateToLocalDate(new Date(nowDate));

		let nowHours   = (date.getHours() <= 9)   ? '0'+date.getHours(): date.getHours();
		let nowMinutes = (date.getMinutes() <= 9) ? '0'+date.getMinutes(): date.getMinutes();
		let nowSeconds = (date.getSeconds() <= 9) ? '0'+date.getSeconds(): date.getSeconds();

		// return Date Time 
		return `${nowHours}:${nowMinutes}:${nowSeconds}`; 
  	}	

	public static defaultInputDate(date = null){
		let dateMoment = date !== null ? moment(date) : moment();
		return dateMoment.format("YYYY-MM-DD");
	}

	/**
	 * Get the initials of a name
	 * @param name Full name
	 */
	public static getNameInitials(name: string){
		name = name.toUpperCase();

		// Split names
		let parts = name.split(" ");
		parts = parts.filter( e => { return ![null,"",undefined].includes(e) });

		// Initials
		if(parts.length > 1){
			return parts[0][0] + parts[1][0];
		}

		// Default
		return name.slice(0, 2);
	}

    /**
     * Validate the profile image and returns a default image
     * @param url 
     * @returns Validated image
     */
	public static getAvatarOrDefault(url: string){
        url && (url = url.trim());
        if(["", null, "null", undefined].includes(url)){
            return defaultAvatar;
        }
        return url;
    }

	public static sliceText(text: string, size: number = 100){
		let me = this;
		let dom = document.createElement("div");
		dom.innerHTML = text;
		text = dom.innerText;
		if(text.length > size){
			text = text.slice(0, size) + "...";
		}
		return text;
	}

	public static getBaseUrl(){
		var getUrl = window.location;
		return getUrl .protocol + "//" + getUrl.host;
	}

	/**
	 * Left zero fillment
	 * @param number Number
	 * @param places Places
	 * @param fill Fill character
	 * @returns Formatted string
	 */
	public static zeroPad(number: number, places: number, fill: string = "0") {
		var zero = places - number.toString().length + 1;
		return Array(+(zero > 0 && zero)).join(fill) + number;
	}

	/**
	 * Strip html tags
	 * @param html Html text
	 * @returns Text without html tags
	 */
	public static stripTags(html: string){
		var htmlContainer = document.createElement("div");
		htmlContainer.innerHTML = html;
		return htmlContainer.innerText;
	}

	/**
	 * Generate a random string
	 * @param length length of the string
	 * @returns 
	 */
	public static generateRoken(length: number = 10){
		//edit the token allowed characters
		var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
		var b = [];  
		for (var i=0; i<length; i++) {
			var j = (Math.random() * (a.length-1)).toFixed(0);
			b[i] = a[j];
		}
		return b.join("");
	}

	/**
	 * Validate Reservation Number
	 * @param reservation
	 */
	public static validNumbReservation(reservation: string): boolean {
        const isValidNumbReservation: RegExpMatchArray = reservation.match(/^(MI|MP|ME|VI|VP|VE)/);
		return isValidNumbReservation != null
	}

	/**
	 * Discard message ShowUserForm
	 * @param messages
	 */
	public static restrictChat (messages){

		return messages.filter(i => i.strMessage != "ShowUserForm")
	}

}

export default GeneralFunctions;