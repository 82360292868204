import React from 'react';
import UserBL from '../../bl/user/UserBL';
import LanguageBL from '../../bl/language/LanguageBL';
import ComponentCommon from '../../core/ComponentCommon';
import LanguageScheduleListProp from '../../entity/language/props/LanguageScheduleListProp';
import LanguageScheduleListState from '../../entity/language/states/LanguageScheduleListState';
import UserSchedule from '../../entity/user/UserEntity';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import TimeRange from '../control/TimeRange';
import LoaderMini from '../utils/LoaderMini';
import {LanguageSchedule} from '../../entity/language/LanguageScheduleEntity';

/**
 * Manage schedules assigned to users
 * @class UserScheduleList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LanguageScheduleList extends ComponentCommon<LanguageScheduleListProp, LanguageScheduleListState> {
    /**
     * Constuctor
     * @param prop Property 
     */
    constructor(prop: LanguageScheduleListProp){
        super(prop);
        this.state = new LanguageScheduleListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize the component
     */
    private async prepare(){
        let me = this;

        // Set loader
        me.setState({ busy: true });

        let finalList: Array<LanguageSchedule> = [];
        let languageSchedule: Array<LanguageSchedule> = await LanguageBL.getLanguageSchedule(me.props.language, false);
        for(var x = 0; x <= 6; x++) {
            var createdElement: LanguageSchedule = null;
            languageSchedule.forEach( us => {
                if(us.day == x){
                    createdElement = us;
                }
            });
            if(createdElement){
                finalList.push(createdElement);
            } else {
                createdElement = new LanguageSchedule();
                createdElement.idLanguage = me.props.language.id;
                createdElement.day = x;
                finalList.push(createdElement);
            }
        }

        me.setState({
            busy: false,
            languageSchedule: finalList
        });
    }

    /**
     * Get day label
     * @param day Day
     * @returns Name of the day
     */
    private getDay(day): string {
        let days = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
        ];
        return days[day];
    }

    /**
     * Update schedule array state by day
     * @param schedule schedule item
     */
    private updateState(schedule: LanguageSchedule){
        let me = this;
        let currentSchedule = me.state.languageSchedule;
        currentSchedule.forEach( cs => {
            if(cs.day == schedule.day){
                Object.assign(cs, schedule);
            }
        });
        me.setState({
            languageSchedule: currentSchedule
        });
        me.props.onChange && me.props.onChange();
    }

    /**
     * Handle onChange timepicker event and save element
     * @param time Time text
     * @param schedule Schedule object update
     * @param start True: is start time, False: is end time 
     */
    private async updateSchedule(time, schedule: LanguageSchedule){
        let me = this;

        // Invalid time
        if(!time) return;

        let parts = time.split("-");

        // Set values
        schedule.start = parts[0];
        schedule.end = parts[1];

        let result = await LanguageBL.saveLanguageSchedule(schedule);
        me.updateState(result);
    }

    /**
     * Delete schedule object
     * @param schedule Schedule object update
     */
    private async deleteSchedule(schedule: LanguageSchedule){
        let me = this;
        
        await LanguageBL.deleteLanguageSchedule(schedule);
        schedule.start = "";
        schedule.end = "";
        schedule.id = null;
        me.updateState(schedule);
        Alert.success();
    }

    render() {
        let me = this;
        return (
            <>
                { me.state.busy && <LoaderMini message={_("key_loading")}/>}
                { me.state.languageSchedule.map( (schedule, index) => {
                    return (
                        <TimeRange key={index} onClear={ () => me.deleteSchedule(schedule) } value={`${schedule.start}-${schedule.end}`} label={_("key_" + me.getDay(schedule.day))} onChange={ time => me.updateSchedule(time, schedule) } />
                    );
                })}
            </>
        );
    }
}

export default LanguageScheduleList;