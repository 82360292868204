import { _ } from "../../../../bl/admin/AdminLocaleBL";
import TextBox from "../../TextBox";
import BasePlugin from "../BasePlugin";

/**
 * Plugin to load and insert images into a textbox
 */
export class ImagePlugin extends BasePlugin {

    _icon: string = "image";
    _label: string = "key_image";
    _name: string = "image_uploader";

    onAction(textbox: TextBox): void {
        let me = this;
        me._fileUploader.open(files  => {
            if (files.length > 0) {
                let file = files[0]
                textbox.setUploadingState(true);
                me._fileUploader.upload(file, r => {
                    let file_attr = r["files"];
                    for (var x in file_attr) {
                        x = file_attr[x]["url"];
                        textbox.showImagePreview(x);
                    }
                    textbox.setUploadingState(false);
                }, e => {
                    textbox.setUploadingState(false);
                    console.log("Error al cargar imagen", e);
                });
            }
        });
    }
}