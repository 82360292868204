import React from 'react';
import LandingSchemaBL from '../../bl/Landing/LandingSchemaBL';
import ComponentCommon from "../../core/ComponentCommon";
import { Button, IconFa, Card, CardHeader, CardBody, Col, Row, Form } from '../../../cuba/components/utils/reactstrap';
import { PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import SchemaListProp from '../../entity/landing/props/SchemaListProp';
import SchemaListState from '../../entity/landing/states/SchemaListState';
import Paginator from '../control/Paginator';
import { Empty } from '../utils/Empty';
import { CustomerSiteLandingPageSchema, LandingSchemaPosition } from '../../entity/landing/LandingEntity';
import { _ } from '../../bl/admin/AdminLocaleBL';
import SchemaForm from './SchemaForm';
import LoaderMini from '../utils/LoaderMini';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoaderBackdrop from '../utils/LoaderBackdrop';

/**
 * Manage an element of static content and its translations
 * @class LandingElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SchemaList extends ComponentCommon<SchemaListProp, SchemaListState>{
    private _paginatorRef = null;

    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: SchemaListProp){
        super(props);
        this.state = new SchemaListState();
        this._paginatorRef = React.createRef();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.searchResult();
    }

    /**
     * Search results
     */
    private async searchResult(){
		let me = this;
        me.setState({
            loadingSchema: true
        })

		let data = {
            id: me.props.landing.id,
            criteria: me.state.criteria
		};

        let request = new PaginatedRequest<any>();
        request.data = data;
        request.pagination = me.state.pagination;

		var result = await LandingSchemaBL.getSchemas(request);

        // Setup pagination!
        let pagination = result.pagination;
        
        me.setState({
            pagination: pagination,
            loadingSchema: false,
            schemas: result.data
        }, () => {
            // Update paginator
            me._paginatorRef.current.update();
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Set selected schema
     * @param schema 
     */
    private selectSchema(schema: CustomerSiteLandingPageSchema){
        let me = this;

        me.setState({
            schema: schema
        });
    }

    /**
     * Handle on close form event
     */
    private handleClose(){
        let me = this;
        me.setState({
            schema: null
        })
    }

    /**
     * Sorts schema list
     */
    public get SchemaList(): CustomerSiteLandingPageSchema[] {
        let me = this;
        return me.state.schemas.sort( (s, d) => s.position - d.position)
    }

    /**
     * Save the positions that were changed
     * @param items List of elements
     */
    private async updateChanged(items: CustomerSiteLandingPageSchema[]) {
        let me = this;
        var changed: LandingSchemaPosition[] = [];
        items.map( i => {
            if(i.changed){
                let position = new LandingSchemaPosition();
                position.id = i.id;
                position.position = i.position;
                changed.push(position);
                i.changed = false;
            }
        });
        me.setState({loadingPosition: true});
        let data = await LandingSchemaBL.updateSchemaPosition(changed);
        me.setState({loadingPosition: false});
        me.props.onChange && me.props.onChange();
    }

    /**
     * Handle ondrag event
     * @param result Event results
     */
    private handleOnDragEnd(result) {
        let me = this;
        if (!result.destination) return;
    
        let items = me.SchemaList;
        var source = null;
        var destination = null;

        items.forEach( i => {
            if(i.position == result.source.index && !source) {
                source = i;
            }
            if(i.position == result.destination.index && !destination) {
                destination = i;
            }
        });

        // Change position
        source.setPosition(destination.position); 
        
        var count = 0;
        items.forEach( i => {
            if(i.id == source.id){
                return;
            }
            ++count;
            if(count == source.position) {
                ++count;
            }
            i.setPosition(count);
        })

        
        me.setState({
            schemas: items
        });
        me.updateChanged(items);
    }

    /**
     * Handle onchange events
     * @param e Event data
     */
    private handleChange(e){
        let me = this;
        me.props.onChange && me.props.onChange(e);
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleSubmit(e) {
        let me = this;
        e.preventDefault();
        me.searchResult();
    }

    render(){
        let me = this;
        return ( 
            <Card>
                <CardHeader className="pb-4">
                    <h4>{_("key_related_schemes")}</h4>
                </CardHeader>
                <CardBody className="pt-4">
                    <LoaderBackdrop visible={me.state.loadingPosition} message="Actualizando posiciones"/>
                    {!me.state.schema ?
                    <>
                        <Form onSubmit={ e => me.handleSubmit(e) }>
                            <Row>
                                <Col sm="12" md="8">
                                    <div className="form-group mb-3">
                                        <input 
                                            onChange={ e => me.setState({criteria: e.target.value}) } 
                                            value={ me.state.criteria } 
                                            className="form-control" 
                                            type="text" 
                                            placeholder={_("key_search_criteria")}
                                            />
                                    </div>
                                </Col>
                                <Col sm="12" md="4">
                                    <button className="btn btn-info mt-3 mt-md-0 float-right" type="submit">
                                        <IconFa icon="search"/> {_("key_filter_results")}
                                    </button>
                                </Col>
                            </Row>
                        </Form>
                        <table className="table table-sm tableDsn">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{_("key_place")}</th>
                                    <th>{_("key_question")}</th>
                                    <th>{_("key_answer")}</th>
                                    <th>{_("key_position")}</th>
                                    <th>{_("key_actions")}</th>
                                </tr>
                            </thead>
                                {me.state.loadingSchema && 
                                    <tbody>
                                        <tr>
                                            <td colSpan={6}>
                                                <LoaderMini message={_("key_search_loading_results")}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                                {!me.state.loadingSchema && me.state.schemas.length > 0 ?
                                    <DragDropContext onDragEnd={ e => me.handleOnDragEnd(e) }>
                                        <Droppable droppableId="characters">
                                            {(provided) => (
                                            <tbody className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                                {me.SchemaList.map((schema) => {
                                                return (
                                                    <Draggable key={schema.id} draggableId={String(schema.id)} index={schema.position}>
                                                        {(provided) => (
                                                            <tr key={schema.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <td>
                                                                    <IconFa icon="bars"/>
                                                                </td>
                                                                <td>{schema.place.name}</td>
                                                                <td>{schema.QuestionText}</td>
                                                                <td>{schema.AnswerText}</td>
                                                                <td>{schema.position}</td>
                                                                <td>
                                                                    <Button className="p-x-md-0" onClick={ e =>  me.selectSchema(schema)} size="sm">
                                                                        <IconFa icon="pencil"/> {_("key_edit")}
                                                                    </Button>
                                                                </td>
                                                            </tr> 
                                                        )}
                                                    </Draggable>
                                                );
                                                })}
                                                {provided.placeholder}
                                            </tbody >
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                :  
                                    <tbody>
                                        <tr>
                                            <td colSpan={6}>
                                                <Empty visible={!me.state.loadingSchema}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                        </table>                            
                        <Paginator 
                            visible={!me.state.loadingSchema && me.state.schemas.length > 0} 
                            ref={ me._paginatorRef } 
                            pagination={me.state.pagination} 
                            onChange={ pagination => me.passPage(pagination) }/>
                    </>
                    :
                        <SchemaForm language={me.props.language} schema={me.state.schema} onClose={ c => me.handleClose() } onChange={ e => me.handleChange(e) }/>
                    }
                </CardBody>
            </Card>
        );
    }
}
export default SchemaList;