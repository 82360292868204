
import Config from "../core/component/chat/config/Config";
import Security from "./Security";

/**
 * Make requests to run command for Miles Symfony
 * @class System
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class System {
    /**
     * Flush redis caché (Symfony)
     * @returns Command results
     */
    public static async redisFlushById(key: string, id: number){
        let config = Config.configuration;
        await config.loadConfiguration();
        let api = "apiChat/redisFlush"
        
        let data = {
            key: key, 
            id: id
        };
        // Encoded request
        let response = await Security.encodedRequest(config.baseUrl + api, data);
        
        return response.data;
    }
}

export default System;