/**
 * Represents a day of schedule assigned to a user
 */
export class LanguageSchedule {
    public id: number = null;
    public idLanguage: number = null;
    public day: number = null;
    public start: string = "";
    public end: string = "";
}

export class LanguageScheduleArray {
    public idLanguage: number = null;
    public languageSchedule: LanguageSchedule[] = []
}