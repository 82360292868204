import moment from "moment";
import ChatBox from "../../component/chat/ChatBox";
import GeneralFunctions from "../../helper/GeneralFunctions";
import { Pair } from "../../helper/Pair";
import { UserLanguage } from "../login/LoginEntity";
import { RequestState, RequestTrace } from "../request/RequestEntity";
import {_} from "../../bl/admin/AdminLocaleBL";

/**
 * It is used to bring a better driving control of chat components and successive messages updates
 * Avoiding creating more components and conserving chat references.
 */
export class ChatPair extends Pair<Chat, any> { }

/**
 * Agent entity
 */
export class Agent {
	public userName: string = "";
	public firstName: string = "";
	public lastName: string = "";
	public image: string = "";
}

/**
 * ChatMessage entity
 */
export class ChatMessage {}

/**
 * User request info
 */
export class UserInfo {
	public chatTopic: string = "";
	createdDate: any = null;
	id: number = 0;
	userName: string = "";
	userPhone: string = "";
	userEmail: string = "";
	userRequest: string = "";
	profile: string = "";
	platform: string = "";
	siteCode: string = "";

	public trace: Array<RequestTrace> = [];
	public idRequestState: number = null;
	public state: RequestState = null;

	public get CreatedDateText(){
		let me = this;
		if(me.createdDate){
			return moment(me.createdDate, "YYYY-MM-DD H:mm:ss").format('DD/MM/YYYY HH:mm:ss');
		}
		return "";
	}

	/**
	 * define Last Message 
	 */
	 public get RequestText() {
		// default Var
		let me = this;
		let message = me.userRequest ? me.userRequest : "";
		return message.length > 60 ? message.slice(0, 60) + "..." : message;
	}

	/**
	 * define Last Message 
	 */
	 public get PlatformText() {
		// default Var
		let me = this;
		return me.platform ? me.platform : "web";
	}
	
	/**
	 * Get chat topic
	 */
	public get ChatTopic() {
		return this.chatTopic.split("_").pop();
	}
}

/**
 * Chat disposition
 */
 export class ChatDisposition {
	public id: number = null;
	public code: string = "";
	public name: string = "";
	public state: boolean = false;
}

/**
 * Represents a suggestion element
 */
export class ChatSuggestion {
	public id: number = null;
	public suggestion: string = "";
	public idLanguage: number = null;
	public language: UserLanguage = new UserLanguage();
	public siteCode: string;

	/**
	 * Returns a text without HTML labels
	 */
	public get SuggestionText() {
		let me = this;
		return GeneralFunctions.sliceText(me.suggestion)
	}
}

/**
 * Chat entity and options
 */
export class Chat {
	public id: number = 0;
	public chatAgent: string = null;
	public agents: Array<Agent> = []; // Provisional
	public agent: Agent = null; // Provisional
    public createdDate: any = null;
    public chatTopic: string = "";
    public language: string = "";
    public messages: Array<any> = [];
	public readed: boolean = true;
	public info: Array<UserInfo> = [];
	public transfer: Array<ChatTransferLog> = [];
	public disposition: number = null;
	public realAgent: number = null;
	public platform: string = "";
	public profile: string = "";
	public idRate: number = 0;
	public rate: ChatRate = null;
	public session: ChatSession = null;
	public siteCode: string = "";
    public dateAgentConnect: Date = null;


	/**
	 * define Last Message 
	 */
	 public get LastMessage() {
		// default Var
		let me = this;
		let lstMessage = null;
		
		// validate Object Length
		if (me.messages.length > 0) {
			lstMessage = me.messages[(me.messages.length - 1)];
		}
		return lstMessage;
	}

	/**
	 * define Last Message 
	 */
	 public get FirstMessage() {
		// default Var
		let me = this;
		let lstMessage = null;
		
		// validate Object Length
		if (me.messages.length > 0) {
			lstMessage = me.messages[0];
		}
		return lstMessage;
	}

	/**
	 * define Last Message 
	 */
	public get LastUserWaitingMessage() {
		// default Var
		let me = this;
		let lstMessage = null;
		
		// validate Object Length
		if (me.messages.length > 0) {
			me.messages.forEach( (message, i) => {
				if(message.usrMessage == "chatbot") {
					lstMessage = me.messages[i - 1];
				}
			})
		}
		return lstMessage;
	}

	/**
	 * define Last Message 
	 */
	 public get LastMessageText() {
		// default Var
		let me = this;
		let message = me.LastMessage;
		let lstMessage = "";
		
		// validate Object Length
		if (message) {
			lstMessage = message['strMessage'];

			// Remove html tags
			let container = document.createElement("div");
			container.innerHTML = lstMessage;
			lstMessage = container.innerText;
		}

		if (lstMessage == "standard_form_contact_chat" ) {
			lstMessage =  _("key_form_contact_sent")
		}
		
		return lstMessage.length > 20 ? lstMessage.slice(0, 20) + "..." : lstMessage;
	}


	/**
	 * define Last Message Date text
	 */
	 public get LastMessageDateText() {
		// default Var
		let me = this;
		let date = "";
		let dateTime = me.LastMessageDate; 
		dateTime && (date = moment(dateTime).format('DD/MM/YYYY H:mm:ss'));
		return date;
	}

	/**
	 * define First Message Date text
	 */
	 public get FirstMessageDateText() {
		// default Var
		let me = this;
		let date = "";
		let dateTime = me.FirstMessageDate; 
		dateTime && (date = moment(dateTime).format('DD/MM/YYYY H:mm:ss'));
		return date;
	}

	/**
	 * define Last Message as DatTime
	 */
	public get LastMessageDate() {
		// default Var
		let me = this;
		let message = me.LastMessage;
		let date = "";
		let dateTime = null;
		
		// validate Object Length
		message && (date = message['datMessage']);
		if(date) {
			let currentUTCDate = moment(date, "DD/MM/YYYY H:mm:ss").toDate();
			dateTime = GeneralFunctions.convertUTCDateToLocalDate(currentUTCDate);
		}
		return dateTime;
	}

	/**
	 * define Last Message as DatTime
	 */
	 public get FirstMessageDate() {
		// default Var
		let me = this;
		let message = me.FirstMessage;
		let date = "";
		let dateTime = null;
		
		// validate Object Length
		message && (date = message['datMessage']);
		if(date) {
			let currentUTCDate = moment(date, "DD/MM/YYYY H:mm:ss").toDate();
			dateTime = GeneralFunctions.convertUTCDateToLocalDate(currentUTCDate);
		}
		return dateTime;
	}
	
	/**
	 * define Last Message as DatTime
	 */
	public get LastUserWaitingMessageDate() {
		// default Var
		let me = this;
		let message = me.LastUserWaitingMessage;
		let date = "";
		let dateTime = null;
		
		// validate Object Length
		message && (date = message['datMessage']);
		if(date) {
			let currentUTCDate = moment(date, "DD/MM/YYYY H:mm:ss").toDate();
			dateTime = GeneralFunctions.convertUTCDateToLocalDate(currentUTCDate);
		}
		return dateTime;
	}

	/**
	 * define Last Message as DatTime
	 */
	public get CreatedDateText() {
		// default Var
		let me = this;
		let date = "";
		let dateTime = me.createdDate; 
		dateTime && (date = moment(dateTime).format('DD/MM/YYYY H:mm:ss'));
		return date;
	}

	/**
	 * Check if the chat is closed
	 */
	public get IsClosed(): boolean {
		let me = this;
		return me.LastMessageText.includes("CloseSession");
	}

	/**
	 * Get online status (user)
	 */
	public get Online(){
		let me = this;
		let lastMessageDate = me.LastMessageDate;
		let minimumDate = moment().subtract(1, 'hours')
		return minimumDate < lastMessageDate && !me.IsClosed;
	}

	/**
	 * Count new messages
	 */
	public get NewCount(){
		let me = this;
		let unreadCount = 0;
        //let lastMessage = me.messages[me.messages.length - 1];
		if(me.messages?.length > 0) {
			me.messages.forEach( m => {
				m["usrMessage"] === "customer" ? (++unreadCount) : (unreadCount = 0);
			});
		}
        return unreadCount;
	}

	/**
	 * Get chat topic
	 */
	public get ChatTopic() {
		return this.chatTopic.split("_").pop();
	}

	/**
	 * Get chat topic
	 */
	public get ChatTopicRaw() {
		return this.chatTopic;
	}
}

/**
 * Session info associated with the chat
 */
export class ChatSession {
	public device: string = "";
	public browser: string = "";
	public location: string = "";
	public country: string = "";
	public domain: string = "";
	public os: string = "";
}

/**
 * Rating associated with the chat
 */
export class ChatRate {
	public id: number = 0;
	public rate: number = 0;
	public comment: string = "";
}

/**
 * Chat transfer info
 */
export class ChatTransferLog {
	public id: string = "";
	public createdDate: Date = null;
	public chatTopic: string = "";
	// Source
	public sourceUserName: string = "";
	public sourceName: string = "";
	// Destination
	public destinationUserName: string = "";
	public destinationName: string = "";
	public isTransferAccepted: boolean = true;

	/**
	 * define Last Message as DatTime
	 */
	 public get CreatedDateDate() {
		// default Var
		let date = this.createdDate;
		let dateTime = null;
		
		if(date) {
			let currentUTCDate = moment(date).toDate();
			dateTime = currentUTCDate;
		}
		return dateTime;
	}

	/**
	 * define Last Message as DatTime
	 */
	 public get CreatedDateText() {
		// default Var
		return moment(this.CreatedDateDate).format("DD/MM/YYYY H:mm:ss")
	}
}

/**
 * MBTemplate
 */
export class MBTemplate {
	name: string;
	language: string;
	status: string;
	id: string;
	category: string;
	createdAt: string;
	updatedAt: string;
	components: MBTemplateComponent[] = [];
}

/**
 * MBTemplate Component
 */
export class MBTemplateComponent {
	type: string;
	text: string;
}
