import React from "react";
import { LocationNoteBL } from "../../bl/location/LocationNoteBL";
import ComponentCommon from "../../core/ComponentCommon";
import LocationNoteListProp from "../../entity/location/props/LocationNoteListProp";
import LocationNoteListState from "../../entity/location/states/LocationNoteListState";
import { Row, Col, Button, FormGroup, IconFa } from '../../../cuba/components/utils/reactstrap'
import LocationNoteForm from "./LocationNoteForm";
import { LocationNote } from "../../entity/location/LocationEntity";
import Alert from "../utils/Alert";
import { LOCATION_NOTE_TYPES } from "../../constants/data/location";
import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * Performs updated and saved locationNote data operations
 * @class LocationNoteList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationNoteList extends ComponentCommon<LocationNoteListProp, LocationNoteListState> {

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props) {
        super(props);
        this.state = new LocationNoteListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare(){
        let me = this;
        me.getLocationNotes();
    }

    /**
     * Load related Location Notes
     */
    private async getLocationNotes(){
        let me = this;
        me.setState({busy: true});
        var locationNotes = await LocationNoteBL.getLocationNotes(me.props.location.id);
        
        me.setState({
            busy: false,
            locationNotes: locationNotes
        });
    }

    /**
     * Opens the editor for locationnote
     * @param locationNote LocationNote element
     */
    private selectNote(locationNote: LocationNote){
        let me = this;
        me.setState({
            selectedLocationNote: null,
        }, () => {
            me.setState({
                selectedLocationNote: locationNote
            });
        });
    }

    /**
     * Verify if there is the type of note, if it does not exist it is created
     */
    private async createNote(){
        let me = this;
        var noteTypeLabel = LOCATION_NOTE_TYPES[me.state.noteType];

        var result = await Alert.confirm(_("key_locationnote_create"), _("key_locationnote_create_message") + ` (${noteTypeLabel})`);
        if(result.isConfirmed){
            var locationNote = new LocationNote();
            locationNote.typeNote = me.state.noteType;
            locationNote.idLocation = me.props.location.id;
            var created = await LocationNoteBL.createLocationNote(locationNote);
            if(created == 1){
                Alert.error(_("key_locationnote_exists") + ` "${noteTypeLabel}"`)
            } else {
                Alert.success(_("key_locationnote_created", {type: noteTypeLabel}))
            }
            me.getLocationNotes();
        }
    }

    /**
     * Handle onclose editor
     */
    private handleClose(){
        let me = this;
        me.setState({
            selectedLocationNote: null
        });
    }

    /**
     * Get current data
     * @returns LocationNote[] data
     */
    private getValues(): LocationNote[] {
        let me = this;
        return me.state.locationNotes;
    }

    // Handle LocationNote changes
    private handleChange(){
        let me = this;
        me.props.onChange && me.props.onChange();
    }

    render () {
        let me = this;

        return (
            <div>
                <Row>
                    <Col sm="4" md="4">
                        <b>{_("key_locationnote_type_select")}:</b>
                    </Col>
                    <Col sm="4" md="4">
                        <FormGroup>
                            <select required={ true } onChange={ e => me.setState({noteType: parseInt(e.target.value) }) } name="locationType" value={ me.state.noteType } className="form-control">
                                {LOCATION_NOTE_TYPES.map( (noteType, i) => {
                                    return <option key={ i } value={ i }>{noteType}</option>
                                })}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm="4" md="4">
                        <Button color="success"
                                onClick={ e => me.createNote() }
                                className="px-2">
                            <IconFa icon="plus"/> {_("key_locationnote_create")}
                        </Button>
                    </Col>
                </Row>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{_("key_content")}</th>
                            <th>{_("key_type")}</th>
                            <th>{_("key_actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {me.state.locationNotes.map( note => 
                            <tr key={note.id}>
                                <td>{note.NoteText}</td>
                                <td>{LOCATION_NOTE_TYPES[note.typeNote]}</td>
                                <td>
                                    {note.id !== me.state.selectedLocationNote?.id &&
                                        <Button onClick={ e => me.selectNote(note) }
                                                className="px-2">
                                            <IconFa icon="pencil"/> {_("key_edit")}
                                        </Button>
                                    }
                                </td>
                            </tr>
                        )}
                        {me.state.busy &&
                            <tr>
                                <td colSpan={3}>{_("key_search_loading_results")}</td>
                            </tr>
                        }
                        {!me.state.busy && me.state.locationNotes.length < 1 &&
                            <tr>
                                <td colSpan={3}>{_("key_locationnote_empty")}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                { me.state.selectedLocationNote && 
                <>
                    <hr/>
                    <LocationNoteForm onChange={ e => me.handleChange() } onClose={ e => me.handleClose() } locationNote={me.state.selectedLocationNote}/>
                </>}
            </div>
        );
    }
}

export default LocationNoteList;