import axios from "axios";
import BaseBL from "../../core/BaseBL";
import {NavigationLogEntity} from "../../entity/navigation/NavigationLogEntity";

/**
 * Performs Navigation Operations
 * @Class NavigationBL
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export class NavigationLogBL extends BaseBL {

    /**
     * Load Navigations
     * @param chatTopic Filter by chatTopic
     * @returns List of navigations available
     */
    public static async getNavigationLog(chatTopic: string): Promise<NavigationLogEntity[]> {
        let me = this;
        let data = {
            params: {
                Id: chatTopic
            }
        };
        let response = await axios.get('/Home/MongoAPI/ListNavigationLog', data);
        let responseData = me.getResponseData(response);
        return me.parseEntityArray(responseData, NavigationLogEntity, (chat: NavigationLogEntity) => chat);
    }

    /**
     * Massive Update Navigation Log
     * @param {NavigationLogEntity[]} listNavigationLog List Navigation Log to Update
     * @returns Navigation Log Updated
     */
    public static async massiveUpdateNavigationLog(listNavigationLog: NavigationLogEntity[]): Promise<NavigationLogEntity[]> {
        let me = this;
        listNavigationLog.forEach((navigationLog)=>{
            navigationLog.updatedDate = new Date().toISOString();
            delete navigationLog.createdDate;
            delete navigationLog.isUpdated;
        });
        let response = await axios.put('/Home/MongoAPI/MassiveUpdateNavigationLog', listNavigationLog);
        let responseData = me.getResponseData(response);
        return me.parseEntityArray(responseData, NavigationLogEntity, (nL: NavigationLogEntity) => nL);
    }
}

export default NavigationLogBL;