import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import { Row, Col, IconFa, AlertBar, Card, CardBody } from '../../../cuba/components/utils/reactstrap'
import { _ } from "../../bl/admin/AdminLocaleBL";
import PermissionListProp from "../../entity/security/props/PermissionListProp";
import PermissionListState from "../../entity/security/states/PermissionListState";
import GroupPermissionBL from "../../bl/security/GroupPermissionBL";
import { LAYOUT } from "../../constants/layout";
import { GroupPermissions } from "../../entity/security/SecurityEntity";
import LoaderMini from "../utils/LoaderMini";
import Switch from "../control/Switch";

/**
 * Performs updated and saved PermissionList data operations
 * @class PermissionList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class PermissionList extends ComponentCommon<PermissionListProp, PermissionListState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props: PermissionListProp) {
        super(props);
        this.state = new PermissionListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare(){
        let me = this;
        me.getPermissions();
    }

    /**
     * Get related permissions
     */
    private async getPermissions(){
        let me = this;
        me.setState({busy:true});
        let permissions = await GroupPermissionBL.getPermissions(me.props.group.id);
        me.setState({
            permissions: permissions,
            busy: false
        })
    }

    /**
     * Check user roles
     * @param role Role identifier
     * @returns TRUE: Exists
     */
    private getPermission(role: string): GroupPermissions {
        let me = this;
        var permission = null;
        me.state.permissions.map( p => {
            if(role == p.module) {
                permission = p;
            }
        });
        return permission;
    }

    /**
     * Handle permission changes
     * @param checked Check status
     * @param role Role
     */
    private handleChange(checked: boolean, role: string){
        let me = this;
        me.props.onChange && me.props.onChange();

        var gp = me.getPermission(role);
        let updatedList = me.state.permissions;

        if(gp == null){
            gp = new GroupPermissions();
            gp.module = role;
            gp.edit = true;
            gp.show = true;
            gp.idGroup = me.props.group.id;
            updatedList.push(gp);
        } else {
            updatedList = updatedList.filter(p => p.id !== gp.id);
        }
        me.setState({
            permissions: updatedList
        });
    }

    /**
     * Save changes
     */
    public async save(){
        let me = this;
        var data = me.state.permissions.map( p => {
            return p.module
        });
        
        // Remove duplicates ...
        data = [...new Set(data)];
        await GroupPermissionBL.updatePermissions(me.props.group, data);
    }

    render () {
        let me = this;

        return (
            <Card>
                <AlertBar className="mb-0" header={true}> {_("key_related_permissions")}</AlertBar>
                <LoaderMini visible={me.state.busy} message={_("key_loading")} />
                {!me.state.busy && LAYOUT.map( (l, i) => 
                    <React.Fragment key={i}>
                        {l.groups.map( (g, i) => 
                            <React.Fragment key={i}>
                                <div className="alert alert-light"> 
                                    {_(l.menutitle)} <IconFa icon="arrow-right ml-2"/> {_(g.title)}
                                </div>
                                <CardBody>
                                    <Row>
                                        {g.getPermissions().map( (role, i) =>
                                            <Col sm="12" md="6" key={i}>
                                                <Switch 
                                                    size="sm" 
                                                    title={_(role.title)} 
                                                    onChange={ e => me.handleChange(e.target.checked, role.role) } 
                                                    checked={me.getPermission(role.role) !== null} />
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Card>
        );
    }
}

export default PermissionList;