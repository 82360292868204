import { ChatSuggestion } from '../../../entity/chat/ChatEntity';
import IState from '../../../core/IState';
import { UserLanguage } from '../../login/LoginEntity';

export class ChatSuggestionsState implements IState {
    public visible: boolean = false;
    public suggestions: Array<ChatSuggestion> = [];
    public criteria: string = "";
    public busy: boolean = false;
    public language: UserLanguage = null;
    public languages: UserLanguage[] = []; 
}

export default ChatSuggestionsState;