import IState from '../../../core/IState';
import { Chat, ChatPair } from '../ChatEntity';
import {INewNavigation} from "../../navigation/states/NavigationLogState";

export class ChatPageState implements IState {
    public menuToggle: boolean = false;
    public activeTab: string = "1";
    public busy: boolean = false;
    public chatAvailable: Array<Chat> = [];
    public chatTransfer: Array<Chat> = [];
    public chatCurrent: Array<ChatPair> = [];
    public selectedChat: Chat = null;
    public loadingChatCurrent: boolean = false;
    public loadingChatAvailable: boolean = false;
    public filterCurrenChatText: string = "";

    public visible: boolean = true;
    public searchKeyword: string = "";
    public messageInput: string = "";
    public showEmojiPicker: boolean = false;
    public showChatAvailable: boolean = true;
    public chatActiveAvailable: boolean = true;
    public chatTransferAvailable: boolean = true;
    public newNavigations: INewNavigation[] = [];
    public visibleNavigationLog: boolean = false;

}

export default ChatPageState;