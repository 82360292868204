import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Language } from "../LanguageEntity";

export default class LanguagePageState implements IState {
    public pagination: Pagination = new Pagination();
    public loadingLanguages: boolean = false;
    public languages: Language[] = [];
    public criteria: string = "";
    public language: Language;
}
