import IState from '../../../core/IState';
import { ChatPair } from '../../chat/ChatEntity';
import { CheckListElement, Pagination } from '../../control/ControlEntity';
import { User, UserLanguage } from '../../login/LoginEntity';
import {INewNavigation} from "../../navigation/states/NavigationLogState";

export class HistoryPageState implements IState {
    public defaultState?: boolean = false;
    public chatFound: Array<ChatPair> = [];
    public selectedChat: ChatPair = null;
    public loadingChatCurrent: boolean = false;
    public loadingChatResults: boolean = false;
    public languages: Array<UserLanguage> = [];
    public agents: CheckListElement[] = [];
    public selectedAgents: CheckListElement[] = [];

    // Pagination
    public pagination: Pagination = new Pagination(); 

    public platform: string = "";
    public criteria: string = "";
    public siteCode: string = "";
    public rate: number = 0;
    public dateStart: Date = new Date();
    public hourStart: string = "00:00";
    public dateEnd: Date = new Date();
    public hourEnd: string = "23:00";
    public language: Array<string> = [];
    public user: string = "";
    public showFilters: boolean = false;
    public visibleNavigationLog: boolean = false;
    public newNavigations: INewNavigation[] = [];
}

export default HistoryPageState;