import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { CustomerSiteLandingPageSchema } from "../LandingEntity";

export default class SchemaListState implements IState {
    // Pagination
    public pagination: Pagination = new Pagination(); 

    public schema: CustomerSiteLandingPageSchema = null;
    public schemas: CustomerSiteLandingPageSchema[] = [];
    public loadingSchema: boolean = false;
    public loadingPosition: boolean = false;
    public criteria: string = "";
}