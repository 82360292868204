import React from 'react';
import { Row, Col, Card, CardBody, Button, Form, Media, IconFa } from '../../cuba/components/utils/reactstrap'
import Config from '../core/component/chat/config/Config';
import { ChatPair } from '../entity/chat/ChatEntity';
import HistoryPageProp from '../entity/history/props/HistoryPageProp';
import HistoryPageState from '../entity/history/states/HistoryPageState';
import ChatBox from '../component/chat/ChatBox';
import LoaderMini from '../component/utils/LoaderMini';
import PageCommon from '../core/PageCommon';
import TimePicker from '../component/control/TimePicker';
import { CheckListElement, PaginatedRequest, Pagination } from '../entity/control/ControlEntity';
import Paginator from '../component/control/Paginator';
import ChatElement from '../component/chat/ChatElement';
import { LocaleBL } from '../bl/locale/LocaleBL';
import { UserLanguage } from '../entity/login/LoginEntity';
import UserBL from '../bl/user/UserBL';
import { Empty } from '../component/utils/Empty';
import ChatBL from '../bl/chat/ChatBL';
import { REQUEST_PLATFORM } from '../constants/data/request';
import AdminLocaleBL, { _ } from '../bl/admin/AdminLocaleBL';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Rate from '../component/control/Rate';
import CheckList from '../component/control/CheckList';
import { ADMIN_SITES } from '../constants/data/sites';
import LoaderSpinner from '../component/utils/LoaderSpinner';
import NavigationBox from "../component/navigation/NavigationBox";
import {ChatContentTabs} from "../component/utils/ChatContentTabs";
import {ChatTabs} from "../component/utils/ChatTabs";
import {IUpdateVisibleNavigationLog} from "../entity/utils/props/ChatTabsProp";
import {INewNavigation} from "../entity/navigation/states/NavigationLogState";
import {IUpdateNewNavigation, updateNewNavigation} from "../entity/navigation/props/NavigationLogProp";
import UrlHash from "../helper/UrlHash";

/**
 * HistoryPage (Chat history)
 * @class HistoryPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class HistoryPage extends PageCommon<HistoryPageProp, HistoryPageState> {

    private configuration: Config = null;
    private paginator = null;

    /**
     * Constructor
     * @param props Property
     */
    public constructor(props: HistoryPageProp){
        super(props);
        this.state = new HistoryPageState();
        this.prepare();
    }

    /**
     * Initialize chat history
     */
    private async prepare(){
        let me = this;
        let idChatTopic = UrlHash.get("id");
        me.configuration = new Config();
        me.paginator = React.createRef();

        let agents = await UserBL.getAgents([2,7]);
        let agentElements = CheckListElement.getFrom(agents, "FullName", "userName");
        let languages = await LocaleBL.getLanguages();

        if(idChatTopic) {
            await me.searchResult(idChatTopic);
        }

        me.setState({
            agents: agentElements,
            languages: languages
        });
    }

    /**
     *
     * @param idChatTopic
     */
    public async searchResult(idChatTopic = '') {
        let me = this;
        me.setState({
            loadingChatResults: true
        })

        // Get user list
        let users = [];
        users = me.state.agents.filter( a => a.checked ).map( a => a.value );

        let data = {
            rate: me.state.rate,
            platform: me.state.platform,
            criteria: me.state.criteria,
            startDate: moment(me.state.dateStart).format("YYYY-MM-DD") + ' ' + me.state.hourStart,
            endDate: moment(me.state.dateEnd).format("YYYY-MM-DD") + ' ' + me.state.hourEnd,
            userName: me.state.user,
            users: users,
            languages: me.state.language,
            siteCode: me.state.siteCode
        };

        let request = new PaginatedRequest();
        request.data = data;
        request.pagination = me.state.pagination;

        var result = await ChatBL.getChats(request);

        let chatPairs = new Array<ChatPair>();
        result.data.forEach( chat => {
            let chatPair = new ChatPair();
            chatPair.entity = chat;
            chatPair.component = null;
            chatPairs.push(chatPair);
        })

        // Setup pagination!
        let pagination = result.pagination;

        me.setState({
            pagination: pagination,
            loadingChatResults: false,
            chatFound: chatPairs
        }, () => {
            // Update paginator
            me.paginator.current?.update();
        });

        if(idChatTopic) {
            await me.openChatHistory(`supportChat_${idChatTopic}`)
        }
    }


    /**
     * Open the history in a new chatbox component
     * @param chatTopic Topic
     */
    public openChatHistory(chatTopic: string) {
        let me = this;
        ChatBL.getChat(chatTopic).then(chat => {
            let chatPair = new ChatPair();
            chatPair.entity = chat;
            chatPair.component = <ChatBox readOnly={true} chat={chat}/>

            me.setState({
                loadingChatCurrent: false,
                selectedChat: chatPair
            });
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Change the chat that is being visualized
     * @param chatPair ChatPair
     */
    private changeChatClick(chatPair: ChatPair) {
        let me = this;
        me.setState({
            loadingChatCurrent: true,
            selectedChat: null
        }, () => {
            me.openChatHistory(chatPair.entity.chatTopic);
        });
        document.getElementById("tab-chat").click();
    }

    /**
     * Handle search form
     * @param e Event
     */
    private search(e) {
        let me = this;
        e.preventDefault();
        me.setState({
            pagination: me.state.pagination.setCurrentPage(1),
            selectedChat: null,
            showFilters: false
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Change the selected language
     * @param language UserLanguage
     */
    private toggleLanguage(language: UserLanguage){
        let me = this;
        let languages: Array<string> = me.state.language;
        if(languages.includes(language.code)){
            languages = languages.filter( l => l != language.code);
        } else {
            languages.push(language.code);
        }
        me.setState({
            language: languages
        });
    }
    private updateVisibleNavigationLog: IUpdateVisibleNavigationLog = (isVisible: boolean) => {
        this.setState({visibleNavigationLog: isVisible});
    }
    private updateNewNavigations: IUpdateNewNavigation = (updateNewNavigations:INewNavigation[], chatTopic: string) => {
        const me = this;
        const updatedNewNavigations = updateNewNavigation(me.state.newNavigations, updateNewNavigations, chatTopic);
        this.setState({newNavigations: updatedNewNavigations});
    }

    render () {
        let me = this;
        const currentChat = me.state.selectedChat?.entity;
        const currentTopic = !!(me.state.selectedChat && me.state.selectedChat.entity.chatTopic);
        const chatComponent = (<Card className="card-chat">
            <CardBody className="p-0">
                <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                        <LoaderMini visible={me.state.loadingChatCurrent } message={_("key_chat_loading_history")} />
                        { !me.state.loadingChatCurrent && me.state.selectedChat && me.state.selectedChat.component }
                        { !(me.state.loadingChatCurrent || me.state.selectedChat) &&
                          <Empty message={_("key_chat_empty_history")}/>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>);
        const navigationUserComponent = currentChat && (<Card className="card-chat-navigation">
            <CardBody className="p-0">
                <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                        <div key={currentChat.chatTopic+"_navigation"} className={!currentTopic ? "d-none" : ""}>
                            <NavigationBox currentChat={currentChat} selectedChat={currentChat}
                                           visible={me.state.visibleNavigationLog} updateNewNavigations={me.updateNewNavigations} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>);
        return (
          <Row>
              <Col md="1"></Col>
              <Col md="3" className="call-chat-sidebar">
                  <Card className="card-chat">
                      <CardBody className="chat-body">
                          <div className="chat-box">
                              <div className="chat-left-aside">
                                  <div className="people-list">
                                      <div className="search">
                                          <Form onSubmit={ e => me.search(e) } className="theme-form">
                                              <div className="mb-2">
                                                  <input
                                                    onChange={ e => me.setState({ criteria: e.target.value }) }
                                                    value={ me.state.criteria }
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={_("key_search_criteria")}
                                                  />
                                                  <i className="fa fa-search search-icon"></i>
                                              </div>
                                              {me.state.showFilters &&
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="form-group mb-2">
                                                                <label>{_("key_platform")}: </label><br/>
                                                                <select onChange={ e => me.setState({platform: e.target.value }) } name="platform" value={ me.state.platform } className="form-control">
                                                                    <option value={""}>{_("key_platform_all")}</option>
                                                                    {REQUEST_PLATFORM.map( (rp) => {
                                                                        return <option key={ rp.identifier } value={rp.identifier}>{rp.name}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="form-group mb-2">
                                                                <label>{_("key_sites")}: </label><br/>
                                                                <select onChange={ e => me.setState({siteCode: e.target.value }) } name="siteCode" value={ me.state.siteCode } className="form-control">
                                                                    <option value={""}>{_("key_sites_all")}</option>
                                                                    {ADMIN_SITES.map( (rp) => {
                                                                        return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <label>{_("key_agent")}: </label><br/>
                                                            {me.state.agents?.length > 0 ?
                                                              <CheckList
                                                                onChange={elements => me.setState({agents: elements}) }
                                                                values={me.state.agents}
                                                              />
                                                              :
                                                              <LoaderSpinner message={_("key_loading")} />
                                                            }
                                                            {/*
                                            <div className="form-group mb-2">
                                                <label>{_("key_agent")}: </label><br/>
                                                <select value={me.state.user} onChange={ e => me.setState({user: e.target.value })} className="form-control">
                                                    <option value="">{_("key_agent_all")}</option>
                                                    {me.state.agents.map( user =>
                                                        <option key={user.id} value={user.userName}>{ user.firstName + " " + user.lastName }</option>
                                                    )}
                                                </select>
                                            </div>
                                            */}
                                                        </Col>
                                                    </Row>
                                                    <label htmlFor="search_date">{_("key_date_start")}: </label><br/>
                                                    <Row>
                                                        <Col>
                                                            <DatePicker
                                                              className="form-control"
                                                              locale={AdminLocaleBL.Language.toLowerCase()}
                                                              maxDate={ me.state.dateEnd }
                                                              onChange={ e => me.setState({dateStart: e}) }
                                                              selected={me.state.dateStart}
                                                              required={true}/>
                                                        </Col>
                                                        <Col><TimePicker value={me.state.hourStart} firstTime={true} onChange={ time => me.setState({ hourStart: time }) }/></Col>
                                                    </Row>
                                                    <label htmlFor="search_hour_start" className="mt-2">{_("key_date_end")}: </label><br/>
                                                    <Row>
                                                        <Col>
                                                            <DatePicker
                                                              className="form-control"
                                                              locale={AdminLocaleBL.Language.toLowerCase()}
                                                              minDate={ me.state.dateStart }
                                                              maxDate={ new Date() }
                                                              onChange={ e => me.setState({dateEnd: e}) }
                                                              selected={me.state.dateEnd}
                                                              required={true} />
                                                        </Col>
                                                        <Col><TimePicker value={me.state.hourEnd} firstTime={true} onChange={ time => me.setState({ hourEnd: time }) }/></Col>
                                                    </Row>
                                                    {/* USER RATING */}
                                                    <Rate
                                                      className="mt-3"
                                                      title={_("key_rate")}
                                                      value={me.state.rate}
                                                      onChange={ rate => me.setState({rate: rate})}
                                                    />

                                                    <label className="mt-3">{_("key_language")}: </label>
                                                    <div className="text-center">
                                                        {me.state.languages.map( lang => {
                                                            let active = me.state.language.includes(lang.code);
                                                            return(
                                                              <button type="button"
                                                                      key={lang.id}
                                                                      onClick={ () => me.toggleLanguage(lang) }
                                                                      className={`btn btn-${active ? 'primary text-white ': 'light'} m-2`}>
                                                                  {active && <IconFa icon="check text-white mr-2"></IconFa>}
                                                                  {lang.name}
                                                              </button>
                                                            );
                                                        })}
                                                    </div>
                                                </>}

                                              <div className="text-center p-2">
                                                  <Button className="m-2" type="submit" color="primary">
                                                      <IconFa icon="search"/> {_("key_search")}
                                                  </Button>
                                                  <Button className="m-2" onClick={ e => me.setState({showFilters: !me.state.showFilters })}>
                                                      <IconFa icon={`chevron-${me.state.showFilters ? "up": "down"}`}/> {me.state.showFilters ? _("key_filters_hide"): _("key_filters_show")}
                                                  </Button>
                                              </div>
                                          </Form>
                                      </div>
                                      {!me.state.showFilters && <>
                                          <LoaderMini visible={me.state.loadingChatResults} message={_("key_chat_loading_current")}/>
                                          {!me.state.loadingChatResults && me.state.chatFound.length > 0 ? 
                                              <>
                                    <ul className="list history-container custom-scrollbar">
                                        { me.state.chatFound.filter( chat => {
                                            // Eliminar chats repetidos ¿?
                                            let repeated = [];
                                            me.state.chatFound.forEach( cc => {
                                                cc.entity.chatTopic == chat.entity.chatTopic && repeated.push(cc);
                                            });
                                            let last = repeated[repeated.length - 1];
                                            repeated.length > 1 && (last.active = false)
                                            return chat.active;
                                        }).map((chatPair, i) => {
                                            let active = me.state.selectedChat && me.state.selectedChat.entity.chatTopic == chatPair.entity.chatTopic;
                                            return (
                                                <ChatElement key={i} active={active} chat={chatPair.entity} onClick={ () => me.changeChatClick(chatPair) } showFirstMessageDateInSummary={true} />
                                            );
                                        })}
                                    </ul>
                                </>
                            :  
                                <Empty visible={!me.state.loadingChatResults}/>
                            }
                            <Paginator showPageSizes={false} visible={!me.state.loadingChatResults && me.state.chatFound.length > 0} ref={ me.paginator } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                            </>}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="7" className="call-chat-body">
                  {!me.state.loadingChatCurrent && me.state.selectedChat && (<ChatTabs visibleNavigationLog={me.state.visibleNavigationLog} currentChat={me.state.selectedChat.entity} newNavigations={me.state.newNavigations} selectedChat={me.state.selectedChat.entity} updateVisibleNavigationLog={me.updateVisibleNavigationLog}></ChatTabs>)}
                  <ChatContentTabs chatComponent={chatComponent} visibleNavigationLog={me.state.visibleNavigationLog}></ChatContentTabs>
                  {!me.state.loadingChatCurrent && me.state.selectedChat && <ChatContentTabs navigationUserComponent={navigationUserComponent} visibleNavigationLog={me.state.visibleNavigationLog}></ChatContentTabs>}
              </Col>
              <Col md="1"></Col>
          </Row>
        );
    }
}

export default HistoryPage;