import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import SuggestionFormProp from '../../entity/suggestion/props/SuggestionFormProp';
import SuggestionFormState from '../../entity/suggestion/states/SuggestionFormState';
import { Button, Card, CardBody } from '../../../cuba/components/utils/reactstrap';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import { UserLanguage } from '../../entity/login/LoginEntity';
import ChatSuggestionBL from '../../bl/chat/ChatSuggestionBL';
import Alert from '../utils/Alert';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import { _ } from '../../bl/admin/AdminLocaleBL';
import TextBox from '../control/TextBox';
import { SUGGESTION_EDITOR_CONFIG } from '../../constants/data/suggestion';
import { ADMIN_SITES } from '../../constants/data/sites';

/**
 * Update chat suggestions
 * @class SuggestionForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SuggestionForm extends ComponentCommon<SuggestionFormProp, SuggestionFormState> {
    
    private _textboxRef = null;
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: SuggestionFormProp){
        super(props);
        let me = this;
        me.state = new SuggestionFormState();  
        me._textboxRef = React.createRef();
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize componente
     */
    private async prepare(){
        let me = this;
        let languages = await LocaleBL.getLanguages();
        me.setState({
            languages: languages
        });
        me.update();
    }

    /**
     * Upgrade the status according to the property
     */
    public update(){
        let me = this;
        let suggestion = me.props.suggestion;
        if(suggestion.id < 1 && me.state.languages.length > 0){
            suggestion.idLanguage = me.state.languages[0].id;
        }
        me.setState({
            suggestion: me.props.suggestion
        });
    }

    /**
     * Establish language of the suggestion
     * @param language 
     */
     private setLanguage(language: UserLanguage){
        let me = this;
        let suggestion = me.state.suggestion;
        suggestion.idLanguage = language.id;
        me.setState({
            suggestion: suggestion
        });
    }

    /**
     * Establish language of the suggestion
     * @param language 
     */
    private setSiteCode(siteCode: string){
        let me = this;
        let suggestion = me.state.suggestion;
        suggestion.siteCode = siteCode;
        me.setState({
            suggestion: suggestion
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        let me = this;
        let suggestion = me.state.suggestion;
        suggestion.suggestion = me._textboxRef.current.getValue();
        suggestion = await ChatSuggestionBL.saveSuggestion(suggestion);
        me.props.onChange && me.props.onChange(suggestion);
        Alert.success();
        me.setState({
            suggestion: suggestion
        })
        me._auditorLog.current?.update();
    }

    /**
     * Delete suggestion (API)
     * @param suggestion ChatSuggestion Object
     */
    private async deleteSuggestion(){
        let me = this;
        let suggestion = me.state.suggestion;
        let result = await Alert.confirm(_("key_suggestion_delete"), _("key_suggestion_delete_message"));
        if(result.isConfirmed){
            let result = await ChatSuggestionBL.deleteSuggestion(suggestion);
            Alert.success();
            me.setState({
                visible: false
            });
            // Notify parent!
            me.props.onChange && me.props.onChange(suggestion);
        }
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({module: "Chat", table: "ChatSuggestion", id: me.state.suggestion?.id})

        return (
            me.state.visible &&
            <>
                <Card>
                    <CardBody className="p-5">
                        <form onSubmit={ e => me.handleSubmit(e) }>
                            <div className="m-checkbox-inline mb-2">
                                <h4 className="form-check-inline col-md-12">{_("key_language")}: </h4>
                                { me.state.languages && me.state.languages.map( (lang: UserLanguage) => 
                                    <div key={lang.id} className={`form-check form-check-inline radio radio-${lang.id == me.state.suggestion.idLanguage ? 'success': "primary"}`}>
                                        <input onChange={ e => me.setLanguage(lang) } checked={lang.id == me.state.suggestion.idLanguage} className="form-check-input" id={`label_disp_${lang.id}`} type="radio" name={`chat_dispositions_${lang.id}`}/>
                                        <label className="form-check-label mb-0" htmlFor={`label_disp_${lang.id}`}>{lang.name}</label>
                                    </div>
                                )} 
                            </div>
                            <label className="mt-3">{_("key_sites")}: </label><br/>
                            <select onChange={ e => me.setSiteCode(e.target.value) } name="siteCode" value={ me.props.suggestion.siteCode } className="form-control mb-3">
                                <option value={""}>{_("key_sites_all")}</option>
                                {ADMIN_SITES.map( (rp) => {
                                    return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                })}
                            </select>
                            <TextBox 
                                config={SUGGESTION_EDITOR_CONFIG} 
                                ref={me._textboxRef} 
                                value={me.props.suggestion.suggestion}>    
                            </TextBox>
                            <div className="p-2">
                                <Button>{_("key_save")}</Button>
                                {me.state.suggestion.id > 0 && 
                                    <Button color="info" className="ml-2 float-right" onClick={ e => me.deleteSuggestion() } type="button">{_("key_delete")}</Button>
                                }
                            </div>
                        </form>
                    </CardBody>
                </Card>
                { me.state.suggestion.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}

export default SuggestionForm;