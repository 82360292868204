import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import {MilesLogo,Password,SignIn,EmailAddress,RememberPassword,ForgotPassword,inputStyle} from '../../constants/common/index';
import {Form,Row,Col,Button,TabPane,TabContent, Container } from '../../../cuba/components/utils/reactstrap'
import LoginFormProp from '../../entity/login/props/LoginFormProp';
import LoginFormState from '../../entity/login/states/LoginFormState';
import LoginBL from '../../bl/login/LoginBL';
import { MilesContext } from '../../context/MilesContext';
import { _ } from '../../bl/admin/AdminLocaleBL';

/**
 * Component that administers the login of the application
 * @class LoginForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LoginForm extends ComponentCommon<LoginFormProp, LoginFormState>{
    static contextType = MilesContext;

    public constructor(prop: LoginFormProp){
        super(prop);
        this.state = new LoginFormState();
    }
    
    /**
     * Call login API
     * @param e 
     */
    private login(e){
        let me = this;
        e && e.preventDefault();
        me.setState({busy: true});
        LoginBL.login(me.state.email, me.state.password).then( logged => {
          if (logged) {
            me.userData( data => {
                me.setState({busy: false});
                me.props.updateInterface(logged);
            });
            me.setState({error: ""});
          } else {
            me.setState({
                busy: false,
                error: _("key_login_invalid")
            });
          }
        });
    }
    
    /**
     * Get user info from session
     * @param {*} callback
     */
    private userData(callback) {
        let me = this;
        LoginBL.userData().then( r => {
            me.context.main.Session = r;
            callback(r);
        });
    }
    
    /**
     * Action that reminds the mail to the user, according to the data entered
     * @param {*} e 
     */
    private remember (e) {
        let me = this;
        e.preventDefault();
        me.setState({busy: true});

        LoginBL.remember(me.state.email).then( changed => {
          me.setState({busy: false});
          if (changed) {
            me.setState({message: _("key_email_recovery_sent")});
          } else {
            me.setState({message: _("key_user_not_exists")});
          }
        });
    }
    
    /**
     * Verify enter key
     * @param {*} event Event object 
     * @param {*} callback 
     */
    private checkEnter(event, callback) {
        if(event.key === 'Enter'){
          callback();
        }
    }

    render(){
        let me = this;
        return (
            <Container fluid={true} className="p-0">
              <Row className="m-0">
                <Col xs="12" className="p-0">     
                  <div className="login-card">
                    <div>
                      <div>
                        <a className="logo">
                          <img className="img-fluid for-light" src={MilesLogo} alt=""/>
                          <img className="img-fluid for-dark" src={MilesLogo} alt=""/>
                        </a>
                      </div>
                      <div className="login-main login-tab"> 
                        <TabContent activeTab={me.state.selected} className="content-login">
                          <TabPane  className="fade show" tabId={"login"}>
                            <Form disabled={me.state.busy} onSubmit={ (e) => me.login(e) } className="theme-form">
                              <h4>{_("key_login")}</h4>
                              <p>{_("key_login_description")}</p>
                              <div className="form-group">
                                <label className="col-form-label">{_("key_email")}</label>
                                <input onChange={ e => me.setState({email: e.target.value}) } required={true} type="email" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">{_("key_password")}</label>
                                <input onKeyPress={ e => me.checkEnter(e, () => me.login(e) ) } onChange={ e => me.setState({password: e.target.value}) } required={true} type="password" className="form-control" />
                              </div>
                              <div className="form-group mb-0">
                                <div className="checkbox ml-3">
                                  <input id="checkbox1" type="checkbox" className="form-check-input"/>
                                  <label htmlFor="checkbox1" className="text-muted">{_("key_password_remember")}</label>
                                </div>
                                <a className="link" onClick={ () => me.setState({selected: 'remember'}) }>{_("key_password_forgotten")}</a>
                                <div className="checkbox ml-3">
                                  {/*<Input id="checkbox1" type="checkbox"/>
                                  <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>*/}
                                </div>
                                <div>
                                    <Button disabled={me.state.busy} type="submit" color="primary" className="btn-block" >{_("key_login")}</Button>
                                </div>
                              </div>
                              {me.state.error.length > 0 && 
                                <div>
                                  <h5 className="text-danger text-center m-2">{me.state.error}</h5>
                                </div>
                              }
                            </Form>
                          </TabPane>
                          <TabPane tabId="remember">
                            <Form disabled={me.state.busy} onSubmit={ (e) => me.remember(e) } className="theme-form">
                              <h4>{_("key_password_remember")}</h4>
                              <p>{_("key_mail_enter")}</p>
                              <div className="form-group">
                                <label className="col-form-label">{_("key_email")}</label>
                                <input required={true} onChange={ e => me.setState({email: e.target.value}) } type="email" className="form-control" />
                              </div>
                              <div>
                                <Button disabled={me.state.busy} color="primary" type="submit" className="btn-block mb-1">{_("key_password_remember")}</Button>
                                <Button onClick={ () => me.setState({selected: 'login'}) } color="danger" className="btn-block">{_("key_go_back")}</Button>
                              </div>
                              {me.state.message.length > 0 && 
                                <div>
                                  <h5 className="text-success text-center m-2">{me.state.message}</h5>
                                </div>
                              }
                            </Form>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
        );
    }
}

export default LoginForm;