import React, { Fragment } from "react";
import ComponentCommon from "../../core/ComponentCommon";
import LoaderBackdropProp from "../../entity/utils/props/LoaderBackdropProp";
import LoaderBackdropState from "../../entity/utils/states/LoaderBackdropState";

/**
 * Shows a loader: is inserted into a segment
 * @class LoaderSpinner
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LoaderSpinner extends ComponentCommon<LoaderBackdropProp, LoaderBackdropState> {

    public constructor(props){
        super(props);
    }

    public get Visible(): boolean {
        let me = this;
        return me.props.visible == undefined || me.props.visible == true;
    }

    render() {
        let me = this;
        return (
            me.Visible ?
            <Fragment>
                <div className={`mb-2 ${me.Visible ? '' : 'loderhide'}`}>
                    <div>
                        <i className="fa fa-spinner mr-2"></i>
                        {me.props.message && <b>{me.props.message}</b>}
                    </div>
                </div>
            </Fragment>
            : <></>
        );
    }
}

export default LoaderSpinner;