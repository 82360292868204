import IState from '../../../core/IState';
import { Pagination } from '../../control/ControlEntity';
import { User, GroupUser } from '../../login/LoginEntity';

export class UserPageState implements IState {
    public defaultState?: boolean = false;
    public currentUsers: Array<User> = [];
    public loadingUsers: boolean = false;
    public editUser: boolean = false;
    public selectedUser: User = null;
    
    public pagination: Pagination = new Pagination();
    public criteria: string = "";

    public groups: Array<GroupUser> = []
    public currentGroup: string = "0"
    public filterEnabled: string = "1";
}

export default UserPageState;