import React from "react";
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import Paginator from "../component/control/Paginator";
import { PaginatedRequest, Pagination } from "../entity/control/ControlEntity";
import LoaderMini from "../component/utils/LoaderMini";
import { _ } from "../bl/admin/AdminLocaleBL";
import PageCommon from "../core/PageCommon";
import ImagesPageProp from "../entity/images/props/ImagesPageProp";
import ImagesPageState from "../entity/images/states/ImagesPageState";
import { Images } from "../entity/images/ImagesEntity";
import { ImagesBL } from "../bl/images/ImagesBL";
import LoginBL from "../bl/login/LoginBL";
import ImagesForm from "../component/images/ImagesForm";

/**
 * Images Manager component
 * @class ImagesPage
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export class ImagesPage extends PageCommon<ImagesPageProp, ImagesPageState> {
    //Paginator reference
    private _paginatorRef = null;
    private imagesForm: ImagesForm;

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: ImagesPageProp) {
        super(props)
        this.state = new ImagesPageState();
        this.imagesForm = new ImagesForm(this.state);
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        this._paginatorRef = React.createRef();
        me.searchResult();
    }
    
    /**
     * Handle form submit
     * @param e Event
     */
    private async handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        await me.searchResult(true);
    }

    /**
     * Select a image and displays the preview
     * @param image 
     */
    private selectImage(image: Images) {
        let me = this;
        me.setState({
            image: image,
            action: "view"
        });
    }

    private async switchEnableImage(image: Images){
        const me = this;
        image.enabled = image.enabled ? 0 : 1;
        me.setState({
            loadingImages: true,
            image: image,
            action: "update"
        });
        await this.imagesForm.updateImage(JSON.parse(JSON.stringify(image)));
        await me.searchResult();
    }

    /**
     * Get user info from session
     * @param {*} prop Prop name
     * @param {*} defaults Default value
     * @returns
     */
    private UserData(prop, defaults = "") {
        let session = LoginBL.SessionData;
        return session[prop] ? session[prop] : defaults;
    }

    /**
     * Create image
     */
    private createImage(){
        let me = this;
        let image = new Images();
        image.idUser = me.UserData("id");
        image.user = LoginBL.SessionData;
        me.setState({
            image: image,
            action: "create"
        })
    }

    /**
     * Make the query by page
     */
    private async searchResult(resetCount: boolean = false){
        let me = this;

        let request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data = {
            criteria:  me.state.criteria,
            enabled: +me.state.filterEnabled
        };

        me.setState({loadingImages: true});
        let data = await ImagesBL.getImages(request);

        me.setState({
            loadingImages: false,
            images: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, async () => {
            await me.searchResult();
        });
    }

    /**
     * Handle on close
     */
    private async handleClose(){
        let me = this;
        me.setState({
            image: null,
            action: ""
        });
        await me.searchResult();
    }

    /**
     * Copy text to clipboard
     * @param text Text to copy
     * @private
     */
    private copyTextToClipboard(text: string){
        let input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    }

    /**
     * Convert bytes to MB
     * @param bytes
     * @private
     */
    private convertBytesToMb(bytes: number){
        return (bytes / 1e6).toFixed(2);
    }

    render(){
        let me = this;
        return (
            <>
                { (!me.state.action || me.state.action === "update") ?
                    <Card>
                        <CardHeader className="d-flex">
                            <Col md="6">
                                <h5>{_("key_mod_page_images")}</h5>
                            </Col>
                            <Col md="6">
                            <Button color="success" className="float-right mt-3 mt-md-0" onClick={ () => me.createImage() }>
                                    <IconFa icon="plus"/> {_("key_upload_image")}
                                </Button>
                            </Col>
                        </CardHeader>
                        <CardBody> 
                            <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                <Row>
                                    <Col md="4">
                                        <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                                    </Col>
                                    <Col md="4">
                                        <select value={me.state.filterEnabled} onChange={ e => me.setState({filterEnabled: e.target.value })} className="form-control">
                                            <option value="1">{_("key_enabled")}</option>
                                            <option value="0">{_("key_disabled")}</option>
                                        </select>
                                    </Col>
                                    <Col md="4">
                                        <button className="btn btn-primary mt-3 mt-md-0 float-right" type="submit">
                                            <IconFa icon="search"/> {_("key_filter_results")}
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                            <table className="table table-m-2">
                                <thead>
                                <tr>
                                    <th scope="col">{_("key_preview")}</th>
                                    <th className="hide-md" scope="col">{_("key_name")}</th>
                                    <th className="hide-md" scope="col">{_("key_profile")}</th>
                                    <th className="hide-md" scope="col">{_("key_upload_date")}</th>
                                    <th className="hide-md" scope="col">{_("key_type")}</th>
                                    <th className="hide-md" scope="col">{_("key_size")}</th>
                                    <th scope="col">{_("key_actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {me.state.loadingImages &&
                                    <tr>
                                        <td colSpan={6}>
                                            <LoaderMini message={_("key_search_loading_results")}/>     
                                        </td>
                                    </tr>
                                    }
                                {!me.state.loadingImages && me.state.images.map((image: Images) =>
                                    <tr key={image.id}>
                                        <td className="hide-md">
                                            <a href={image.path} target="_blank" rel="nofollow noopener noreferrer">
                                                <img src={image.thumbnailPath} className={"img-thumbnail"}
                                                     alt={image.name}/>
                                            </a>
                                        </td>
                                        <td className="hide-md">
                                            <a href={image.path} target="_blank" rel="nofollow noopener noreferrer">
                                                {image.name}
                                            </a>
                                        </td>
                                        <td className="hide-md">{image.user?.FullName}</td>
                                        <td className="hide-md">{image.CreatedDateText}</td>
                                        <td className="hide-md">{image.extension}</td>
                                        <td className="hide-md">{me.convertBytesToMb(image.sizeByte)} MB</td>
                                        <td className="p-2 actions">
                                            <Button className="p-x-md-0 w-100 mt-2"
                                                    onClick={() => me.selectImage(image)} color="secondary" size="sm">
                                                <IconFa icon="eye"/> {_("key_view")}
                                            </Button>
                                            <Button className="p-x-md-0 w-100 mt-2"
                                                    onClick={() => me.copyTextToClipboard(image.path)} size="sm">
                                                <IconFa icon="clipboard"/> {_("key_copy_link")}
                                            </Button>
                                            {image.enabled?
                                                <Button className="p-x-md-0 w-100 mt-2" color="danger"
                                                                   onClick={async() => await me.switchEnableImage(image)} size="sm">
                                                <IconFa icon="ban"/> {_("key_disable")}
                                                </Button>:
                                                <Button className="p-x-md-0 w-100 mt-2" color="success"
                                                              onClick={async() => await me.switchEnableImage(image)} size="sm">
                                                <IconFa icon="check"/> {_("key_enable")}
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <Paginator
                                visible={!me.state.loadingImages && me.state.images.length > 0}
                                ref={me._paginatorRef}
                                pagination={me.state.pagination} 
                                onChange={ pagination => me.passPage(pagination) } />
                        </CardBody>
                    </Card>
                    :
                    <ImagesForm onClose={async() => await me.handleClose()} image={me.state.image} action={me.state.action} />
                }
            </>
        );
    }
}
