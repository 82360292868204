import IState from '../../../core/IState';
import ApiTinyToken from "../../api/tiny/ApiTinyTokenEntity";

export class TextBoxState implements IState {
    public uploadDialog: boolean = false;
    public uploadingFile: boolean = false;
    public uploadedFile: string = "";
    public text: string = "";
    public apiTinyTokenUpdated: ApiTinyToken|null = null;
}

export default TextBoxState;