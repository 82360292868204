import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { UserLanguage } from "../../login/LoginEntity";
import { Locale } from "../LocaleEntity";

export default class LocalePageState implements IState {
    public pagination: Pagination = new Pagination();
    public locale: Array<Locale> = [];
    public languages: Array<UserLanguage> = [];
    public criteria: string = "";
    public busy: boolean = false;
    public width: Number = 0;
    public height: Number = 0;
}