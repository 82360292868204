/**
 * Represents a channel
 */
export class Channel {
    id: number;
    name: string;
    profile: string;
    platform: string = "web";
    identifier: string;
    siteCode: string;
    enabled: boolean = true;
}