import { BaseTranslate, BaseTranslation } from "../../core/Translate";
import GeneralFunctions from "../../helper/GeneralFunctions";
import { Place } from "../place/PlaceEntity";

/**
 * It represents the default translation
 */
 export class Customer {
    public id: number = null;
    public name: string = "";
    public ruleCode: string = "";
    public template: string = "";
    public urlCdn: string = "";
    public email: string = "";
    public enabled: boolean = true;
}

export class CustomerSite {
    public id: number = null;
    public idCustomer: number = null;
    public idPlace: number = null;
    public idLocation: number = null;
    public code: string = "";
    public name: string = "";
    public langByDefault: string = "";
    public currencyByDefault: string = "";
    public domain: string = "";
    public logo: string = "";
    public address: string = "";
    public addressTwo: string = "";
    public urlKey: string = "";
    public googleTag: string = "";
    public urlCdn: string = "";
    public urlFacebook: string = "";
    public urlFacebookMessenger: string = "";
    public urlTwitter: string = "";
    public urlInstagram: string = "";
    public whatsappPhone: string = "";
    public showNortonSeal: boolean = true;
    public showVwoVideo: boolean = true;
    public idTrustPilot: string = "";
    public isDefault: boolean = true;
    public hideOptionsFooter: boolean = true;
    public urlAdress1: string = "";
    public urlAdress2: string = "";
    public classImage: string = "";
    public enabled: boolean = true;
}

export class CustomerSiteLandingPage {
    public id: number = null;
    public idCustomerSite: number = null;
    public service: string = "";
    public typeLandingPage: string = "";
    public h1: string = "";
    public h2: string = "";
    public h3: string = "";
    public metaTitle: string = "";
    public metaDescription: string = "";
    public metaKeyword: string = "";
    public content: string = "";
    public optionQuickSearch: string = "";
    public inAllSites: boolean = true;
    public showSchemas: boolean = true;
    public image: string = "";
    public enabled: boolean = true;
    public translates: Array<CustomerSiteLandingPage_Translate> = [];
    public customerUrlSiteLandingPages: Array<CustomerUrlSiteLandingPage> = []
}

export class CustomerSiteLandingPage_Translate {
    public id: number = null;
    public idRelation: number = null;
    public content: string = "";
    public h1: string = "";
    public h2: string = "";
    public h3: string = "";
    public language: string = "";
    public metaDescription: string = "";
    public metaKeyword: string = "";
    public metaTitle: string = "";
}

export class CustomerSiteLandingPageLanguage {
    public content: string = "";
    public h1: string = "";
    public h2: string = "";
    public h3: string = "";
    public metaDescription: string = "";
    public metaKeyword: string = "";
    public metaTitle: string = "";
    public urlRequest: string = "";
}

export class CustomerUrlSiteLandingPage {
    public id: number = 0;
    public idCustomerSiteLandingPage: number = 0;
    public customerUrl: CustomerUrl = null
}

export class CustomerUrl {
    public id: number = 0;
    public idCustomer: number = 0;
    public language: string = '';
    public priority: string = '';
    public urlRequest: string = '';
}

export class CustomerSiteLandingPageSchema extends BaseTranslate {
    public id: number = 0;
    public idCustomerSiteLandingPage: number = 0;
    public idPlace: number = 0;
    public question: string;
    public answer: string;
    public icon: string;
    public position: number = 0;
    public place: Place = null; 
    public changed: boolean = false;

    /**
     * Set item position
     * @param position Position
     */
    public setPosition(position: number){
        let me = this;
        if(position !== me.position){
            me.changed = true;
            me.position = position;
        }
    }
    
	/**
	 * Returns a text without HTML labels
	 */
	public get AnswerText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.answer);
	}

	/**
	 * Returns a text without HTML labels
	 */
	public get QuestionText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.question);
	}
}

export class CustomerSiteLandingPageSchemaGeolocation extends BaseTranslation {
    public id: number = 0;
    public idPlace: number = 0;
    public question: string;
    public answer: string;
    public place: Place = null; 
    
	/**
	 * Returns a text without HTML labels
	 */
	public get AnswerText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.answer);
	}
    
	/**
	 * Returns a text without HTML labels
	 */
	public get QuestionText(): string {
		let me = this;
		return GeneralFunctions.sliceText(me.question);
	}
}

/**
 * Schema position
 */
export class LandingSchemaPosition {
    public id: number = 0;
    public position: number = 0; 
}