import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { Card, CardHeader, CardBody, FormGroup, IconFa, Row, Col } from '../../../cuba/components/utils/reactstrap';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import AuditorLogList from '../auditor/AuditorLogList';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import LoaderBackdrop from '../utils/LoaderBackdrop';
//import { REQUEST_PLATFORM } from '../../constants/data/request';
import ContactFormProp from '../../entity/contact/props/ContactFormProp';
import ContactFormState from '../../entity/contact/states/ContactFormState';
import ContactBL from '../../bl/contact/ContactBL';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { ADMIN_SITES } from '../../constants/data/sites';

/**
 * Update chat contacts
 * @class ContactForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ContactForm extends ComponentCommon<ContactFormProp, ContactFormState> {
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    private _phoneRef = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ContactFormProp){
        super(props);
        let me = this;
        me.state = new ContactFormState();  
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
        me._phoneRef = React.createRef();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize componente
     */
    private async prepare(){
        let me = this;
        me.setState({
            contact: me.props.contact
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        let me = this;
        let channel = me.state.contact;

        // Check number
        if(!me.checkCurrentPhone()){
            Alert.error(_("key_phone_invalid"));
            return;
        }
         
        me.setState({busy: true});

        // Save changes...
        channel = await ContactBL.updateContact(channel);

        me.props.onChange && me.props.onChange(channel);
        Alert.success();
        me.setState({
            busy: false,
            contact: channel
        });

        // Update auditor
        me._auditorLog.current?.update();
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
     private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let channelState = me.state.contact;
        channelState[prop] = value;
        me.setState({
            contact: channelState
        });
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    /**
     * Check current phone number
     * @returns boolean
     */
    private checkCurrentPhone(): boolean  {
        let me = this;
        let current = me._phoneRef.current;
        return current.isValidNumber(current.tel.value);
    }

    /**
     * Validate phone
     * @param isValid 
     * @param phone 
     * @param country 
     * @returns 
     */
     private onPhoneChange(isValid, phone, country){
        let me = this;
        if(!isValid){
            return;
        }

        var number = String(phone);
        if(!number.includes("+")){
            number = `+${country.dialCode}${phone}`;
        }

        var contact = me.state.contact;
        if(isValid){
            contact.profile = number;
        } else {
            contact.profile = number;
        }

        me.setState({
            contact: contact
        });
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({
            module: "Contact", identifier: me.state.contact?.id
        });

        if(!me.state.contact){
            return <></>
        }

        return (
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card> 
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-0">{_("key_edit")} {me.state.contact?.name && <span>"{me.state.contact.name}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                        <IconFa icon="save"/> {_("key_save")}
                                    </button>
                                    <button onClick={ () => me.handleClose() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                            <Row>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_name")}</h6>
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="name" value={ me.state.contact.name } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_sites")}</h6>
                                        <select onChange={ e => me.handleChange(e) } name="siteCode" value={ me.state.contact.siteCode } className="form-control">
                                            {ADMIN_SITES.map( (rp) => {
                                                return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                            })}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_phone")}</h6>
                                        <IntlTelInput
                                                ref={me._phoneRef}
                                                defaultValue={me.state.contact.profile}
                                                formatOnInit={true}
                                                autoComplete={'tel'}
                                                containerClassName="intl-tel-input chat-tel-input"
                                                inputClassName="form-control"
                                                onPhoneNumberChange={ (isValid, phone, country) => me.onPhoneChange(isValid, phone, country) }
                                                />
                                        {/*
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="profile" value={ me.state.contact.profile } className="form-control" />
                                        */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/*
                            <FormGroup>
                                <h6 className="form-label">{_("key_platform")}</h6>                                         
                                <select required={ true } onChange={ e => me.handleChange(e) } name="platform" value={ me.state.contact.platform || "" } className="form-control">
                                    {REQUEST_PLATFORM.map( rol => {
                                        return <option key={ rol.identifier } value={rol.identifier}>{rol.name}</option>
                                    })}
                                </select>
                            </FormGroup>
                            */}
                        </CardBody>
                    </Card>
                </form> 
                { me.state.contact.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}

export default ContactForm;