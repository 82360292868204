import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { CustomerSiteLandingPageSchema, CustomerSiteLandingPageSchemaGeolocation, LandingSchemaPosition } from '../../entity/landing/LandingEntity';
import { UserLanguage } from '../../entity/login/LoginEntity';
import { Place } from '../../entity/place/PlaceEntity';
import Cookies from '../../helper/Cookies';

/**
 * Get and manage translations
 * @class LandingSchemaBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LandingSchemaBL extends BaseBL {
    /**
     * Gets a listing list of the list of related schemes
     * @param paramsData Parameters
     * @returns Schemas
     */
    public static async getSchemas(paramsData: PaginatedRequest): Promise<PaginatedRequest<CustomerSiteLandingPageSchema[]>> {
        let me = this;

        let data = {
            params: paramsData
        }

        let response = await axios.get('/Home/LandingPageAPI/ListSchema', data)
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);
        
        result.pagination = me.parseEntity(result.pagination, Pagination);
        result.data = me.parseEntityArray(result.data, CustomerSiteLandingPageSchema, (schema: CustomerSiteLandingPageSchema) => {
            schema.place = me.parseEntity(schema.place, Place);
            return schema;
        });
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
     public static async updateSchema(itemCustomerSiteLandingPage: CustomerSiteLandingPageSchema): Promise<CustomerSiteLandingPageSchema> {
        let me = this;
        let formData = me.getEntityFormData(itemCustomerSiteLandingPage);
        let response = await axios.post('/Home/LandingPageAPI/UpdateSchema', formData);
        let responseData = me.getResponseData(response);
        let updated = me.parseEntity(responseData, CustomerSiteLandingPageSchema, (schema: CustomerSiteLandingPageSchema) => {
            schema.place = me.parseEntity(schema.place, Place);
            return schema;
        });
        return updated;
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
     public static async updateSchemaGeolocation(itemCustomerSiteLandingPage: CustomerSiteLandingPageSchemaGeolocation): Promise<CustomerSiteLandingPageSchemaGeolocation> {
        let me = this;
        let formData = me.getEntityFormData(itemCustomerSiteLandingPage);
        let response = await axios.post('/Home/LandingPageAPI/UpdateSchemaGeolocation', formData);
        let responseData = me.getResponseData(response);
        let updated = me.parseEntity(responseData, CustomerSiteLandingPageSchemaGeolocation, (schema: CustomerSiteLandingPageSchemaGeolocation) => {
            schema.place = me.parseEntity(schema.place, Place);
            return schema;
        });
        return updated;
    }

    /**
     * Take the action to save in the API.
     * @param positions Entity object
     * @returns response
     */
     public static async updateSchemaPosition(positions: LandingSchemaPosition[]): Promise<CustomerSiteLandingPageSchema> {
        let me = this;
        let data = {
            params: {
                data: positions
            }
        };
        let response = await axios.post('/Home/LandingPageAPI/UpdateSchemaPosition', null, data);
        let responseData = me.getResponseData(response);
        let updated = me.parseEntity(responseData, CustomerSiteLandingPageSchema, (schema: CustomerSiteLandingPageSchema) => {
            schema.place = me.parseEntity(schema.place, Place);
            return schema;
        });
        return updated;
    }
}

export default LandingSchemaBL;