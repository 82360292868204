import { _ } from '../../../bl/admin/AdminLocaleBL';
import Config from '../../../core/component/chat/config/Config';
import FileUploader from '../../../helper/FileUploader';
import TextBox from '../TextBox';

export default class BasePlugin {
    protected _fileUploader: FileUploader = null;
    protected _icon: string;
    protected _label: string;
    protected _name: string;

    public constructor(){
        let me = this;
        let baseUrl = Config.configuration.baseUrl;
		me._fileUploader = new FileUploader(baseUrl);
    }
    
    setup(textbox: TextBox): void {
        let me = this;
        let editor = textbox.Editor;
        editor.ui.registry.addButton(me._name, {
            icon: me._icon,
            tooltip: _(me._label),
            onAction: function () {
                me.onAction(textbox);
            }
        });
    }

    onAction(textbox: TextBox) {}
}