import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { GroupPermissions } from "../SecurityEntity";

export default class PermissionListState implements IState {
    public pagination: Pagination = new Pagination();
    
    public role: string = "";
    public permissions: GroupPermissions[] = [];
    public busy: boolean = false;
}