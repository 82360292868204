import React from 'react';
import LocalePageProp from "../entity/locale/props/LocalePageProp";
import LocalePageState from "../entity/locale/states/LocalePageState";
import { Card, CardHeader, CardBody, Row, Col, Form, IconFa } from '../../cuba/components/utils/reactstrap';
import { LocaleBL } from '../bl/locale/LocaleBL';
import Paginator from '../component/control/Paginator';
import { PaginatedRequest, Pagination } from '../entity/control/ControlEntity';
import LocaleElement from '../component/locale/LocaleElement';
import LocaleElementDsn from '../component/locale/LocaleElementDsn';
import PageCommon from '../core/PageCommon';
import AuditorLogList from '../component/auditor/AuditorLogList';
import { AuditorConfig } from '../entity/auditor/AuditorEntity';
import { _ } from '../bl/admin/AdminLocaleBL';

/**
 * Component to manage translations
 * @class LocalePage
 */
export class LocalePage extends PageCommon<LocalePageProp, LocalePageState> {
    private _paginatorRef = null;
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: LocalePageProp){
        super(props);
        let me = this;
        me.state = new LocalePageState();
        me._paginatorRef = React.createRef();
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
        me._auditorLogConfig.addCriteria({module: "Locale"});
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();         
    }
    
    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        let dataLanguage = await LocaleBL.getLanguages();
        me.setState({
            languages: dataLanguage,
        });
        me.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
        me.searchResult();
    }

    /**
     * Make the query by page
     */
    private async searchResult(){
        let me = this;

        let request = new PaginatedRequest();
        request.pagination = me.state.pagination;
        request.data.criteria = me.state.criteria;

        me.setState({busy:true});
        let data = await LocaleBL.getLocale(request);
        let dataLanguage = await LocaleBL.getLanguages();
        
        me.setState({
            busy: false,
            locale: data.data,
            languages: dataLanguage,
            pagination: data.pagination
        }, () => {
            me._paginatorRef.current.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult();
    }

    /**
     * Handle on change locale event
     */
    private handleChange(){
        let me = this;
        me._auditorLog.current?.update();
    }

    render() {
        let me = this;
        
        return (
            <>
                <Card>
                    <CardHeader>
                        <h5>{_("key_mod_page_locale")}</h5>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                            <Row>
                                <Col md="6">
                                    <input id="criteria_input"
                                           className="form-control card-title mb-0"
                                           value={me.state.criteria}
                                           onChange={ e => me.setState({criteria: e.target.value })} 
                                           placeholder={_("key_search_criteria")}/>
                                </Col>
                                <Col md="6">
                                    <button className="btn btn-primary mt-3 mt-md-0 float-right"
                                            type="submit">
                                        <IconFa icon="search"/> {_("key_filter_results")}
                                    </button>
                                </Col>
                            </Row>
                        </Form>
                        <table className="table table-sm locale-table table-m-3 tableDsn">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>+</th>
                                    <th>{_("key_locale_key")}</th>
                                    <th>{_("key_label")}</th>
                                    <th>{_("key_state")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { me.state.busy && <tr><td colSpan={5}>{_("key_search_loading_results")}</td></tr>}
                                { me.state.locale.map( l => 
                                    <LocaleElementDsn onChange={ e => me.handleChange() }
                                                      languages={me.state.languages}
                                                      key={l.id}
                                                      locale={l} />
                                )}
                            </tbody>
                        </table>
                        <Paginator visible={me.state.locale.length > 0}
                                      ref={ me._paginatorRef }
                                      pagination={me.state.pagination}
                                      onChange={ pagination => me.passPage(pagination) }/>
                    </CardBody>
                </Card>
                {/* POR RAZON DE LECTURA DE LOGS, SE OMITE AQUI TEMPORALMENTE */}
                {/*<AuditorLog ref={me._auditorLog} config={me._auditorLogConfig} />*/}
            </>
        );
    }
}

export default LocalePage;