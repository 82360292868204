/**
 * Config Chat Parameters
 */
export class Config {
	private _baseUrl : string = "";

	public get baseUrl() : string {
		return this._baseUrl;
	}
	public set baseUrl(v : string) {
		this._baseUrl = v;
	}
	
	
	private _baseUrlMercure : string = "";
	public get baseUrlMercure() : string {
		return this._baseUrlMercure;
	}
	public set baseUrlMercure(v : string) {
		this._baseUrlMercure = v;
	}
	
	
	private _apiJWTToken : string = "";
	public get apiJWTToken() : string {
		return this._apiJWTToken;
	}
	public set apiJWTToken(v : string) {
		this._apiJWTToken = v;
	}
	
	private static _configuration : Config;
	public static get configuration() : Config {
		if(!Config._configuration){
			Config._configuration = new Config();
		}
		return Config._configuration;
	}
	public static set configuration(config: Config) {
		this._configuration = config;
	}

	/**
	* Class Constructor
	*/
	constructor() {
		let item = this.loadConfiguration();
	}

	/**
	 * load Configuration
	 */
	public async loadConfiguration()
	{
		const response = await fetch("/Home/SystemAPI/ConfigEnvironment");
		let data = (await response.json()).data.response;
		
		// Reemplazar el valor de la variable por el miles local.
		let localMiles = 'http://localhost/';
		let baseUrl = location.href.includes("localhost") ? localMiles : data.clientUrl;

		this.baseUrl = baseUrl;
		this.baseUrlMercure = data.mercureUrl;
		this.apiJWTToken = data.apiToken;
		Config._configuration = this;
	}
}

export default Config;