import IState from '../../../core/IState';
import {NavigationLogEntity} from "../NavigationLogEntity";

export interface INewNavigation {
    newTrackUrl: string;
    listNewTrackUrls?: string[];
    chatTopic: string;
    visible: boolean;
}
export class NavigationLogState implements IState {
    public visible: boolean = true;
    public listNavigationLog: NavigationLogEntity[] = [];
    public newNavigations: INewNavigation[] = [];
}

export default NavigationLogState;