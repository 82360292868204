import IState from '../../../core/IState';
import { GroupUser, User, UserLanguage } from '../../login/LoginEntity';

export class UserFormState implements IState {
    public defaultState?: boolean = false;
    public user: User = null;
    public roles: Array<GroupUser> = [];
    public busy: boolean = false;
    public loadingFile: boolean = false;
}

export default UserFormState;