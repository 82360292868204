import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Agency, Location } from "../../location/LocationEntity";
import { Place } from "../../place/PlaceEntity";

export default class LocationPageState implements IState {
    public busy: boolean = false;
    public loadingLocationResults: boolean = false;

    public criteria: string = "";
    public zipCode: string = "";
    public idAgency: number = 0;

    // Lists
    public countries: Place[] = [];
    public states: Place[] = [];
    public cities: Place[] = [];

    // Seletected places
    public showState: boolean = true;
    public country: number = 0;
    public state: number = 0;
    public city: number = 0;

    public pagination: Pagination = new Pagination();
    public locationList: Array<Location> = [];
    public agencies: Array<Agency> = [];
    public currentLocation: Location = null;
}