/**
 * Convert a key-value object into a specific type.
 * @class Reflection
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export default class Reflection {
	/**
	 * Remove the fields with null values
	 * @param data Object data
	 * @returns Clean object
	 */
	public static clearNulls<T = any>(data: any, values: any[] = [null]): T{
		var keys = Object.keys(data);
		if(keys.length > 1) {
			keys.forEach( key => {
				values.includes(data[key]) && delete(data[key]);
			});
			return data;
		}
		return data;
	}

    /**
     * Convert the object to a specific type
     * @param data Object
     * @param entity Type
     * @returns Parsed entity
     */
	public static parseEntity(data: any, entity, eachCallback: CallableFunction = null){
        let object = new entity();
		data && Object.keys(data).forEach( key => {
			let value = data[key];
			// Keep predetermined value
			(value !== null) && (object[key] = value);
		});
		eachCallback && (object = eachCallback(object));
		return object;
	}

    /**
     * Convert the object array to a specific type
     * @param data Object array
     * @param entity Type
     * @returns Parsed entity array
     */
	public static parseEntityArray(data: Array<any>, entity, eachCallback: CallableFunction = null){
		if(!data || data.length < 1) {
			return [];
		}
		let me = this;
		let newArray = [];
		data.forEach( element => {
			let tmp = me.parseEntity(element, entity, eachCallback);
			newArray.push(tmp);
		})
		return newArray;
	}

	/**
	 * Generates a formdata from an entity object
	 * @param data Entity object
	 * @returns FormData
	 */
	public static getEntityFormData(data: any): FormData {
		let formData = new FormData();
		Object.keys(data).forEach( key => {
			formData.set(key, data[key]);
		})
		return formData;
	}
}